// 
// GtPictureFileSupport ( 0.1.0.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'

class GtPictureFileSupport extends GtUiComponent {
  name() {
    return 'GtPictureFileSupport'
  }

  fetchUi() {
    this.ui.formInputs = $('.form-inputs')
    this.ui.pictureFileInputs = this.ui.formInputs.find('.input-group.picture_file')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.formInputs.length > 0 &&
                   this.ui.pictureFileInputs.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.pictureFileInputs.each((index, self) => {
      this.initPictureFileInput($(self))
    })
  }

  initPictureFileInput(uiPictureFileInput) {
    const uiImg = uiPictureFileInput.find('.preview img')
    const uiFile = uiPictureFileInput.find('input[type=file]')

    uiFile.on('change', event => {
      const input = event.target
      const file = input.files[0]
      const isFound = input.files && file

      if (isFound) {
        this.previewImage(file, uiImg)
      }
    })
  }

  previewImage(file, uiImg) {
    const reader = new FileReader()

    reader.onload = e => {
      uiImg.attr('src', e.target.result)
    }

    reader.readAsDataURL(file)
  }
}

export default GtPictureFileSupport