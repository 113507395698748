import Vue from 'vue'
import VueNotifications from 'vue-notification'
import App from './app.vue'

Vue.use(VueNotifications)

Vue.config.productionTip = false
// Vue.config.devtools = window.location.search.indexOf('debug=1') > -1

function setupVueApp () {
  const eleApp = document.getElementById('page_helper_app')

  if (eleApp) {
    new Vue({ render: h => h(App)}).$mount(eleApp)
  }
}

document.addEventListener('DOMContentLoaded', setupVueApp)