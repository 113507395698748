// 
// IdeaEditorBtns ( 0.5.3.0 )
//
import { GtUiComponent } from '../../shared/core/GtUi'
import ClipboardJS from 'clipboard'

class IdeaEditorBtns extends GtUiComponent {
  name() {
    return 'IdeaEditorBtns'
  }

  fetchUi() {
    this.core.quill = null

    this.ui.main = $('body')
    this.ui.emojiBtn = this.ui.main.find('a.editor-btn.emoji')
    this.ui.copyBtn = this.ui.main.find('a.editor-btn.copy-article')
    this.ui.galleryBtn = this.ui.main.find('a.editor-btn.gallery')
    this.ui.galleryFileInput = this.ui.main.find('.gallery-file input')
    this.ui.findIdeasBtn = this.ui.main.find('a.editor-btn.find-ideas')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.main.length === 1

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.initEmojiUi()
    this.initFileUploadUi()
    this.initCopyArticleUi()
    this.initFindIdeasUi()
  }

  initEmojiUi() {
    if (this.ui.emojiBtn.length !== 1) { return }

    this.ui.emojiBtn.on('click', e => {
      e.preventDefault()
      this.toggleEmojiInput()
    })
  }

  initFileUploadUi() {
    if (this.ui.galleryBtn.length !== 1) { return }

    this.ui.galleryBtn.on('click', e => {
      e.preventDefault()
      window.nasApp.toggle()
    })
  }

  initCopyArticleUi() {
    if (this.ui.copyBtn.length !== 1) { return }

    const clipboard =
      new ClipboardJS(this.ui.copyBtn[0],
        {text: trigger => this.copyArticleContent()})

    clipboard.on('success', event => {
      this.ui.copyBtn.html('<i class="fas fa-check"></i>')
      this.ui.copyBtn.toggleClass('opened', true)

      setTimeout(() => {
        this.ui.copyBtn.html('<i class="fas fa-cut"></i>')
        this.ui.copyBtn.toggleClass('opened', false)
      }, 500)

      window.PageHelper.notify(window.ui18.msg.copyCompleted, window.ui18.label.copy, 'fas fa-check')
    })

    clipboard.on('error', e => {
      window.PageHelper.notify(window.ui18.msg.copyFailed, window.ui18.label.copy, 'fas fa-times')
    })

    this.ui.copyBtn.on('click', e => {
      e.preventDefault()
    })
  }
  
  initFindIdeasUi() {
    if (this.ui.findIdeasBtn.length !== 1) { return }

    this.ui.findIdeasBtn.on('click', e => {
      e.preventDefault()
      if ((window.IdeasPanel == null) || (window.RhymeSearch == null)) { return }

      if (window.IdeasPanel.isOpened()) {
        this.ui.findIdeasBtn.toggleClass('opened', false)

        window.RhymeSearch.close({completed: () => {
          window.IdeasPanel.close()
        }})

      } else {
        this.ui.findIdeasBtn.toggleClass('opened', true)

        window.IdeasPanel.open({completed: () => {
          window.RhymeSearch.open()
        }})
      }
    })
  }

  copyArticleContent() {
    const inViewMode = (window.IdeaEditorViewMode.core.quill != null)

    const articleTitle =
      inViewMode ?
        window.IdeaEditorViewMode.getEditorTitle()
      :
        window.IdeaEditorEditMode.getEditorTitle()

    const articleContent =
      inViewMode ?
        window.IdeaEditorViewMode.getEditorContent()
      :
        window.IdeaEditorEditMode.getEditorContent()

    const copyLinks = IdeaEditorUploadFiles.getCopyLinks()

    const copyContent =
      [
        `\# ${articleTitle}\r\n\r\n`,
        articleContent,
        "\# 參考檔案\r\n",
        copyLinks.join("\r\n") 
      ].join("")

    return copyContent
  }

  toggleEmojiInput() {
    if ((window.EmojiInput == null)) { return }

    this.ui.emojiBtn.toggleClass('opened', window.EmojiInput.toggle())
  }
}

export default IdeaEditorBtns