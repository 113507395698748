import _ from 'lodash'
import {
  getHumanFileSize,
  getFileExtName,
  getFileIconCss
} from '../../shared/core/util/common'

// 
// FileObject2 ( 0.8.6.0 )
// 
class FileObject2 {
  constructor (options={}) {
    if ( _.isEmpty(options.uuid) ) {
      throw 'uuid not found.'
    }

    // javascript object id
    this.uuid = options.uuid

    // adops nas file record id
    this.id = ( options.id || null )

    // 0 => root, >0 => folderId
    this.folderId = options.folderId || 0
  
    // status: unknown, new, uploading, stored, uploaded, processFailed
    this.status = options.status || 'unknown'

    // file info
    this.name = options.fileName || 'unknown.dat'
    this.extension = options.fileExt || getFileExtName(this.name)
    this.contentType = options.contentType || 'none'
    this.size = options.fileSize || 0
    this.humanSize = getHumanFileSize(this.size)
    this.iconCss = getFileIconCss(this.extension)

    this.createdAt =
      options.createdAt || Math.round(new Date().getTime() / 1000)

    // url
    this.previewUrl = options.previewUrl || ''
    this.storageUrl = options.storageUrl || ''

    // super storage record id
    this.storageSourceId = ''

    // uploading info
    this.uploadProgress = 0
    this.uploadTxBytes = 0
    this.uploadTotalBytes = 0

    // ui control
    this.userChose = false
    this.userChoseByUiSection = false
    this.userCutObject = false
    this.userEditObjectNameMode = false
    this.dataUpdating = false
    this.userRating = options.userRating || 0
    this.userRatingApiUpdating = options.userRatingApiUpdating || false
    this.userRatingApiTimer = options.userRatingApiTimer || 0
    this.bindToArticle = false
  }

  setNewName (newName) {
    this.name = newName

    if ( _.isEmpty(this.extension) ) {
      this.extension = getFileExtName(this.name)
    }

    this.iconCss = getFileIconCss(this.extension)
  }

  updateUploadProgress (progressLoaded, progressTotal) {
    this.uploadTxBytes = progressLoaded
    this.uploadTotalBytes = progressTotal
    this.uploadProgress = progressLoaded / progressTotal * 100
  }

  hasBeenStored () {
    return /^stored|uploaded$/.test(this.status)
  }

  resetFolderId (newId) {
    newId = newId == "" ? 0 : newId
    this.folderId = parseInt(newId, 10)
  }

  resetUserUiStatus () {
    this.userChose = false
    this.userCutObject = false
  }

}

export default FileObject2
