<template>
  <div class="storage-status-bar">
    <div class="preview-imgs">
      <transition name="fade">
        <span v-if="formatChoseFilesCount" class="count">{{ formatChoseFilesCount }}</span>
      </transition>
      <transition-group name="fade" tag="div">
        <img2
          v-for="(item, index) in choseFiles"
          :key="item.uuid"
          :class="'img-'+index"
          :width="80"
          :height="80"
          :src="item.previewUrl"
          :large="item.storageUrl"
        />
      </transition-group>
    </div>
    <div class="info">
      <transition name="fade">
        <span v-if="choseFilesCount">
          <template v-if="ideaEditorStatus.enabled">
            <a
              v-tooltip="{ offset: 5, content: `將所選檔案，加入到 ${ideaEditorStatus.articleTitle} 內` }"
              class="btn sd-2"
              href="#"
              @click.prevent="$emit('add-file-to-exist-article')"
            >
              <i class="fas fa-share"></i> 加入到文案
            </a>
          </template>
          <template v-else>
            <a
              v-tooltip="{ offset: 5, content: '建立一個新文案，並且附帶所選檔案' }"
              class="btn sd-2"
              href="#"
              @click.prevent="$emit('add-file-to-new-article')"
            >
              為 <i class="fas fa-images"></i> 建立文案
            </a>
          </template>
          已選取 {{ choseFilesCount }} 個 &nbsp;<i class="fas fa-images"></i>&nbsp; 共 {{ humanChoseFilesTotalSize }} &nbsp;<!--
          --><a 
            class="btn bd"
            href="#"
            @click.prevent="selectAllFiles"
          ><i class="fas fa-check"></i> 全選</a><em>/</em><!--
          --><a
            class="btn bd"
            href="#"
            @click.prevent="deselectAllFiles"
          >取消</a> &nbsp;
        </span>
      </transition>
      <transition name="fade">
        <span v-if="currentFoldersCount" class="count">
          {{ currentFoldersCount }} <i class="far fa-folder-open"></i>
        </span>
      </transition>

      <transition name="fade">
        <span v-if="currentFilesCount" class="count">
          {{ currentFilesCount }} <i class="far fa-file-alt"></i>
        </span>
      </transition>

      <i class="fas fa-hdd"></i>
      <span
        v-tooltip="{ offset: 10, content: '目前使用容量 '+(currentUsage/maxUsage).toFixed(2)+'%' }"
        class="bar"
      ><span :style="barStyle"></span></span>
      <span class="storage">{{ humanCurrentUsage }}<em>/</em>{{ humanMaxUsage }}</span>&nbsp;
      <a
        v-tooltip="'若有容量的增加需求，歡迎與客服聯繫'"
        class="btn sd disabled"
        href="#"
        @click.prevent
      >增加</a>
    </div>
  </div>
</template>

<script>
import Img2 from './Img2.vue'
import { getHumanFileSize } from '../../shared/core/util/common'

// 
// StorageStatusBar ( 0.9.6.0 )
// 
export default {
  name: 'StorageStatusBar',

  components: {
    Img2
  },

  props: {
    ideaEditorStatus:     { type: Object, default: null },
    currentUsage:         { type: Number, default: 0    },
    maxUsage:             { type: Number, default: 0    },
    currentFoldersCount:  { type: Number, default: 0    },
    currentFilesCount:    { type: Number, default: 0    },
    choseFiles:           { type: Array,  default: null },
    choseFilesCount:      { type: Number, default: 0    },
    choseFilesTotalSize:  { type: Number, default: 0    }
  },

  emits: [
    'add-file-to-new-article',
    'add-file-to-exist-article'
  ],

  computed: {

    formatChoseFilesCount () {
      return this.choseFilesCount > 99 ? '..' : this.choseFilesCount
    },

    humanCurrentUsage () {
      return getHumanFileSize(this.currentUsage)
    },

    humanMaxUsage () {
      return getHumanFileSize(this.maxUsage)
    },
    
    humanChoseFilesTotalSize () {
      return getHumanFileSize(this.choseFilesTotalSize)
    },

    barStyle () {
      const ratio = this.currentUsage / this.maxUsage
      const percent = ratio > 1 ? 100 : Math.round(ratio * 100)

      return {
        width: `${percent}%`
      }
    }
  },

  methods: {

    getHumanFileSize (bytes) {
      // modify: https://stackoverflow.com/questions/15900485
      if ( bytes == 0 ) {
        return '0 Byte'
      }

      const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ]
      const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

      return Math.round(bytes / Math.pow(1024, index), 2) + ' ' + sizes[index]
    },

    selectAllFiles () {
      this.$parent.selectAllFiles()
    },

    deselectAllFiles () {
      this.$parent.deselectAllFiles()
    }

  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.storage-status-bar {
  z-index: 9990;
  position: fixed;

  display: table;

  width: 100%;
  height: 68px;

  bottom: 0;
  left: 0;

  padding: 1em 0.75em 0.75em 0.75em;
  color: white;
  background-color: fade-out(#1a1f36, 0.1);
  border-bottom: 5px solid fade-out(#1a1f36, 0);
  box-shadow: 0 0 5px fade-out(#1a1f36, 0.7);

  em {
    opacity: 0.2;
    font-weight: normal;
    font-style: normal;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  > .preview-imgs {
    position: absolute;

    top: -28px;
    left: 1em;

    width: 40em;

    .img2 {
      position: absolute;
      overflow: hidden;

      border-radius: 50%;
      box-shadow: 0 0 3px fade-out(black, 0.5);
      background-color: fade-out(#1a1f36, 0.05);

      i { color: lighten(#1a1f36, 50%); }

      @for $i from 0 through 8 {
        &.img-#{$i} {
          left: $i * 52px;
        }
      }
    }

    span.count {
      z-index: 9999;
      position: absolute;
      display: inline-block;

      top: 0;
      left: 0;

      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      overflow: hidden;

      border-radius: 50%;
      background-color: #f44336;
    }
  } // [end] > .preview-imgs


  > .info {
    display: table-cell;

    width: 100%;
    height: 100%;

    vertical-align: middle;
    text-align: right;
    letter-spacing: 1px;

    .fa-hdd {
      color: #4f566b;
      margin-left: 0.25em;
    }

    span.count {
      font-family: 'Roboto Mono';

      padding-left: 0.25em;
      padding-right: 0.5em;
      
      i {
        color: darken(white, 10%);
      }
    }

    a.btn {
      opacity: 1;
      display: inline-block;
      vertical-align: middle;
      transition: color .3s ease, opacity .3s ease;

      &.disabled {
        &:hover { opacity: 0.5; }
        cursor: not-allowed;
      }

      &.bd {
        margin-left: 0.25em;
        margin-right: 0.25em;

        color: #fff59d;

        &:hover {
          opacity: 0.5;
        }
      }

      &.sd, &.sd-2 {
        margin-left: 0.25em;
        margin-right: 0.25em;

        color: white;
        background-color: #5469d4;
        padding: 0.25em 0.5em;
        border-radius: 5px;
        box-shadow: 0 0 2px fade-out(black, 0.8);
        font-size: 15px;
        transition: color .3s ease, background-color .3s ease;

        &:hover {
          color: white;
        }
      }

      &.sd-2 {
        background-color: #f44336;
        background-image: linear-gradient(135deg, #e66465, #d32f2f);
      }
    } // [end] a.btn

    > .bar {
      display: inline-block;
      overflow: hidden;

      width: 4em;
      height: 8px;

      background-color: lighten(#4f566b, 58%);

      margin-left: 0.5em;
      margin-right: 0.5em;
      border-radius: 5px;
      
      > span {
        display: block;

        background-color: #4f566b;
        transition: width 1s ease-out, color .3s ease;

        width: 0%;
        height: 100%;
      }
    } // [end] > .bar

    > .storage {
      font-family: 'Roboto Mono';
    }

  } // [end] > .info
}
</style>