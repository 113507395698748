// 
// RhymeSearchTemplateGenerator ( 0.2.0.0.es6 )
// 
class RhymeSearchTemplateGenerator {
  filter() {
    return '{{#titlesCountData}}<a href="#" data-label="{{label}}" data-count="{{count}}">{{label}}</a>{{/titlesCountData}}'
  }

  result() {
    return `<div id="{{uuid}}" data-filter="{{filterTitle}}"> \
<div class="headline"><!-- \
{{#isIdeaArticle}}--><i class="fas fa-pencil-ruler"></i><!--{{/isIdeaArticle}} \
{{#isTrackingPost}}--><img class="avatar" src="{{img0}}"><!--{{/isTrackingPost}} \
--><a class="title tips" data-tippy-placement="top-start" data-tippy-content="瀏覽全文" href="{{url}}" target="_blank">{{title}}</a><!-- \
--></div> \
<div class="info"><!-- \
-->{{#isIdeaArticle}}<span class="tag">文案</span>{{/isIdeaArticle}}<!-- \
-->{{#isTrackingPost}}<span class="tag">素材</span>{{/isTrackingPost}}<!-- \
--><i class="far fa-clock"></i> <span class="date">{{timeString}}</span><!-- \
--></div> \
<div class="content"><!-- \
-->{{#paragraphs}}<div class="p">{{{unescapedParagraph}}}</div>{{/paragraphs}}<!-- \
-->{{#img1}}<p class="image {{#useLargeImage}}large{{/useLargeImage}}"><img src="{{img1}}" alt=""></p>{{/img1}}<!-- \
-->{{#img2}}<p class="image "><img src="{{img2}}" alt=""></p>{{/img2}}<!-- \
-->{{#img3}}<p class="image "><img src="{{img3}}" alt=""></p>{{/img3}}<!-- \
-->{{#isTrackingPost}}<p class="social-count"><span><i class="far fa-thumbs-up"></i><b>{{count1}}</b></span>{{#count3}}<span><b>{{count3}}</b>則留言</span>{{/count3}}{{#count2}}<span><b>{{count2}}</b>次分享</span>{{/count2}}</p>{{/isTrackingPost}}<!-- \
--></div> \
</div>`
  }

  inputTips() {
    return '<div class="input-tips"><i class="far fa-lightbulb"></i> 以下為系統隨選，您也可以自行搜尋內容</div>'
  }

  updateMessage() {
    return '<div class="message"><div><i class="{{iconClass}}"></i><p>{{message}}</p></div></div>'
  }
}

export default RhymeSearchTemplateGenerator