
import { GtUi } from '../shared/core/GtUi'

import FormSupport from './components/FormSupport'
import GtAirMenu from './components/GtAirMenu'
import TopNavigator from './components/TopNavigator'
import AutoApplyTippy from './components/AutoApplyTippy'
import GtPictureFileSupport from './components/GtPictureFileSupport'
import MultiFilters from './components/MultiFilters'
import SystemModal from './components/SystemModal'
import SystemActiveLogs from './components/SystemActiveLogs'
import CurrentPlanInfo from './components/CurrentPlanInfo'
import RequestFbAuthorizationBtns from './components/RequestFbAuthorizationBtns'

import CopyBtn from './components/CopyBtn'
import MiniCharts from './components/MiniCharts'
import GtListTableMultiSelectSupport from './components/GtListTableMultiSelectSupport'
import GtSwitchPreviewImage from './components/GtSwitchPreviewImage'
import TrackingPostsPageUpdateSupport from './components/TrackingPostsPageUpdateSupport'
import AutoDetectTrackingPostProcessStatus from './components/AutoDetectTrackingPostProcessStatus'

import IdeasPanel from './components/IdeasPanel'
import IdeaEditorBtns from './components/IdeaEditorBtns'
import IdeaEditorViewMode from './components/IdeaEditorViewMode'
import IdeaEditorEditMode from './components/IdeaEditorEditMode'
import EmojiInput from './components/EmojiInput'
import IdeaArticleModifyHistory from './components/IdeaArticleModifyHistory'
import IdeaEditorUploadFiles from './components/IdeaEditorUploadFiles'
import RhymeSearch from './components/RhymeSearch'
import MerchantUserInviteUrl from './components/MerchantUserInviteUrl'


GtUi('Global', {
  load: {
    components: {
      FormSupport,
      GtAirMenu,
      TopNavigator,
      AutoApplyTippy,
      GtPictureFileSupport,
      MultiFilters,
      SystemModal,
      SystemActiveLogs,
      GtSwitchPreviewImage,
      CurrentPlanInfo,
      RequestFbAuthorizationBtns,
      GtListTableMultiSelectSupport
    }
  }
})


GtUi('TrackingPostSystem', {
  route: /^(?:\/tracking_posts|\/master\/merchants\/\d+\/tracking_posts)/i,
  load: {
    components: {
      CopyBtn,
      MiniCharts,
      TrackingPostsPageUpdateSupport,
      AutoDetectTrackingPostProcessStatus
    }
  }
})


GtUi('IdeaArticleSystem', {
  route: /^\/(?:ideas\/|ideas$)/i,
  load: {
    components: {
      IdeasPanel,
      IdeaEditorBtns,
      IdeaEditorViewMode,
      IdeaEditorEditMode,
      EmojiInput,
      IdeaArticleModifyHistory,
      IdeaEditorUploadFiles,
      RhymeSearch
    }
  }
})

GtUi('MerchantSystem', {
  route: /\/merchant_users/i,
  load: {
    components: {
      MerchantUserInviteUrl
    }
  }
})