// 
// IdeaEditorViewMode ( 0.9.0.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import Quill from "quill"

class IdeaEditorViewMode extends GtUiComponent {
  name() {
    return 'IdeaEditorViewMode'
  }

  fetchUi() {
    this.core.quill = null

    this.ui.editorWindow = $('.idea-editor.view-mode')
    this.ui.titleInput = this.ui.editorWindow.find('.title input')
    this.ui.editorContent = this.ui.editorWindow.find('.content')
  }

  checkUi() {
    this.fetchUi()

    const result = (this.ui.editorWindow.length === 1) && 
             (this.ui.titleInput.length === 1) && 
             (this.ui.editorContent.length === 1)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initQuill(elementId) {
    this.core.quill =
      new Quill(`\#${elementId}`, {
        theme: 'bubble',
        modules: {
          syntax: false,
          toolbar: false
        }
      })

    this.core.quill.enable(false)
  }

  initUi() {
    this.initQuill(this.ui.editorContent.attr('id'))

    if (window.location.href.indexOf('show=histories') > -1) {
      this.showHistories()
    }
  }
  
  getEditorTitle() {
    return this.ui.titleInput.val()
  }

  getEditorContent() {
    return this.core.quill.getText()
  }

  showHistories() {
    const newTop = $('.modify-history').offset().top - (12 * 7)

    TweenMax.to(window, 0.7, { scrollTo: {y: newTop, ease: Power2.easeInOut}, delay: 0.2 })
  }
}

export default IdeaEditorViewMode