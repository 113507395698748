import Quill from 'quill'

const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')
const Block = Quill.import('blots/block')

// 1. 將換行的方式產生的 tag 調整為 div，確保 Chrome 不會干擾複製的內容
Block.tagName = 'DIV'
Quill.register(Block, true)

// 2. 凡從剪貼簿貼上，通通將它轉為純文字
class PlainClipboard extends Clipboard {

  onPaste (e) {
    e.preventDefault()

    const range = this.quill.getSelection()
    const text = e.clipboardData.getData('text/plain')

    const delta =
      new Delta().retain(range.index).delete(range.length).insert(text)

    const index = text.length + range.index
    const length = 0

    this.quill.updateContents(delta, 'silent')
    this.quill.setSelection(index, length, 'silent')
    this.quill.scrollIntoView()
  }
}

Quill.register('modules/clipboard', PlainClipboard, true)