<template>
  <div class="storage-path">
    <div class="path">
      <span>
        <a
          v-tooltip="'回最頂層'"
          href="#"
          class="storage-path-folder"
          data-id=""
          @click.prevent="changeRootFolder"
        ><i class="fas fa-hdd"></i></a>
      </span>
      <span>
        <a
          href="#"
          class="arrow"
          @click.prevent="showFoldersMenu"
        ><i class="fas fa-chevron-right"></i></a>
      </span>
      <span v-for="tree in treeData" :key="tree.id">
        <template v-if="!tree.root">
          <a
            class="label storage-path-folder"
            :data-id="tree.id"
            :href="'#nas:folder:'+tree.id"
          >{{ tree.name }}</a>
          <a
            class="arrow"
            href="#"
            :data-folder-id="tree.id"
            @click.prevent="showFoldersMenu"
          ><i class="fas fa-chevron-right"></i></a>
        </template>
      </span>
    </div>
    <div class="folder-menu" :style="folderMenuStyle">
      <div v-if="loadingFolderMenu" style="padding: 0.325em;">
        <i class="fas fa-fw fa-spinner fa-spin "></i> Loading
      </div>
      <div v-else>
        <a
          v-for="item in foldersMenuItems"
          :key="item.id"
          class="storage-path-folder"
          :data-id="item.id"
          :href="'#nas:folder:'+item.id"
        ><i class="far fa-folder"></i>{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import StorageOperator from "../core/StorageOperator.js"

// 
// StoragePath ( 0.9.4.0 )
// 
export default {
  name: 'StoragePath',

  props: {
    currentFolderId: { type: [String, Number], default: '' }
  },

  data () {
    return {
      treeData: [],
      foldersMenuItems: [],
      showFolderMenu: false,
      menuTop: 0,
      menuLeft: 0,
      loadingFolderMenu: false
    }
  },

  computed: {
    folderMenuStyle () {
      return {
        display: ( this.showFolderMenu ? '' : 'none' ),
        top: `${this.menuTop}px`,
        left: `${this.menuLeft}px`,
      }
    }
  },

  watch: {
    currentFolderId () {
      this.fetchNewTreeData()
    }
  },

  mounted () {
    this.storageOperator = new StorageOperator()

    this.fetchNewTreeData()

    window.addEventListener('click', (event) => {
      if ( this.showFolderMenu ) {
        const elArrowBtn = event.target.closest('.storage-path a.arrow')
        
        if ( elArrowBtn ) {

        } else {
          this.showFolderMenu = false
        }
      }
    })
  },

  methods: {

    showFoldersMenu (event) {
      const elBtn = event.currentTarget
      const domRect = elBtn.getBoundingClientRect()

      this.foldersMenuItems = []
      this.folderId = elBtn.dataset.folderId || ''
      this.loadingFolderMenu = true
      this.menuTop = domRect.top + domRect.height + 5
      this.menuLeft = domRect.left
      this.showFolderMenu = true

      this.storageOperator.currentFolders({
        useCache: true,
        currentFolder: this.folderId,
        events: {
          ok: (res) => {
            this.foldersMenuItems = res.data.data.folders.slice(0, 15)
            this.loadingFolderMenu = false
          },
          failed: (res, err) => {
            // failed
          }
        }
      })
    },

    changeRootFolder () {
      this.$parent.changeRootFolder()
    },

    fetchNewTreeData () {
      this.storageOperator.pathData({
        useCache: true,
        currentFolder: this.currentFolderId,
        events: {
          ok: (res) => {
            this.treeData = res.data.data.tree_data
          },
          failed: (res, err) => {
            // failed
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.storage-path {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  > .folder-menu {
    z-index: 8888;
    position: absolute;

    top: 0;
    left: 0;

    color: rgba(76, 90, 157, 0.9);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px fade-out(black, 0.9);

    > div > a {
      display: block;

      color: #5469d4;
      background-color: white;
      font-size: 14px;
      padding: 0.5em 0.8em;
      text-align: left;
      transition: color .3s ease, background-color .3s ease;

      i {
        margin-right: 0.5em;
      }

      &:hover {
        color: white;
        background-color: #5469d4;
      }
    }
  }

  > .path {

    > span > a {
      display: inline-block;
      
      color: #4f566b;
      padding: 0.25em 0.5em;
      // padding-left: 0.5em;
      // padding-right: 0.5em;

      border-radius: 5px;
      transition: color .3s ease, background-color .3s ease;

      i.fas.fa-chevron-right {
        opacity: 0.3;

        transition: opacity .3s ease;
      }

      &:hover {
        color: white;
        background-color: #5469d4;

        i { opacity: 1; }
      }

      &.arrow {
        i {
          transform: rotate(0);
          transition: transform .3s ease;
        }

        &:hover {
          i {
            opacity: 1 !important;
            transform: rotate(90deg);
          }
        }
      }

    }
  }
}
</style>