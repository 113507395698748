// 
// FormSupport ( 0.3.4.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'

class FormSupport extends GtUiComponent {
  name() {
    return 'FormSupport'
  }

  fetchUi() {
    this.ui.submitButtons = $('a.ctr-submit')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.submitButtons.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    this.ui.submitButtons.off()

    return true
  }

  initUi() {
    this.ui.submitButtons.on('click', event => {
      event.preventDefault()
      return this.submit($(event.currentTarget))
    })
  }

  submit(uiButton) {
    const uiForm = (() => {
      if (uiButton.data('form') != null) {
        const targetFormName = uiButton.data('form').replace(/[^\w\-\_]/, '')
        const uiFormSelector = `.${targetFormName}`
        return $(uiFormSelector)
      } else {
        return uiButton.parents('form:eq(0)')
      }
    })()
    
    if (uiForm.length < 1) { return }

    if (this.confirm(uiButton)) {
      if (uiButton.data('formAction') != null) {
        uiForm.attr('action', uiButton.data('formAction'))
      }

      this.setFormMethod(uiForm, uiButton)
      this.setNewAuthenticityToken(uiForm)

      return uiForm.submit()
    }
  }

  setFormMethod(uiForm, uiButton) {
    if ((uiButton.data('formMethod') == null)) { return }

    const newMethod = uiButton.data('formMethod')
    
    const useHiddenMethod = !(/get|post/i.test(newMethod))

    if (useHiddenMethod) {
      uiForm.attr('method', 'post')

      let uiHiddenMethod = uiForm.find('input[name=_method]')

      if (uiHiddenMethod.length === 0) {
        uiHiddenMethod = $('<input type="hidden" name="_method">')
        uiForm.append(uiHiddenMethod)
      }

      return uiHiddenMethod.val(newMethod)
    } else {
      return uiForm.attr('method', uiButton.data('formMethod'))
    }
  }

  setNewAuthenticityToken(uiForm) {
    const currentMethod = uiForm.attr('method') || ''

    const ignoreUpdate = /get/i.test(currentMethod)

    let uiAuthenticityToken = 
      uiForm.find('input[name=authenticity_token]')

    if (ignoreUpdate) {
      uiAuthenticityToken.remove()
      return
    }

    if (uiAuthenticityToken.length === 0) {
      uiAuthenticityToken = 
        $('<input type="hidden" name="authenticity_token">')

      uiForm.append(uiAuthenticityToken)
    }

    const newToken = this.fetchCsrfToken()
    if (newToken != null) { uiAuthenticityToken.val(newToken) }

    return null
  }
    
  fetchCsrfToken() {
    return $('meta[name=csrf-token]').attr('content')
  }

  confirm(uiButton) {
    const message = uiButton.data('submitConfirm') || ''

    if (message.length < 1) {
      return true
    } else {
      return confirm(message)
    }
  }
}

export default FormSupport