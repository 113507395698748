// 
// IdeaEditorUploadFiles ( 0.9.16.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import MultiUploadControl from '../../shared/core/MultiUploadControl'
import UploadAsset from '../../shared/core/UploadAsset'
import ArticleAssetsOperator from '../../shared/core/ArticleAssetsOperator'
import _ from 'lodash'
import ClipboardJS from 'clipboard'
import tippy from 'tippy.js'
import Mustache from 'mustache'

class IdeaEditorUploadFiles extends GtUiComponent {
  name() {
    return 'IdeaEditorUploadFiles'
  }

  fetchUi() {
    this.status.loaded = false

    this.ui.main = $('.editor-upload-files')
    this.ui.fileUi = $('<div></div>')

    var uploadTips = ''

    if (this.ui.main.length > 0) {
      uploadTips = this.ui.main.data('uploadTips') || ''
    }

    this.ui.uploadTips = $('<div class="upload-tips">'+uploadTips+' <i class="fas fa-cloud-upload-alt"></i></div>')

    this.setting.currentArticleId = this.ui.main.data('recordId') || 0
    this.setting.readonly = this.ui.main.data('readonly') === 'yes'

    this.core.uploadControl =
      new MultiUploadControl({
        paramsName: 'file',
        uploadTo: '/files/upload',
        otherParams: [
          { name: 'article', value: this.setting.currentArticleId }
        ],
        event: {
          added: () => {
            this.refreshUploadUi()
          }
        }
      })

    if (!this.setting.readonly) {
      this.ui.fileUi.append(this.ui.uploadTips)
    }

    this.ui.main.append(this.ui.fileUi)
  }
  
  checkUi() {
    this.fetchUi()

    const result = (this.ui.main.length === 1) &&
             (this.ui.fileUi.length === 1)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  enabled() {
    return this.status.loaded
  }

  initUi() {
    this.articleAssetsOperator = new ArticleAssetsOperator()

    this.initFileCopyFeature()
    this.initOtherUi()
    this.fetchCurrentArticleAssets()
    this.status.loaded = true
  }

  initOtherUi() {
    this.ui.uploadTips.on('click', e => {
      window.IdeaEditorBtns.ui.galleryBtn.click()
    })
  }

  notice (text, title, icon='fas fa-times') {
    window.PageHelper.notify(text, title, icon)
  }

  fetchCurrentArticleAssets () {
    this.articleAssetsOperator.currentAssets({
      params: {
        articleId: this.setting.currentArticleId
      },
      events: {
        ok: res => {
          const { result, data } = res.data

          if (result == 'success') {
            this.addExistFileToFileUiByArticleAssets({ assets: data.assets.reverse() })
          }
        },
        failed: (res, err) => {
          this.notice(`暫時無法取得附件檔案。`, '發生錯誤', 'fas fa-times')
          console.error(err)
        },
        always: () => {
          this.refreshUploadUi()
        }
      }
    })

  }

  addExistFileToFileUiByArticleAssets ({ assets }) {
    for (const file of assets) {
      const obj =
        new UploadAsset({
          pseudo: true,
          file: {
            name: file.name,
            size: file.size
          },
          storageParams: {
            fileId: file.id,
            url: file.url,
            previewUrl: file.previewUrl
          }
        })

      this.addExistFileToFileUi(obj)
    }
  }

  initFileCopyFeature() {
    const clipboard =
      new ClipboardJS('a.copy', {
        text(trigger) {
          return $(trigger).attr('aria-label')
        }
      })

    clipboard.on('success', function(event) {
      const uiTrigger = $(event.trigger)
      uiTrigger.html('<i class="fas fa-lg fa-check"></i>')

      setTimeout(() => uiTrigger.html('<i class="fas fa-lg fa-link"></i>'), 850)
    })

    clipboard.on('error', function(event) {
      const uiTrigger = $(event.trigger)
      uiTrigger.remove()

      alert('提示：瀏覽器複製失敗，請使用上方「下載」試試！')
    })
  }

  getCurrentArticleId() {
    return this.setting.currentArticleId
  }

  getCopyLinks() {
    const fileUrls = []
    const uiBtns = this.ui.fileUi.find('.file a.copy')

    uiBtns.each((index, eleBtn) => {
      const uiBtn = $(eleBtn)
      const url = uiBtn.attr('aria-label')
      if (!_.isEmpty(url)) { return fileUrls.push(url) }
    })

    return fileUrls
  }

  refreshUploadUi() {
    const currentFileCount = this.ui.fileUi.children('.file').length

    const layoutModeCss =
      (() => { switch (this.ui.fileUi.children('.file').length) {
        case 1:
          return 'editor-upload-files layout-1'
        case 2:
          return 'editor-upload-files layout-2'
        default:
          return 'editor-upload-files layout-3'
      } })()

    if (currentFileCount > 0) {
      this.ui.uploadTips.hide()
      this.ui.main.attr('class', layoutModeCss)
    } else {
      if (!this.setting.readonly) {
        this.ui.uploadTips.show()
      }
    }
  }

  addFile({ file=null, fileList=null }) {

    if (fileList) {
      for (const file of Array.from(fileList)) {
        this.addFile({ file })
      }
      return
    }

    this.core.uploadControl.add(file, {
      event: {
        beforeStart: obj => {
          this.addNewFileToFileUi(obj)
          return true
        },
        uploaded: obj => {
          const response = obj.getServerResponse()
          if ((response == null)) { return }

          // 儲存 storage 服務用的參數
          obj.storageParams.fileId     = response.data.file_id
          obj.storageParams.url        = response.data.url
          obj.storageParams.previewUrl = response.data.preview_url
          obj.storageParams.time       = response.data.time
          obj.storageParams.signature  = response.data.signature

          // this.createAttachmentInIdeaArticleApp(obj)
          this.processImageUploadSuccess(obj)
        },
        progress: obj => {
          const { ui } = obj.cache
          TweenMax.set(ui.progressLine, {scaleX: obj.progressPercent / 100})
        },
        error: obj => {
          this.processImageUploadError(obj)
        },
        timeout: obj => {
          this.processImageUploadError(obj, {timeout: true})
        },
        abort(obj) {
          // ignore
        }
      }
    })
  }

  processImageUploadSuccess(obj) {
    const { ui } = obj.cache
    const response = obj.getServerResponse()

    // 更新該檔案的 ui 狀態跟按鈕 
    ui.downloadBtn.attr('download', obj.fileName)
    ui.downloadBtn.attr('href', response.data.url)
    ui.downloadBtn.attr('target', '_blank')
    ui.copyBtn.attr('aria-label', response.data.url)
    ui.file.removeClass('new').addClass('uploaded')
    ui.file.attr('id', `editor_user_asset_${obj.storageParams.fileId}`)

    tippy(ui.downloadBtn[0], { content: "下載", hideOnClick: false, placement: "left" })
    tippy(ui.copyBtn[0], { content: "複製連結", hideOnClick: false, placement: "left" })

    window.PageHelper.notify(`${obj.fileName}`, `上傳完成 ( ${obj.fileHumanSize} )`, 'fas fa-file-upload')

    this.loadPreviewImage(obj)
  }

  loadPreviewImage(obj) {
    const { ui } = obj.cache

    if (!_.isEmpty(obj.storageParams.previewUrl)) {
      TweenMax.to(ui.thumbnailProcessing, 0.3, { opacity: 1 })

      const newPreviewUrl =
        `${obj.storageParams.previewUrl}&t=${+new Date}`

      const buffer = new Image

      buffer.onerror = () => {
        TweenMax.to(ui.thumbnailProcessing, 0.3, { opacity: 0 })
      }

      buffer.onload = e => {
        const notReady = (buffer.width === 50) && (buffer.height === 50)

        if (notReady) {
          setTimeout(() => this.loadPreviewImage(obj), 6500)
        } else {
          ui.previewImg.attr('src', newPreviewUrl)
          ui.file.addClass('is-image-file')
          TweenMax.to(ui.fileIcon, 0.3, { opacity: 0 })
          TweenMax.to(ui.previewImg, 0.5, { opacity: 1, ease: Power1.easeOut })
          TweenMax.to(ui.thumbnailProcessing, 0.3, { opacity: 0 })
        }
      }

      buffer.src = newPreviewUrl
    }
  }

  processImageUploadError(obj, options) {
    if (options == null) { options = {} }
    const { ui } = obj.cache
    const { xhr } = obj
    const response = obj.getServerResponse()

    // 將 icon 換成 error icon
    ui.file.removeClass('new').addClass('error')
    ui.fileIcon.html('<div><i class="far fa-times-circle"></i></div>')

    // 顯示發生什麼樣的錯誤
    if (options.timeout) {
      ui.label.text("上傳失敗，已逾時！")
    } else if (obj.fileSizeExceededTheLimit()) {
      ui.label.text(obj.fileSizeExceededTheLimitTips())
    } else {
      switch (xhr.status) {
        case 403:
          switch (response.result) {
            case 'file_size_exceeded_the_limit':
              ui.label.text("上傳檔案超過限制，建議壓縮檔案再上傳")
              break
            case 'storage_usage_exceeded_the_limit':
              ui.label.text('您的空間已滿，無法上傳。')
              break
            default:
              ui.label.text("上傳失敗，建議重新再試一次！")
              break
          }
          break
        case 404:
          ui.label.text("上傳失敗，系統可能正在維護。")
          break
        case 406:
          ui.label.text(`不可上傳 .${obj.fileExtName} 格式`)
          break
        default:
          ui.label.text("上傳失敗，建議重新再試一次！")
          break
      }
    }
  }

  insertUserAssetToEditor(nasFiles) {
    for (const nasFile of Array.from(nasFiles)) {

      const acceptAppend =
        document.getElementById(`editor_user_asset_${nasFile.id}`) == null

      if (acceptAppend) {
        const obj =
          new UploadAsset({
            pseudo: true,
            file: {
              name: nasFile.name,
              size: nasFile.size
            },
            storageParams: {
              fileId: nasFile.id,
              url: nasFile.storageUrl,
              previewUrl: nasFile.previewUrl
            }
          })

        this.addExistFileToFileUi(obj)
      }
    }

    this.refreshUploadUi()
  }

  removeAssetRelationSetting(obj) {
    if (_.isNull(obj.storageParams.fileId)) { return }

    obj.cache.startRemove = true

    this.articleAssetsOperator.removeArticleAsset({
      params: {
        assetId: obj.storageParams.fileId,
        articleId: this.setting.currentArticleId
      },
      events: {
        ok: res => {
          this.removeFileUi(obj)
          this.notice(`上傳到NAS的檔案，不會移除`, '已將附件移出文案', 'fas fa-file-export')
        },
        failed: (res, err) => {
          const { result } = res.data
    
          if (!_.isObject(res)) {
            this.notice(`暫時無法移除`, '發生錯誤', 'fas fa-times')
            return
          }
    
          switch (result) {
            case 'article_not_found':
              this.notice('無法移除，因為相關文案可能已經刪除。', '移除失敗', 'fas fa-times')
              break
            case 'no_delete_any_setting':
              this.notice('無法移除，因為找不到相關的設定，可能已經刪除。', '移除失敗', 'fas fa-times')
              break
            default:
              this.notice(`系統暫時無法移除`, '移除失敗', 'fas fa-times')
              break
          }
        },
        always: () => {
          obj.cache.startRemove = false
        }
      }
    })
  }

  removeFileUiByUserAssetId(id) {
    const uiFile = $(`#editor_user_asset_${id}`)
    if (uiFile.length < 1) { return }

    uiFile.remove() 
    this.refreshUploadUi()
  }

  removeFileUi(obj) {
    TweenMax.to(obj.cache.ui.file, 0.25, {
      opacity: 0,
      onComplete: () => {
        obj.cache.ui.file.remove()
        this.refreshUploadUi()
      }
    })
  }

  startRemoveFile(obj) {
    const terminateUploading =
      obj.inProcessing() || obj.inQueued() || obj.processFailed()

    if (terminateUploading) {
      obj.abort()
      this.removeFileUi(obj)
    } else {
      if (obj.cache.startRemove) { return }
      this.removeAssetRelationSetting(obj)
    }
  }

  addExistFileToFileUi(obj) {

    // 1. 初始化相關介面
    const template = `<!-- \
--><div id="{{elementId}}" class="file {{css}}" data-asset-id="{{fileId}}"> \
<div class="inner"><!-- \
--><div class="thumbnail-processing">縮圖產生中..</div><!-- \
--><div class="file-size">{{fileExtName}} - {{fileHumanSize}}</div><!-- \
--><img class="preview" src="/images/blank-50x32.png" draggable="false"><!-- \
--><div class="label"><div>{{name}}</div></div><!-- \
--><a href="{{fileUrl}}" target="_blank" class="download"><i class="fas fa-lg fa-download"></i></a><!-- \
--><a href="#copy" class="copy" aria-label="{{fileUrl}}"><i class="fas fa-lg fa-link"></i></a><!-- \
--><a href="#remove" class="remove" title=""><i class="fas fa-file-export"></i></a><!-- \
--><div class="file-icon"><div><i class="{{fileIconCss}}"></i></div></div><!-- \
--></div> \
</div>`

    const outputSyntax =
      Mustache.render(template, {
        elementId: `editor_user_asset_${obj.storageParams.fileId}`,
        css: 'cover uploaded',
        name: obj.fileName,
        fileId: obj.storageParams.fileId,
        fileHumanSize: obj.fileHumanSize,
        fileExtName: obj.fileExtName,
        fileIconCss: obj.fileIconCss,
        fileUrl: obj.storageParams.url
      })

    const uiNewFile = $(outputSyntax)

    obj.cache.ui.file = uiNewFile

    obj.cache.ui.downloadBtn = uiNewFile.find('a.download')
    obj.cache.ui.downloadBtn.on('click', e => {
      if (obj.cache.ui.downloadBtn.attr('href').indexOf('#download') > -1) {
        e.preventDefault()
      }
    })

    obj.cache.ui.copyBtn = uiNewFile.find('a.copy')
    obj.cache.ui.copyBtn.on('click', e => { e.preventDefault() })

    obj.cache.ui.removeBtn = uiNewFile.find('a.remove')
    obj.cache.ui.removeBtn.on('click', e => { e.preventDefault() })
    obj.cache.ui.removeBtn.on('dblclick', e => {
      if (!this.setting.readonly) { return this.startRemoveFile(obj) }
    })

    if (this.setting.readonly) { obj.cache.ui.removeBtn.hide() }

    tippy(obj.cache.ui.downloadBtn[0],
      { content: "下載", hideOnClick: false, placement: "left" })

    tippy(obj.cache.ui.copyBtn[0],
      { content: "複製連結", hideOnClick: false, placement: "left" })

    tippy(obj.cache.ui.removeBtn[0],
      { content: "點二次移出文案", hideOnClick: false, placement: "right" })

    obj.cache.ui.previewImg = uiNewFile.find('img.preview')
    obj.cache.ui.fileIcon = uiNewFile.find('.file-icon')
    obj.cache.ui.thumbnailProcessing = uiNewFile.find('.thumbnail-processing')

    // 2. 初始化完成後，秀出附件檔案介面
    TweenMax.set(uiNewFile, { opacity: 0 })
    this.ui.fileUi.prepend(uiNewFile)
    TweenMax.to(uiNewFile, 0.2, { opacity: 1, delay: 0.1 })

    // 3. 最後看是否需要顯示預覽圖
    const previewUrl = obj.getPreviewUrl()

    if (!_.isEmpty(previewUrl)) {
      this.loadPreviewImage(obj)
    }
  }

  addNewFileToFileUi(obj) {
    this.ui.fileUi[0].scrollIntoView()

    // 1. 初始化相關介面
    const template = `<!-- \
--><div class="file {{css}}" data-uuid="{{uuid}}"> \
<div class="inner"><!-- \
--><div class="thumbnail-processing">縮圖產生中..</div><!-- \
--><div class="file-size">{{fileExtName}} - {{fileHumanSize}}</div><!-- \
--><img class="preview" src="/images/blank-50x32.png" draggable="false"><!-- \
--><div class="label"><div>{{name}}</div></div><!-- \
--><div class="progress"><div></div></div><!-- \
--><a href="#download" class="download"><i class="fas fa-lg fa-download"></i></a><!-- \
--><a href="#copy" class="copy"><i class="fas fa-lg fa-link"></i></a><!-- \
--><a href="#remove" class="remove" title=""><i class="fas fa-file-export"></i></a><!-- \
--><div class="file-icon"><div><i class="{{fileIconCss}}"></i></div></div><!-- \
--></div> \
</div>`

    const outputSyntax =
      Mustache.render(template, {
        uuid: obj.id,
        css: 'cover new',
        name: obj.fileName,
        fileHumanSize: obj.fileHumanSize,
        fileExtName: obj.fileExtName,
        fileIconCss: obj.fileIconCss
      })

    const uiNewFile = $(outputSyntax)

    obj.cache.ui.file = uiNewFile

    obj.cache.ui.downloadBtn = uiNewFile.find('a.download')
    obj.cache.ui.downloadBtn.on('click', e => {
      if (obj.cache.ui.downloadBtn.attr('href').indexOf('#download') > -1) {
        e.preventDefault()
      }
    })

    obj.cache.ui.copyBtn = uiNewFile.find('a.copy')
    obj.cache.ui.copyBtn.on('click', e => { e.preventDefault() })

    obj.cache.ui.removeBtn = uiNewFile.find('a.remove')
    obj.cache.ui.removeBtn.on('click', e => { e.preventDefault() })
    obj.cache.ui.removeBtn.on('dblclick', e => {
      if (!this.setting.readonly) { return this.startRemoveFile(obj) }
    })

    if (this.setting.readonly) {
      obj.cache.ui.removeBtn.hide()
    } else {
      tippy(obj.cache.ui.removeBtn[0],
        { content: "點二次移出文案", hideOnClick: false, placement: "right" })
    }

    obj.cache.ui.progress = uiNewFile.find('.progress')
    obj.cache.ui.progressLine = uiNewFile.find('.progress div')
    obj.cache.ui.previewImg = uiNewFile.find('img.preview')
    obj.cache.ui.fileSize = uiNewFile.find('.file-size')
    obj.cache.ui.fileIcon = uiNewFile.find('.file-icon')
    obj.cache.ui.label = uiNewFile.find('.label')
    obj.cache.ui.thumbnailProcessing = uiNewFile.find('.thumbnail-processing')

    // 2. 初始化完成後，秀出附件檔案介面
    TweenMax.set(uiNewFile, { opacity: 0 })
    this.ui.fileUi.prepend(uiNewFile) // 最新的排在最前面，主要與 NAS 呈現方式一致
    TweenMax.to(uiNewFile, 0.2, { opacity: 1, delay: 0.1 })
  }
}

export default IdeaEditorUploadFiles