// 
// GtListTableMultiSelectSupport ( 1.0.4.0 )
// 

import { GtUiComponent } from '../../shared/core/GtUi'

class GtListTableMultiSelectSupport extends GtUiComponent {
  name() {
    return 'GtListTableMultiSelectSupport'
  }

  fetchUi() {
    this.ui.selectTips = $('.list-table-select-tips')
    this.ui.tipCount = this.ui.selectTips.find('.select-count')
    this.ui.listTable = $('.multi-select')
    this.ui.rows = this.ui.listTable.find('tr[data-id]')
  }

  checkUi() {
    this.fetchUi()

    const result = 
      this.ui.listTable.length == 1 &&
      this.ui.rows.length > 0 &&
      this.ui.selectTips.length == 1 &&
      this.ui.tipCount.length == 1

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.initSelectTipsUi()
    this.initTableUi()

    this.ui.rows.each((_, self) => {
      this.asyncInit(self)
    })
  }

  asyncInit(element) {
    if (element instanceof jQuery) {
      this.initTableRow(element)
    } else {
      const uiElement = $(element)
      if (uiElement.length < 1) { return }

      this.initTableRow(uiElement)
    }
  }
  
  initSelectTipsUi() {
    this.ui.selectTips.find('.select-all').click(e => {
      e.preventDefault()
      this.selectAll()
    })

    this.ui.selectTips.find('.unselect-all').click(e => {
      e.preventDefault()
      this.unSelectAll()
    })
  }


  initTableUi() {
    this.ui.listTable.on('click', 'tr[data-id]', e => {
      if (/(?:A|IMG|I|EM)/.test(e.target.nodeName)) {
        return
      }

      const uiRow = $(e.currentTarget)
      const uiCheckbox = uiRow.data('uiCheckbox')

      const ignore = uiRow.data('disabled') != null || uiCheckbox == null
      if (ignore) { return }

      uiCheckbox.prop('checked', !uiCheckbox.prop('checked')).change()
    })
  }

  initTableRow(uiRow) {
    const dataId = uiRow.data('id')
    if ((dataId == null)) { return }

    const uiCheckBoxId = `ltmss${dataId}`

    uiRow.children('td:eq(0)').append(`<div class=\"hidden\"><input id=\"${uiCheckBoxId}\" type=\"checkbox\" name=\"ids[]\" value=\"${dataId}\" class=\"ids multi-select-checbkox\"></div>`)

    const uiCheckbox = $(`\#${uiCheckBoxId}`)

    uiCheckbox.on('change', _ => {
      uiRow.toggleClass('checked', uiCheckbox.prop('checked'))
      this.showChoseCount()
    })

    uiRow.data('uiCheckbox', uiCheckbox)
  }

  selectAll() {
    this.ui.listTable.find('.ids').prop('checked', true).change()
  }

  unSelectAll() {
    this.ui.listTable.find('.ids:checked').prop('checked', false).change()
  }

  showChoseCount() {
    if (this.ui.tipCount == null) { return }

    const count = this.ui.listTable.find('.ids:checked').length
    this.ui.tipCount.text(count)

    if (count == 0) {
      if (this.ui.selectTips.css('display') == 'none') { return }

      TweenMax.set(this.ui.selectTips, { display: 'block', opacity: 1, y: 0 })
      TweenMax.to(this.ui.selectTips, 0.3, { display: 'none', opacity: 0, y: 20 })
    } else {
      if (this.ui.selectTips.css('display') == 'block') { return }

      TweenMax.set(this.ui.selectTips, { display: 'none', opacity: 0, y: 20 })
      TweenMax.to(this.ui.selectTips, 0.3, { display: 'block', opacity: 1, y: 0 })
    }
  }
}

export default GtListTableMultiSelectSupport