// 
// MiniCharts ( 0.5.0.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'

class MiniCharts extends GtUiComponent {
  name() {
    return 'MiniCharts'
  }

  fetchUi() {
    this.ui.miniCharts = $('.mini-chart')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.miniCharts.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.miniCharts.each((index, self) => {
      const delay = index * 30
      setTimeout(() => this.asyncInit(self), delay)
    })
  }

  asyncInit(element) {
    const uiElement = $(element)
    if (uiElement.length < 1) { return }

    this.initChart(uiElement)
  }

  initChart(uiChart) {
    try {
      const chartData = uiChart.data('chart')

      let syntax = ''

      if (chartData != null) {
        chartData.forEach(v => syntax += `<div><span class=\"status-${v[0]}\" style=\"height:${v[1]}%;\"></span></div>`)
      }

      uiChart.html(syntax)

    } catch (e) {
      // ignore
    }
  }
}

export default MiniCharts