import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueClipboard from 'vue-clipboard2'
import VueNotifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import App from './app.vue'

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#3b477b',
  cancelButtonColor: '#ff7674'
})

Vue.use(VueClipboard)
Vue.use(VueNotifications)
Vue.use(VTooltip)

Vue.config.productionTip = false
Vue.config.devtools = window.location.search.indexOf('debug=1') > -1

function setupVueApp () {
  const eleApp = document.getElementById('nas_app')

  if (eleApp) {
    new Vue({ render: h => h(App)}).$mount(eleApp)
  }
}

document.addEventListener('DOMContentLoaded', setupVueApp)