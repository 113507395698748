// 
// MerchantUserInviteUrl ( 0.5.1.0 )
//
import { GtUiComponent } from '../../shared/core/GtUi'
import ClipboardJS from 'clipboard'

class MerchantUserInviteUrl extends GtUiComponent {
  name() {
    return 'MerchantUserInviteUrl'
  }

  fetchUi() {

    this.ui.main = $('body')
    this.ui.inviteLinkArea = $('#invite_link')
    this.ui.copyInviteUrl = $('.copy-invite-url')

    // this.ui.emojiBtn = this.ui.main.find('a.editor-btn.emoji')
    // this.ui.copyBtn = this.ui.main.find('a.editor-btn.copy-article')
    // this.ui.galleryBtn = this.ui.main.find('a.editor-btn.gallery')
    // this.ui.galleryFileInput = this.ui.main.find('.gallery-file input')
    // this.ui.findIdeasBtn = this.ui.main.find('a.editor-btn.find-ideas')
  }

  checkUi() {
    this.fetchUi()

    const result =
      this.ui.main.length === 1 &&
      this.ui.inviteLinkArea.length === 1 &&
      this.ui.copyInviteUrl.length === 1

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.initCopyBtnUi()
    this.ui.inviteLinkArea.on('click', function () { $(this).select() })
  }

  initCopyBtnUi() {
    this.ui.copyInviteUrl.on('click', e => e.preventDefault())

    const clipboard =
      new ClipboardJS(this.ui.copyInviteUrl[0], { text: t => this.copyContent()})

    clipboard.on('success', _ => {
      window.PageHelper.notify(window.ui18.msg.copyCompleted, window.ui18.label.copy, 'fas fa-check')
    })

    clipboard.on('error', _ => {
      window.PageHelper.notify(window.ui18.msg.copyFailed, window.ui18.label.copy, 'fas fa-times')
    })
  }

  copyContent() {
    return this.ui.inviteLinkArea.val()
  }

}

export default MerchantUserInviteUrl