<template>
  <div
    class="storage-sort-status"
  >
    <transition name="fade">
      <div
        v-if="column != '' && order != ''"
        class="status"
      >
        <i :class="icon"></i> {{ label }} <span>{{ rangeA }} <i class="fas fa-caret-right"></i> {{ rangeB }}</span> 排列
      </div>
    </transition>
  </div>
</template>

<script>
// 
// StorageSortStatus ( 0.8.0.1 )
// 
export default {
  name: 'StorageSortStatus',

  props: {
    column: { type: String, default: '' },
    order:  { type: String, default: '' }
  },

  computed: {

    label () {
      let result = '未知'

      switch (this.column) {
        case 'name':      result = '依名稱'; break
        case 'id':        result = '依日期'; break
        case 'size':      result = '依大小'; break
        case 'extension': result = '依種類'; break
      }

      return result
    },

    icon () {
      let result = 'fas fa-fw fa-signature'

      switch (this.column) {
        case 'name':      result = 'fas fa-fw fa-signature';    break
        case 'id':        result = 'far fa-fw fa-calendar-alt'; break
        case 'size':      result = 'fas fa-fw fa-hdd';          break
        case 'extension': result = 'far fa-fw fa-file-pdf';     break
      }

      return result
    },

    rangeA () {
      let result = '?'

      switch (`${this.column}:${this.order}`) {
        case 'name:asc':       result = 'A';    break
        case 'name:desc':      result = 'Z';    break
        case 'id:asc':         result = '1/1';  break
        case 'id:desc':        result = '1/31'; break
        case 'size:asc':       result = '1';    break
        case 'size:desc':      result = '9';    break
        case 'extension:asc':  result = 'A';    break
        case 'extension:desc': result = 'Z';    break
      }

      return result
    },

    rangeB () {
      let result = '?'

      switch (`${this.column}:${this.order}`) {
        case 'name:asc':       result = 'Z';    break
        case 'name:desc':      result = 'A';    break
        case 'id:asc':         result = '1/31'; break
        case 'id:desc':        result = '1/1';  break
        case 'size:asc':       result = '9 MB';    break
        case 'size:desc':      result = '1 MB';    break
        case 'extension:asc':  result = 'Z';    break
        case 'extension:desc': result = 'A';    break
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.storage-sort-status {
  display: inline-block;

  padding-right: 1em;

  vertical-align: middle;

  > * {
    vertical-align: middle;
  }

  > .btn {
    border: 1px solid #5469d4;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    width: 24px;
    height: 24px;
  }

  > .status {
    display: inline-block;

    color: white;
    background-color: #5469d4;
    font-size: 13px;
    padding: 0.125em 0.5em;
    border-radius: 5px;
    vertical-align: middle;

    span {
      margin-left: 0.5em;

      i {
        margin-left: 0.25em;
        margin-right: 0.25em;
      }
    }
  }
}
</style>