<template>
  <div
    class="img2"
    :style="{ width: `${width}px`, height: `${height}px` }"
  >
    <template v-if="fileIconCss!='far fa-file-image'">
      <i :class="fileIconCss + ' fa-2x'" :style="{ opacity: 1 }"></i>
    </template>
    <template v-else>
      <i class="fas fa-fw fa-times" :style="{ opacity: errorIconOpacity }"></i>
      <img
        ref="img"
        draggable="false"
        :src="imgRealLoadUrl"
        :style="{ opacity: imgOpacity }"
        @load="loaded"
        @error="error"
      />
    </template>
  </div>
</template>

<script>
import { getFileExtName, getFileIconCss } from '../../shared/core/util/common'

// 
// Img2 ( 0.9.3.1 )
// 
export default {
  name: 'Img2',

  props: {
    src:    { type: String, default: '' },
    large:  { type: String, default: '' },
    width:  { type: Number, default: 0 },
    height: { type: Number, default: 0 }
  },

  data () {
    return {
      fileIconCss: '',
      imgRealLoadUrl: '',
      imgOpacity: 0,
      errorIconOpacity: 0
    }
  },

  mounted () {
    const fileName = this.large.split('/').pop().replace(/\?.+/, '')
    const fileExtName = getFileExtName(fileName)
    this.fileIconCss = getFileIconCss(fileExtName)

    this.imgRealLoadUrl = this.src
  },

  methods: {
    loaded () {
      const img = this.$refs.img

      const previewImageGenerating = 
        img && img.naturalWidth == 50 && img.naturalHeight == 50

      if (!previewImageGenerating) {
        this.imgOpacity = 1

      } else {
        setTimeout(() => {
          this.imgRealLoadUrl = `${this.src}&t=${+new Date}`
        }, 4500)
      }
    
    },

    error () {
      this.errorIconOpacity = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.img2 {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  i {
    opacity: 0;
    position: absolute;

    color: lighten(gray, 30%);
    transition: opacity .2s ease;

    &.fa-times {
      color: lighten(#f44336, 20%);
    }

    // &.file {
    //   color: lighten(gray, 10%);
    // }
  }

  img {
    opacity: 0;
    position: absolute;
    transition: opacity .3s ease;

    object-fit: cover;

    width: 100%;
    height: 100%;
  }
}
</style>