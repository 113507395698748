/* eslint no-console:0 */
window.jQuery = window.$ = jQuery

require('chartkick')

require('@rails/ujs').start()

const extendsScripts =
  require.context('application/shared/extends', true, /\.js$/)

extendsScripts.keys().forEach(extendsScripts)

require('application/adops')
require('application/page_helper')
require('application/nas')