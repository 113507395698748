// 
// TrackingPostsPageUpdateSupport ( 0.9.3.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'

class TrackingPostsPageUpdateSupport extends GtUiComponent {
  name() {
    return 'TrackingPostsPageUpdateSupport'
  }

  fetchUi() {
    return this.ui.main = $('.tracking_posts.index')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.main.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    // ignore
  }

  updateTrackingPost(params) {
    const uiRow = $(`\#row${params.id}`)

    const uiUpdatedMark = uiRow.find('.post-details .updated-mark')
    if (uiUpdatedMark.length) { uiUpdatedMark.show() }

    const uiTags = uiRow.find('.post-details .tags')
    if (uiTags.length) { uiTags.html(params.tagsContent) }

    const uiCategory = uiRow.find('.post-details .category')
    if (uiCategory.length) { uiCategory.html(params.categoryContent) }

    const uiPostTitle = uiRow.find('.post-details .post-title')
    if (uiPostTitle.length) { uiPostTitle.show().text(params.postTitleContent) }
  }

  acceptMultiEdit() {
    const accept = $('.list-table tr.checked').length > 0

    if (accept) {
      return true
    } else {
      alert("系統提示：請先選擇貼文，才能進行修改噢")
      return false
    }
  }
}

export default TrackingPostsPageUpdateSupport