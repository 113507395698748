<template>
  <div
    v-tooltip="timetime"
    :class="classObject"
    :data-uuid="uuid"
    :data-id="id"
    :data-folder-id="folderId"
    @click.prevent="chose"
  >
    <transition name="fade">
      <div v-if="dataUpdating" class="data-updating">
        <div><i class="fas fa-spinner fa-spin fa-1x"></i></div>
      </div>
    </transition>

    <div v-if="userEditObjectNameMode" class="rename-dialog">
      <div>
        <p class="title">
          <i class="fas fa-eraser"></i> 重新命名
        </p>
        <textarea
          ref="renameInput"
          v-model="renameInputValue"
          spellcheck="false"
          onfocus="this.select();"
          autocomplete="off"
        ></textarea>
        <a
          class="btn-confirm"
          href="#"
          @click.prevent="confirmRename"
        ><i class="fas fa-check"></i></a>
        <a
          class="btn-cancel"
          href="#"
          @click.prevent="cancelRenaming"
        ><i class="fas fa-times"></i></a>
      </div> 
    </div>

    <div
      ref="preview"
      class="preview"
      draggable="true"
    >
      <div v-if="hasUploaded">
        <a
          v-if="bindToArticle"
          v-tooltip.left="'已加入到文案內'"
          class="bind-to-article"
          href="#"
          @click.prevent=""
        ><i class="fas fa-share-square"></i></a>
        <div v-if="iconCss == 'far fa-file-image'">
          <img
            ref="previewImg"
            draggable="false"
            :style="{ opacity: previewImageOpacity }"
            :src="tempPreviewUrl"
            @load="showPreviewImageWhenLoaded"
          />
          <transition name="fade">
            <div v-if="!previewImageOpacity" class="image-loading">
              <i class="fas fa-fw fa-spinner fa-spin"></i><span>{{ loadingTips }}</span>
            </div>
          </transition>
        </div>
        <div v-else>
          <div class="file-icon">
            <i :class="iconCss"></i>
          </div>
        </div>
        <a
          href="#download"
          class="btn-download"
          content="下載"
          target="_blank"
          @click.prevent="download"
        ><i class="fas fa-fw fa-lg fa-download"></i></a>
        <a
          ref="copyBtn"
          v-clipboard:copy="storageUrl"
          v-clipboard:success="copyStorageUrlSuccess"
          v-clipboard:error="copyStorageUrlError"
          class="btn-copy"
          href="#copy"
          v-tooltip.left="'複製連結'"
          @click.prevent
        >
          <i v-if="copyStorageUrlStatus === 'success'" class="fas fa-fw fa-lg fa-check"></i>
          <i v-else-if="copyStorageUrlStatus === 'error'" class="fas fa-fw fa-lg fa-times"></i>
          <i v-else class="fas fa-fw fa-lg fa-link"></i>
        </a>
        <a
          href="#search"
          class="btn-search"
          target="_blank"
          v-tooltip.right="'尋找相關文案'"
          @click.prevent="searchArticleByAsset"
        ><i class="fas fa-fw fa-lg fa-search"></i></a>
        <a
          href="#remove"
          class="btn-remove"
          @dblclick.prevent="destroy"
        ><i class="fas fa-fw fa-times"></i></a>
      </div>
      <div v-else-if="status == 'uploadError' || status == 'uploadTimeout' || status == 'uploadAbort'">
        <div class="file-icon">
          <i :class="iconCss"></i>
        </div>
        <div class="progress">
          <i style="width: 0;"></i>
        </div>
        <div class="uploading-icon">
          <i class="fas fa-times"></i>
        </div>
        <a
          href="#remove"
          class="btn-remove"
          @dblclick.prevent="terminate"
        ><i class="fas fa-fw fa-times"></i></a>
      </div>
      <div v-else>
        <div class="file-icon">
          <i :class="iconCss"></i>
        </div>
        <div class="progress">
          <i :style="progressBarLineStyle"></i>
        </div>
        <div class="uploading-icon">
          <i :class="uploadingIconCss"></i>
        </div>
        <a
          href="#remove"
          class="btn-remove"
          @dblclick.prevent="terminate"
        ><i class="fas fa-fw fa-times"></i></a>
      </div>
    </div>

    <storage-file-rating
      :number="userRating"
      :api-updating="userRatingApiUpdating"
      @update="ratingFile"
    ></storage-file-rating>

    <div class="name">
      <div>{{ name }}</div>
    </div>

    <div class="extension">
      <div><i :class="iconCss"></i>{{ extension }}&nbsp;&nbsp;{{ humanSize }}</div>
    </div>
  </div>
</template>

<script>
import StorageFileRating from './StorageFileRating.vue'

import _ from 'lodash'

// 
// StorageFile ( 0.8.9.0 )
// 
export default {
  name: 'StorageFile',

  components: {
    StorageFileRating
  },

  props: {
    uuid:             { type: String, default: '' },
    id:               { type: Number, default: 0  },
    folderId:         { type: Number, default: 0  },
    status:           { type: String, default: '' },
    name:             { type: String, default: '' },
    extension:        { type: String, default: '' },
    size:             { type: Number, default: 0  },
    humanSize:        { type: String, default: '' },
    createdAt:        { type: Number, default: 0 },
    iconCss:          { type: String, default: '' },
    previewUrl:       { type: String, default: '' },
    storageUrl:       { type: String, default: '' },
    storageSourceId:  { type: String, default: '' },
    uploadProgress:   { type: Number, default: 0 },
    uploadTxBytes:    { type: Number, default: 0 },
    uploadTotalBytes: { type: Number, default: 0 },
    userChose:        { type: Boolean, default: false },
    userChoseByUiSection: { type: Boolean, default: false },
    userCutObject: { type: Boolean, default: false },
    userEditObjectNameMode:  { type: Boolean, default: false },
    dataUpdating:     { type: Boolean, default: false },
    userRating:       { type: Number, default: 0 },
    userRatingApiUpdating: { type: Boolean, default: false },
    userRatingApiTimer: { type: Number, default: 0 },
    bindToArticle: { type: Boolean, default: false }
  },

  emits: [
    'rating',
    'cancel-chose',
    'chose',
    'rename-file',
    'cancel-file-renaming',
    'destroy',
    'terminate-upload'
  ],

  data () {
    return {
      renameInputValue: '',
      copyStorageUrlStatus: 'new',
      previewImageOpacity: 0,
      tempPreviewUrl: '',
      loadingTips: '載入中'
    }
  },

  computed: {
    classObject () {
      return {
        'storage-file': true,
        'updating': this.dataUpdating,
        'user-chose': this.userChose,
        'user-cut-object': this.userCutObject
      }
    },

    uploadingIconCss () {
      if ( this.status == 'uploading' ) {
        return 'fas fa-spinner fa-spin'
      } else {
        return 'fas fa-cloud-upload-alt'
      }
    },

    progressBarLineStyle () {
      return {
        'width': `${this.uploadProgress}%`
      }
    },

    showAbortButton () {
      return /^(?:empty|new|uploading|uploadError|uploadTimeout|uploadAbort)$/i.test(this.status)
    },

    showDestroyButton () {
      return /^(?:uploaded)$/i.test(this.status)
    },

    hasUploaded () {
      return /^(?:uploaded|stored)$/i.test(this.status)
    },

    timetime () {
      return timeago.format(this.createdAt*1000, 'zh_TW')
    }
  },

  watch: {

    previewUrl: {
      immediate: true,
      handler (newValue, originalValue) {
        this.tempPreviewUrl = newValue
      }
    },

    userEditObjectNameMode: {
      immediate: true,
      handler (newValue, originalValue) {
        if ( !originalValue && newValue == true ) {
          setTimeout(() => {
            if ( !this.$refs.renameInput ) { return }

            this.$refs.renameInput.focus()
          }, 100)
        }
      }
    }
  },

  mounted () {
    this.renameInputValue = this.name
  },

  methods: {

    showPreviewImageWhenLoaded () {
      const previewImageGenerating = 
        this.$refs.previewImg &&
        this.$refs.previewImg.naturalWidth == 50 &&
        this.$refs.previewImg.naturalHeight == 50

      if ( previewImageGenerating ) {
        this.previewImageOpacity = 0
        this.loadingTips = '產生縮圖中'

        setTimeout(() => {
          this.tempPreviewUrl = `${this.previewUrl}&t=${+new Date}`
        }, 4500)
      } else {
        this.previewImageOpacity = 1
      }
    },

    download () {
      if ( _.isEmpty(this.storageUrl) ) { return }

      window.open(this.storageUrl)
    },

    searchArticleByAsset () {
      if (this.id) {
        window.open(`/ideas?asset_search=${this.id}`)
      }
    },

    destroy () {
      this.$emit('destroy', { uuid: this.uuid, type: 'file' })
    },

    terminate () {
      this.$emit('terminate-upload', { uuid: this.uuid })
    },

    cancelChose () {
      this.$emit('cancel-chose', { uuid: this.uuid })
    },

    choseByUiSection () {
      const ignore =
        this.userChose || this.ignoreChose()

      if ( ignore ) { return }

      this.$emit('chose', { uuid: this.uuid, by: 'uiSection' })
    },

    chose (e) {
      const ignore =
        this.ignoreChose() || e.target.closest('a[class^=btn],.storage-file-rating')

      if ( ignore ) { return }

      this.$emit('chose', { uuid: this.uuid, by: 'click' })
    },

    confirmRename () {
      const newName = this.$refs.renameInput.value
      if ( _.isEmpty(newName) ) { return }

      this.$emit('rename-file', {
        uuid: this.uuid,
        newName,
        renameCallback: (newName) => {
          this.renameInputValue = newName
        }
      })
    },

    cancelRenaming () {
      this.$emit('cancel-file-renaming', { uuid: this.uuid })
    },

    ignoreChose () {
      return this.userEditObjectNameMode || this.dataUpdating
    },

    copyStorageUrlSuccess () {
      this.copyStorageUrlStatus = 'success'

      TweenMax.set(this.$refs.copyBtn, { x: 0, y: 5 })
      TweenMax.to(this.$refs.copyBtn, 1.5, { x: 0, y: 0, ease: Elastic.easeOut.config(1, 0.3) })

      setTimeout(() => { this.copyStorageUrlStatus = 'new' }, 900)
    },

    copyStorageUrlError () {
      this.copyStorageUrlStatus = 'error'
    },

    ratingFile ({ ratingNumber }) {
      this.$emit('rating', { uuid: this.uuid, ratingNumber })
    },

    getPreviewBlockDomRect () {
      if ( this.$refs.preview ) {
        return this.$refs.preview.getBoundingClientRect()
      } else {
        return
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>