// 
// AutoApplyTippy ( 0.1.1.0.es6 )
// 
import tippy from 'tippy.js'
import { GtUiComponent } from '../../shared/core/GtUi'

class AutoApplyTippy extends GtUiComponent {
  name() {
    return 'AutoApplyTippy'
  }

  fetchUi() {
    this.ui.tippyElements = $('.tippy,.tp')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.tippyElements.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initTippyElements()

    return true
  }

  destroy() {
    return true
  }

  initTippyElements() {
    this.ui.tippyElements.each((index, element) => {
      this.initTippy(element)
    })
  }

  initTippy(element) {
    const uiElement = $(element)
    const hintText = uiElement.attr('title') || ''
    if (hintText.length < 1) { return }

    const placement =
      uiElement.hasClass('top') ?
        'top'
      : uiElement.hasClass('bottom') ?
        'bottom'
      : uiElement.hasClass('right') ?
        'right'
      : 
        'left'

    const options = {
      content: hintText,
      placement
    }

    const useFocusTrigger = 
      uiElement.prop('tagName') === 'INPUT'

    if (useFocusTrigger) { options.trigger = 'focus' }

    tippy(element, options) 
  }
}

export default AutoApplyTippy