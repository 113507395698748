// 
// SystemActiveLogFormatter ( 0.9.3.1.es6 )
// 
import moment from 'moment'

class SystemActiveLogFormatter {

  constructor(originalLogs) {
    this.originalLogs = originalLogs // json data
    this.formattedLogs = []

    this.timeRangeAcceptMergeCondition = 60 * 10
  }

  createEmptyModifyActionLog() {
    return {
      tag: '',
      time: 0,
      count: 0,
      showUserName: false,
      recordIds: [],
      urls: [],
      comments: [],
      isCreateArticle: false,
      isCreatePost: false,
      isEditArticle: false,
      isDestroyArticle: false,
      isDestroyPost: false,
      isUploadImage: false,
      isDestroyFile: false
    }
  }

  createFormattedLog() {
    return {
      timestamp: 0,
      time: 0,
      timeAgo: '',
      current: '',
      userId: '',
      userName: '',
      userImg: '',
      debug: [],
      destroyAlert: false,
      userNameIsDisplayed: false,
      userModifyActionLogs: []
    }
  }

  format() {
    //
    // 備註
    //
    //   1. 以下程式碼中的 prevLog、formattedLog、modifyActionLog 三組變數
    //      資料處理上，都是採傳址的方式傳遞跟修改，因此，維護需要多加留意
    //
    let prevLogUserId = null
    let prevLogTimestamp = null
    let prevLog = null

    for (var originalLog of Array.from(this.originalLogs)) {
      const timeRangeAcceptMerge =
        (prevLogTimestamp != null) ?
          (prevLogTimestamp - originalLog.time) <= this.timeRangeAcceptMergeCondition
        :
          true

      const mergeLog = timeRangeAcceptMerge && (prevLogUserId === originalLog.userId)

      var createNewLog = !mergeLog

      const formattedLog =
        (() => {
          if (createNewLog) {
            const newLog = this.createFormattedLog()
            newLog.timestamp = originalLog.time // is ruby timestamp
            newLog.time      = moment(originalLog.time * 1000).format() // use js time
            newLog.timeAgo   = timeago.format(originalLog.time * 1000, 'zh_TW') // use js time
            newLog.userId    = originalLog.userId
            newLog.userName  = originalLog.userName
            newLog.userImg   = originalLog.userImg
            return newLog
          } else {
            return prevLog
          }
        })()

      if (createNewLog) {
        this.formattedLogs.push(formattedLog)
      }

      formattedLog.debug.push(originalLog.tag)

      let modifyActionLog =
        _.find(formattedLog.userModifyActionLogs, { tag: originalLog.tag })

      if ((modifyActionLog != null) !== true) {
        modifyActionLog = this.createEmptyModifyActionLog()
        modifyActionLog.time = moment(originalLog.time * 1000).format()
        modifyActionLog.tag = originalLog.tag
        formattedLog.userModifyActionLogs.push(modifyActionLog)
      }
      
      if (formattedLog.userNameIsDisplayed !== true) {
        modifyActionLog.showUserName = true
        formattedLog.userNameIsDisplayed = true
      }

      switch (modifyActionLog.tag) {
        case 'create_article':
          modifyActionLog.count += 1
          modifyActionLog.isCreateArticle = true
          modifyActionLog.recordIds.push(originalLog.recordId)
          modifyActionLog.urls.push({ img: originalLog.previewImg, url: originalLog.url })
          break

        case 'create_post':
          modifyActionLog.count += 1
          modifyActionLog.isCreatePost = true
          modifyActionLog.recordIds.push(originalLog.recordId)
          modifyActionLog.urls.push({ img: originalLog.previewImg, url: originalLog.url })
          break

        case 'upload_file':
          modifyActionLog.count += 1
          modifyActionLog.isUploadImage = true
          break

        case 'edit_article':
          modifyActionLog.count += 1
          modifyActionLog.isEditArticle = true
          modifyActionLog.recordIds.push(originalLog.recordId)
          modifyActionLog.urls.push({ img: originalLog.previewImg, url: originalLog.url })
          break

        case 'destroy_article':
          modifyActionLog.count += 1
          modifyActionLog.isDestroyArticle = true
          modifyActionLog.comments.push(originalLog.comment)
          formattedLog.destroyAlert = true
          break

        case 'destroy_post':
          modifyActionLog.count += 1
          modifyActionLog.isDestroyPost = true
          modifyActionLog.comments.push(originalLog.comment)
          formattedLog.destroyAlert = true
          break

        case 'destroy_file':
          modifyActionLog.count += 1
          modifyActionLog.isDestroyFile = true
          modifyActionLog.comments.push(originalLog.comment)
          break
      }

      // 記錄上一筆資料
      prevLog = formattedLog
      prevLogUserId = originalLog.userId
      prevLogTimestamp = originalLog.time
    }
    
    // 回傳 format 好的 logs
    return this.formattedLogs
  }
}

export default SystemActiveLogFormatter