// 
// storageContextMenuMixin ( 0.5.5.0.alpha )
// 
export default {

  data: function () {
    return {
      contextMenu: {
        opener: null,
        eleStorageObject: null,
        visible: false,
        positionTop: 0,
        positionLeft: 0,
        pasteFolder: null,
        acceptUploadFile: false,
        acceptCreateFolder: false,
        acceptRenameSingleObject: false,
        acceptRenameMultiFiles: false,
        acceptCutObject: false,
        acceptPasteObject: false,
        acceptDeleteSingleObject: false,
        acceptDeleteMultiObject: false
      }
    }
  },

  methods: {

    showContextMenu (e) {
      if ( !this.isOpened ) { return }

      this.contextMenu.acceptUploadFile = true
      this.contextMenu.acceptCreateFolder = false
      this.contextMenu.acceptRenameSingleObject = false
      this.contextMenu.acceptRenameMultiFiles = false
      this.contextMenu.acceptCutObject = false
      this.contextMenu.acceptPasteObject = false
      this.contextMenu.acceptDeleteSingleObject = false
      this.contextMenu.acceptDeleteMultiObject = false

      const disabledContextMenu =
        _.isObject(e.target.closest('.top-navigator'))

      if ( disabledContextMenu ) {
        this.$refs.storageContextMenu.disable({
          before: () => {
            this.contextMenu.opener = null
            this.contextMenu.eleStorageObject = null
            this.contextMenu.visible = false
          }
        })

        return
      }

      e.preventDefault()

      this.contextMenu.opener = e.target

      const matchElement =
        e.target.closest('div[class^=storage-]')

      const isStorageObject =
        matchElement &&
        (/^storage-(?:file |folder |file$|folder$)/i).test(matchElement.className)

      const isStorageFolderObject =
        matchElement &&
        (/^storage-(?:folder |folder$)/i).test(matchElement.className)

      const isSystemDefaultObject = 
        matchElement && (/system-default/i).test(matchElement.className)

      this.contextMenu.eleStorageObject =
        isStorageObject ? matchElement : null

      this.contextMenu.pasteFolderId = null

      this.contextMenu.acceptCreateFolder = true

      this.contextMenu.acceptDeleteSingleObject =
        !isSystemDefaultObject && isStorageObject

      this.contextMenu.acceptDeleteMultiObject =
        !isSystemDefaultObject && this.choseFilesCount > 0

      this.contextMenu.acceptRenameMultiFiles =
        this.choseFilesCount > 0

      this.contextMenu.acceptRenameSingleObject =
        !isSystemDefaultObject && isStorageObject

      this.contextMenu.acceptCutObject =
        !isSystemDefaultObject && this.choseFilesCount > 0

      if ( isStorageFolderObject ) {
        this.contextMenu.pasteFolderId = matchElement.dataset.id
        this.contextMenu.acceptPasteObject = this.tempZoneFilesCount > 0
      } else {
        this.contextMenu.pasteFolderId = this.currentFolderId
        this.contextMenu.acceptPasteObject =
          this.tempZoneFilesCount > 0 &&
          this.tempZoneFilesOldFolderId != this.currentFolderId
      }

      this.contextMenu.positionTop =
        window.scrollY + e.clientY + 5

      this.contextMenu.positionLeft =
        window.scrollX + e.clientX + 10

      if ( this.contextMenu.visible ) {
        this.$refs.storageContextMenu.updatePosition({
          top: this.contextMenu.positionTop,
          left: this.contextMenu.positionLeft
        })

      } else {
        this.$refs.storageContextMenu.show({
          top: this.contextMenu.positionTop,
          left: this.contextMenu.positionLeft,
          onComplete: () => {
            this.contextMenu.visible = true
          }
        })
      }

    },

    hideContextMenu (e) {
      const menuDisplayed =
        this.contextMenu.visible && this.contextMenu.opener

      const ignore =
        !menuDisplayed || e.button != 0 || e.target.closest('.has-submenu')

      if ( ignore ) { return }

      this.$refs.storageContextMenu.hide({
        onComplete: () => {
          this.contextMenu.opener = null
          this.contextMenu.eleStorageObject = null
          this.contextMenu.visible = false
        }
      })

    },

    destroyContextMenu () {
      document.removeEventListener('contextmenu', this.showContextMenu)
      document.removeEventListener('mousedown', this.hideContextMenu)
    },

    setupContextMenu () {
      document.addEventListener('contextmenu', this.showContextMenu)
      document.addEventListener('mousedown', this.hideContextMenu)
    }

  }

}