// 
// storageNoticeMixin ( 0.8.1.0 )
// 
export default {

  methods: {

    notify (text, title, icon='far fa-comment') {
      this.$notify({
        group: 'storage',
        title: title.replace(/[\<\>\"\']/gi, ''),
        text: text.replace(/[\<\>\"\']/gi, ''),
        data: { icon: icon }
      })
    },

    async deleteConfirm (options={}) {
      const message = options.message.replace(/[\<\>\"\']/gi, '')
      const events = options.events

      events.accept =
        _.isFunction(events.accept) ? events.accept : () => {}

      events.reject =
        _.isFunction(events.reject) ? events.reject : () => {}

      const { value: accept } = await this.$swal({
        title: '確認刪除',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder: message,
        confirmButtonText: '確定 <i class="fas fa-check"></i>',
        inputValidator: (result) => {
          return !result && '請勾選上方確認'
        }
      })

      if ( accept ) {
        events.accept()
      } else {
        events.reject()
      }
    },

    alertDialog (text, title, dialogIcon='info', events={}) {
      text = text.replace(/[<>\/]/gi, '')
      title = title.replace(/[<>\/]/gi, '')

      events.closed =
        _.isFunction(events.closed) ? events.closed : () => {}

      this.$swal(title, text, dialogIcon)
          .then(result => { events.closed(result) })
    }

  }

}