import axios from 'axios'
import _ from 'lodash'

// 
// FbOperator ( 0.7.1.0 )
// 
class FbOperator {

  name () {
    return 'FbOperator'
  }

  constructor({
    events: {
      fbSdkInitFailed=null,
      beforeGetPageAccount=null,
      syncPageAccountSuccess=null,
      syncPageAccountFailed=null,
      beforeExchangeToken=null,
      exchangeTokenFailed=null,
      requestUserLoginFailed=null
    }
  }) {
    this.events = {
      fbSdkInitFailed,
      beforeGetPageAccount,
      syncPageAccountSuccess,
      syncPageAccountFailed,
      beforeExchangeToken,
      exchangeTokenFailed,
      requestUserLoginFailed
    }
  }

  createHttpClient (options={}) {
    const timeout = options.timeout || 5000
    const instance = axios.create({ timeout })
    return instance
  }

  checkFbSdkInited () {
    return !!window.fbSdkInited
  }

  getLongLivedUserAccessToken({
    accessToken
  }) {
    this.events.beforeExchangeToken()

    const params = { access_token: accessToken }

    const client = this.createHttpClient()
    client.post('/fb_application/exchange_token', params)
          .then((res) => {
            this.getCurrentFacebookPageAccounts({
              longLiveAccessToken: res.data.data.long_live_access_token
            })
          })
          .catch(err => this.events.exchangeTokenFailed(err))
  }

  uploadPageAccountDataToAdops({
    pageAccounts
  }) {
    const params = { page_accounts: pageAccounts }

    const client = this.createHttpClient()
    client.post('/fb_application/sync_page_accounts', params)
          .then(res => this.events.syncPageAccountSuccess({ pageAccounts, res }))
          .catch(err => this.events.syncPageAccountFailed({ err }))
  }

  getCurrentFacebookPageAccounts ({
    longLiveAccessToken
  }) {

    const params = {
      fields: [
        'id',
        'name',
        'about',
        'access_token',
        'picture.type(large)'
      ].join(','),
      access_token: longLiveAccessToken
    }

    FB.api('/me/accounts', (res) => {
      this.events.beforeGetPageAccount()

      const pageAccounts = []

      for (const item of res.data) {
        const accountData = {
          id: item.id,
          name: item.name,
          about: item.about,
          access_token: item.access_token,
          picture: _.get(item, 'picture.data.url', '')
        }

        pageAccounts.push(accountData)
      }

      this.uploadPageAccountDataToAdops({ pageAccounts })
    }, params)
  }

  requestUserLogin() {
    if (!this.checkFbSdkInited()) {
      this.events.fbSdkInitFailed()
      return
    }

    const scope = [
      'public_profile',
      'email',
      'pages_show_list',
      'pages_read_engagement',
      'pages_read_user_content'
    ]

    FB.login((response) => {
      if (response.authResponse) {
        FB.getLoginStatus((response) => {
          this.getLongLivedUserAccessToken({
            accessToken: response.authResponse.accessToken
          })
        })
      } else {
        this.events.requestUserLoginFailed()
      }
    }, {
      scope: scope.join(',')
    })
  }

}

export default FbOperator