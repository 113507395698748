// 
// storageFileUiSectionProcessMixin ( 0.8.0.0 )
// 
export default {

  data: function () {
    return {
      uiSection: {
        enabled: false,
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }
    }
  },

  methods: {

    detectUiSectionCollision (rectangle1, rectangle2) {

      const windowPageXOffset = window.pageXOffset
      const windowPageYOffset = window.pageYOffset

      const r1MinX = windowPageXOffset + rectangle1.left
      const r1MaxX = windowPageXOffset + rectangle1.left + rectangle1.width
      const r1MinY = windowPageYOffset + rectangle1.top
      const r1MaxY = windowPageYOffset + rectangle1.top + rectangle1.height
    
      const r2MinX = rectangle2.left
      const r2MaxX = rectangle2.left + rectangle2.width
      const r2MinY = rectangle2.top
      const r2MaxY = rectangle2.top + rectangle2.height
    
      const collided =
        r1MaxX > r2MinX &&
        r1MaxY > r2MinY &&
        r2MaxX > r1MinX &&
        r2MaxY > r1MinY
    
      return collided
    },

    processStorageFileComponentCollision (component, uiSectionRect) {
      const blockDomRect = component.getPreviewBlockDomRect()

      const componentCollided =
        blockDomRect && this.detectUiSectionCollision(blockDomRect, uiSectionRect)

      if ( componentCollided ) {
        component.choseByUiSection()
      } else {
        if ( component.userChose && component.userChoseByUiSection ) {
          component.cancelChose()
        }
      }
    },

    thrDetectAllStorageFileCollision:_.throttle(function (uiSectionRect) {
      this.detectAllStorageFileCollision(uiSectionRect)
    }, 100),

    detectAllStorageFileCollision (uiSectionRect) {
      // 
      // 開發備註
      //
      //    1. 如果從畫面的一半開始選取，則從後面的檔案優先做偵測碰撞。
      //
      if ( _.isNil(this.$refs.storageFileItems) ) { return }

      const reverseSearch =
        document.documentElement.scrollHeight / 2 < uiSectionRect.top
      
      const length = this.$refs.storageFileItems.length

      for ( let step=0; step<length; step++ ) {
        const index = reverseSearch ? length - 1 - step : step

        this.processStorageFileComponentCollision(
          this.$refs.storageFileItems[index], uiSectionRect
        )
      }

    }

  }

}