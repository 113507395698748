<template>
  <div
    id="nas_app"
    ref="main"
    class="nas-app"
    style="display: none;"
    @dragover.prevent
    @dragstart="userDragStart"
    @dragend="userDragEnd"
    @drop="userDrop"
    @dblclick="userDblClick"
  >
    <storage-loading-bar :loading="loading"></storage-loading-bar>

    <ui-section
      :enabled="uiSection.enabled"
      :top="uiSection.top"
      :left="uiSection.left"
      :width="uiSection.width"
      :height="uiSection.height"
    >
    </ui-section>

    <notifications
      group="storage"
      classes="storage-notify"
      position="bottom right"
    >
      <template #body="props">
        <div class="storage-notify">
          <div class="oa">
            <div class="col icon">
              <i :class="props.item.data.icon"></i>
            </div>
            <div class="col">
              <div class="title">
                {{ props.item.title }}
              </div>
              <div class="content" v-html="props.item.text"></div>
            </div>
          </div>
        </div>
      </template>
    </notifications>

    <storage-navigator
      :current-folder-label="currentFolderLabel"
      @create-new-file="createNewFolder"
      @open-file-dialog="openBrowserFileDialog"
      @search="searchStorage"
      @close-dialog="closeAppDialog"
    ></storage-navigator>

    <div class="sub-navigator">
      <div class="left">
        <storage-path :current-folder-id="currentFolderId"></storage-path>
      </div>
      <div class="right">
        <storage-sort-status
          :column="sortStatus.column"
          :order="sortStatus.order"
        ></storage-sort-status>
        <!--<a
          v-tooltip.left="'(尚未實裝) 在畫面中間，可使用滑鼠右鍵可呼叫檔案選單'"
          class="file-edit-menu"
        >
          <span>操作</span>
          <i class="far fa-caret-square-down"></i>
        </a>-->
      </div>
    </div>

    <storage-status-bar
      :idea-editor-status="ideaEditorStatus"
      :current-usage="storageStatusCurrentUsage"
      :max-usage="storageStatusMaxUsage"
      :current-folders-count="currentFolders.length"
      :current-files-count="currentFiles.length"
      :chose-files="choseFiles.slice(0, 7)"
      :chose-files-count="choseFilesCount"
      :chose-files-total-size="choseFilesTotalSize"
      @add-file-to-new-article="addFileToNewArticle"
      @add-file-to-exist-article="addFileToExistArticle"
    ></storage-status-bar>

    <div ref="storageFolders" class="storage-folders">
      <storage-folder
        v-for="item in currentFolders"
        :id="item.id"
        :key="item.uuid"
        :uuid="item.uuid"
        :parent-id="item.parentId"
        :depth="item.depth"
        :status="item.status"
        :name="item.name"
        :total-files="item.totalFiles"
        :total-size="item.totalSize"
        :user-chose="item.userChose"
        :user-edit-object-name-mode="item.userEditObjectNameMode"
        :data-updating="item.dataUpdating"
        :system-default="item.systemDefault"
        :preview-images="item.previewImages"
        @rename-folder="renameFolder"
        @cancel-folder-renaming="cancelFolderRenaming"
        @refresh-folder-preview-images="refreshFolderPreviewImages"
      ></storage-folder>
    </div>

    <div ref="storageFiles" class="storage-files">
      <storage-file
        v-for="item in currentFiles"
        :id="item.id"
        :key="item.uuid"
        ref="storageFileItems"
        :uuid="item.uuid"
        :folder-id="item.folderId"
        :status="item.status"
        :name="item.name"
        :extension="item.extension"
        :size="item.size"
        :human-size="item.humanSize"
        :created-at="item.createdAt"
        :icon-css="item.iconCss"
        :preview-url="item.previewUrl"
        :storage-url="item.storageUrl"
        :storage-source-id="item.storageSourceId"
        :upload-progress="item.uploadProgress"
        :upload-tx-bytes="item.uploadTxBytes"
        :upload-total-bytes="item.uploadTotalBytes"
        :user-chose="item.userChose"
        :user-chose-by-ui-section="item.userChoseByUiSection"
        :user-cut-object="item.userCutObject"
        :user-edit-object-name-mode="item.userEditObjectNameMode"
        :data-updating="item.dataUpdating"
        :user-rating="item.userRating"
        :user-rating-api-updating="item.userRatingApiUpdating"
        :user-rating-api-timer="item.userRatingApiTimer"
        :bind-to-article="item.bindToArticle"
        @rating="ratingFile"
        @chose="choseFile"
        @rename-file="renameFile"
        @cancel-file-renaming="cancelFileRenaming"
        @cancel-chose="canelFileChose"
        @destroy="destroyObject"
        @terminate-upload="terminateUpload"
      ></storage-file>
      <upload-tips v-show="currentFiles[0] == null"></upload-tips>
    </div>

    <storage-context-menu
      ref="storageContextMenu"
      :idea-editor-status="ideaEditorStatus"
      :chose-files-count="choseFilesCount"
      :temp-zone-files-count="tempZoneFilesCount"
      :current-folder-id="currentFolderId"
      :paste-folder-id="contextMenu.pasteFolderId"
      :accept-upload-file="contextMenu.acceptUploadFile"
      :accept-create-folder="contextMenu.acceptCreateFolder"
      :accept-rename-single-object="contextMenu.acceptRenameSingleObject"
      :accept-rename-multi-files="contextMenu.acceptRenameMultiFiles"
      :accept-cut-object="contextMenu.acceptCutObject"
      :accept-paste-object="contextMenu.acceptPasteObject"
      :accept-delete-single-object="contextMenu.acceptDeleteSingleObject"
      :accept-delete-multi-object="contextMenu.acceptDeleteMultiObject"
      @go-to-upper-folder="goToUpperFolder"
      @open-browser-file-dialog="openBrowserFileDialog"
      @create-new-folder="createNewFolder"
      @rename-object="renameObject"
      @rename-multi-objects="renameMultiObjects"
      @cut-objects="cutObjects"
      @paste-objects="pasteObjects"
      @delete-object="destroyObjectByContextMenu"
      @delete-multi-object="destroyMultiObjectByContextMenu"
      @sort-folder-and-files="sortFolderAndFiles"
      @add-user-asset-to-idea-editor="addUserAssetToIdeaEditorFromContextMenu"
      @copy-chose-files-url="copyChoseFilesUrl"
    />

    <div class="hidden-file-input" style="opacity: 0; overflow: hidden; width: 1px; height: 1px;">
      <input
        ref="fileInput"
        type="file"
        multiple
        @change="uploadFile"
      />
    </div>
  </div>
</template>

<script>
import StorageFile from './components/StorageFile.vue'
import StorageFolder from './components/StorageFolder.vue'
import StorageLoadingBar from './components/StorageLoadingBar.vue'
import StorageNavigator from './components/StorageNavigator.vue'
import StoragePath from './components/StoragePath.vue'
import StorageSortStatus from './components/StorageSortStatus.vue'
import StorageStatusBar from './components/StorageStatusBar.vue'
import StorageContextMenu from './components/StorageContextMenu.vue'
import UploadTips from './components/UploadTips.vue'
import UiSection from './components/UiSection.vue'

import storageCoreProcessMixin from './core/storageCoreProcessMixin'
import storageContextMenuMixin from './core/storageContextMenuMixin'
import storageUserManagementProcessMixin from './core/storageUserManagementProcessMixin'
import storageNoticeMixin from './core/storageNoticeMixin'
import storageFileUiSectionProcessMixin from './core/storageFileUiSectionProcessMixin'

import UserBehaviorObserver from './core/UserBehaviorObserver'
import StorageOperator from './core/StorageOperator'
import ArticleAssetsOperator from '../shared/core/ArticleAssetsOperator'
// import SearchFileEngine from './core/SearchFileEngine'



export default {

  components: {
    StorageFile,
    StorageFolder,
    StorageLoadingBar,
    StorageNavigator,
    StoragePath,
    StorageSortStatus,
    StorageStatusBar,
    StorageContextMenu,
    UiSection,
    UploadTips
  },

  mixins: [
    storageCoreProcessMixin,
    storageContextMenuMixin,
    storageUserManagementProcessMixin,
    storageNoticeMixin,
    storageFileUiSectionProcessMixin
  ],

  data () {
    return {
      isOpened: false,
      icons: {
        dragFile1: null,
        dragFile2: null
      },
      fileNotFound: false,
      loading: false,
      storageStatusCurrentUsage: 0,
      storageStatusMaxUsage: 0
    }
  },

  watch: {
    isOpened (val) {
      if (val) {
        document.documentElement.classList.add('nas-opened')
      } else {
        document.documentElement.classList.remove('nas-opened')
      }
    }
  },

  beforeUnmount () {
    this.destroyContextMenu()

    this.userBehaviorObserver.disable()

    window.removeEventListener('popstate', this.userPopstate)
  },

  mounted () {

    // load article assets operator
    this.articleAssetsOperator = new ArticleAssetsOperator()

    // load storage operator
    this.storageOperator = new StorageOperator()

    // load core objects
    this.userBehaviorObserver =
      new UserBehaviorObserver({
        document: document,
        window: window,
        events: {
          moveObjectToFolder: (obj) => {
            this.moveObjectToSpecificFolder(obj)
          },
          selectAllFiles: () => {
            this.selectAllFiles()
          },
          deselectAllFiles: () => {
            this.deselectAllFiles()
          },
          cutChoseFiles: () => {
            this.cutObjects()
          },
          pasteChoseFiles: () => {
            this.pasteObjects({ pasteFolderId: this.currentFolderId })
          },
          userDropFiles: (event) => {
            for ( const file of event.dataTransfer.files ) {
              this.uploadAssetToStorage(file)
            }
          },
          userUiSectionBefore: (event) => {
            this.uiSection.enabled = true
          },
          userUiSectionProcessing: (event, uiSectionRect) => {
            this.uiSection.top = uiSectionRect.top
            this.uiSection.left = uiSectionRect.left
            this.uiSection.width = uiSectionRect.width
            this.uiSection.height = uiSectionRect.height

            this.thrDetectAllStorageFileCollision(uiSectionRect)
          },
          userUiSectionCancel: (event) => {
            this.uiSection.enabled = false
          }
        }
      })

    // this.searchFileEngine =
    //   new SearchFileEngine({ storageOperator: this.storageOperator })

    // setup context menu
    this.setupContextMenu()

    // load necessary files
    this.loadNecessaryFiles()

    // load current usage info
    this.refreshCurrentUsageInfo()

    // load current folder content
    window.addEventListener('popstate', this.userPopstate)

    // binding instance to window object
    window.nasApp = this
  },

  methods: {

    loadNecessaryFiles () {
      var img = new Image()
      img.src = '/images/icons/drag-file-1.png'
      this.icons.dragFile1 = img

      var img = new Image()
      img.src = '/images/icons/drag-file-2.png'
      this.icons.dragFile2 = img
    },

    userDragStart (e) {
      const storageObject = e.target.closest('div[class^=storage-file]')
      const showIcon = storageObject != null
      
      if ( showIcon ) {
        this.choseFile({ uuid: storageObject.dataset.uuid, by: 'userDrag' })

        const iconImage =
          this.choseFilesCount > 1 ? this.icons.dragFile2 : this.icons.dragFile1

        e.dataTransfer.setDragImage(iconImage, 0, 0)
      }

      this.userBehaviorObserver.dragStartEventProbe(e)
    },

    userDragEnd (e) {
      this.userBehaviorObserver.dragEndEventProbe(e)
    },

    userDrop (e) {
      this.userBehaviorObserver.dropEventProbe(e)
    },

    userPopstate (e) {
      this.changeFolderByUrlAnchor()
    },

    userDblClick (e) {
      const className = e.target.className || ''

      const cancel =
        /^(?:nas-app|storage-files|storage-folders|breadcrumb|path)$/i.test(className)
      
      if (!cancel) { return }

      this.cancelAllUserAssetChose()
    },

    toggle () {
      this.isOpened ? this.close() : this.open()
    },

    refreshUserBehaviorObserverStatus() {
      if (this.isOpened) {
        this.userBehaviorObserver.enable()
      } else {
        this.userBehaviorObserver.disable()
      }
    },

    open () {
      this.isOpened = true
      this.refreshUserBehaviorObserverStatus()
      this.enableAutoSyncIdeaEdtiorStatus()
      this.enableAutoSyncUserChoseObjects()
      this.enableAutoRefreshUsageInfo()

      this.changeFolderByUrlAnchor()
      this.openUiAnimation()
    },

    openUiAnimation () {
      const animation = () => {
        TweenMax.set(this.$refs.main, {
          opacity: 0, display: 'none'
        })

        TweenMax.to(this.$refs.main, 0.5, {
          opacity: 1, display: '', ease: Power1.easeOut
        })
      }

      const autoScroll = window.scrollY > 145

      if (autoScroll) {
        TweenMax.to(window, 0.3, {
          scrollTo: { y: 0, ease: Power2.easeInOut },
          onComplete: () => animation()
        })
      } else {
        animation()
      }
    },

    close () {
      this.isOpened = false
      this.refreshUserBehaviorObserverStatus()
      this.disableAutoSyncIdeaEdtiorStatus()
      this.disableAutoSyncUserChoseObjects()
      this.disableAutoRefreshUsageInfo()

      TweenMax.to(this.$refs.main, 0.5, {
        opacity: 0,
        display: 'none',
        ease: Power1.easeOut,
        onComplete: () => {
          this.resetStorageList()

          if (document.querySelector('body.portal.home')) {
            window.location.reload()
          }
        }
      })
    }

  }
}
</script>

<style lang="scss">
@import "./styles/main";
</style>