//
// MultiUploadControl ( 1.0.0.0 )
// 
import _ from 'lodash'
import UploadAsset from './UploadAsset'

class MultiUploadControl {

  constructor (options={}) {

    const rejectInit = 
      _.isEmpty(options.paramsName) ||
      _.isEmpty(options.uploadTo)

    if ( rejectInit ) {
      throw '[MultiUploadControl] create failed. ( paramsName or uploadTo not found )'
    }

    this.queue = []
    this.formParamsName  = options.paramsName
    this.formOtherParams = options.otherParams
    this.remoteServerUrl = options.uploadTo
    this.uploadLimit     = 3
    this.startWatching   = false

    this.event = {}

    this.event.added =
      _.isFunction(options.event.added) ? options.event.added : () => {}

    this.event.thrAdded =
      _.throttle(() => {
        this.event.added()
      }, 50)

    this.inDebugMode =
      /^127.0.0.1|localhost$/.test(window.location.hostname) ||
      window.location.href.indexOf('debug=1') > -1

  }

  startWatchingQueue () {
    if ( this.inDebugMode ) {
      console.log(['MultiUploadControl', 'startWatchingQueue'])
    }

    this.startWatching = true

    setInterval(() => {
      this.watchingQueueData()
    }, 1200)
  }

  currentFiles () {
    return this.queue.length
  }

  watchingQueueData () {
    if ( this.inDebugMode ) {
      console.log(['MultiUploadControl', 'watchingQueueData', this.queue.length])
    }

    let inProcessingCount = 0

    for ( const uploadFile of this.queue ) {
      if ( uploadFile.inProcessing() ) {
        inProcessingCount += 1
      }
    }

    const startProcessNext = 
      inProcessingCount < this.uploadLimit

    if ( startProcessNext ) {
      for ( const uploadFile of this.queue ) {
        if ( uploadFile.inQueued() ) {
          uploadFile.start()
          inProcessingCount += 1
        }

        if ( inProcessingCount >= this.uploadLimit ) {
          break
        }
      }
    }
  }

  add (file, options={}) {
    if ( !this.startWatching ) {
      this.startWatchingQueue()
    }

    const uploadAssetOtherParams =
      _.isArray(options.params) ?
        this.formOtherParams.concat(options.params) : this.formOtherParams

    const uploadFile =
      new UploadAsset({
        file: file,
        paramsName: this.formParamsName,
        uploadTo: this.remoteServerUrl,
        otherParams: uploadAssetOtherParams,
        event: options.event
      })

    if ( uploadFile.beforeStart() ) {
      this.queue.push(uploadFile)
      this.event.thrAdded()
    }

    return uploadFile
  }

}

export default MultiUploadControl