// 
// GtSwitchPreviewImage ( 0.5.0.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'

class GtSwitchPreviewImage extends GtUiComponent {
  name() {
    return 'GtSwitchPreviewImage'
  }

  fetchUi() {
    this.ui.switchPreviewImages = $('.switch-preview-image')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.switchPreviewImages.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.switchPreviewImages.each((_, self) => {
      this.asyncInit(self)
    })
  }

  asyncInit(element) {
    const uiElement = $(element)
    if (uiElement.length < 1) { return }

    this.initSwitchPreviewImage(uiElement)
  }

  initSwitchPreviewImage(uiSwitchPreviewImage) {
    const uiPreviewWindow = uiSwitchPreviewImage.find('.preview-window')
    const uiPreviewImages = uiSwitchPreviewImage.find('.preview-image')

    uiPreviewImages.on('mouseover', function(event) {
      event.preventDefault()
      uiPreviewWindow.attr('src', $(this).data('url'))
    })
  }
}

export default GtSwitchPreviewImage