// 
// IdeaArticleModifyHistory ( 0.9.0.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'


class IdeaArticleModifyHistory extends GtUiComponent {
  name() {
    return 'IdeaArticleModifyHistory'
  }

  fetchUi() {
    this.status.showFirstLog = false

    this.ui.main = $('body.ideas.show')
    this.ui.modifyHistoryLogs = $('.modify-history .log')
  }

  checkUi() {
    this.fetchUi()

    const result = (this.ui.main.length === 1) &&
             (this.ui.modifyHistoryLogs.length > 0)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.modifyHistoryLogs.each((index, eleLog) => {
      this.initLogUi($(eleLog))
    })
  }
  
  initLogUi(uiLog) {
    const uiMoreBtn = uiLog.find('a.more')
    const uiDiff = uiLog.find('.diff')

    TweenMax.set(uiDiff, { opacity: 0 })

    if (uiLog.hasClass('has-been-modified') && !this.status.showFirstLog) { 
      this.status.showFirstLog = true
      uiDiff.show()
      TweenMax.to(uiDiff, 1, { opacity: 1, ease: Power1.easeOut })
      uiMoreBtn.remove()
    }

    uiMoreBtn.on('click', e => {
      e.preventDefault()
      uiDiff.show()
      TweenMax.to(uiDiff, 1, { opacity: 1, ease: Power1.easeOut })
      uiMoreBtn.remove()
    })
  }
}

export default IdeaArticleModifyHistory