// 
// TopNavigator ( 0.1.0.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'

class TopNavigator extends GtUiComponent {
  name() {
    return 'TopNavigator'
  }

  fetchUi() {
    this.ui.navigator = $('.top-navigator')
    this.ui.nasTogglerBtn = this.ui.navigator.find('.btn.nas-toggler')
  }

  checkUi() {
    this.fetchUi()

    const result =
      (this.ui.navigator.length > 0) &&
      (this.ui.nasTogglerBtn.length > 0)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.nasTogglerBtn.click(e => {
      e.preventDefault()
      window.nasApp.toggle()
    })
  }
}

export default TopNavigator