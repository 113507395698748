import _ from 'lodash'

// 
// UserBehaviorObserver( 0.8.0.0 )
// 
class UserBehaviorObserver {

  constructor({
    document,
    window,
    events: {
      moveObjectToFolder=()=>{},
      selectAllFiles=()=>{},
      deselectAllFiles=()=>{},
      cutChoseFiles=()=>{},
      pasteChoseFiles=()=>{},
      userDropFiles=()=>{},
      userUiSectionBefore=()=>{},
      userUiSectionProcessing=()=>{},
      userUiSectionCancel=()=>{}
    }
  }) {

    this.document = document

    this.window = window

    this.status = {
      enabled: false
    }

    this.events = {
      moveObjectToFolder,
      selectAllFiles,
      deselectAllFiles,
      cutChoseFiles,
      pasteChoseFiles,
      userDropFiles,
      userUiSectionBefore,
      userUiSectionProcessing,
      userUiSectionCancel
    }

    this.userStatus = {
      dragging: false,
      uiSection: {
        enabled: false,
        latestPageX: 0,
        latestPageY: 0,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        selectProcessing: false 
      }
    }

    this.watchElements = {
      forDrop: null,
      forDragEnter: null,
      forDragLeave: null,
      forDragStart: null,
      forDragEnd: null
    }

    this.autoBindingEvents()
  }

  autoBindingEvents() {
    this._userMouseDownEventProbe = this.userMouseDownEventProbe.bind(this)
    this._userMouseUpEventProbe = this.userMouseUpEventProbe.bind(this)
    this._userMouseMoveEventProbe = this.userMouseMoveEventProbe.bind(this)
    this._userKeyDownEventProbe = this.userKeyDownEventProbe.bind(this)
  }

  resetDragEventWatchData() {
    this.forDrop = null
    this.forDragEnter = null
    this.forDragLeave = null
    this.forDragStart = null
    this.forDragEnd = null
  }

  dragStartEventProbe(event) {
    if (!this.status.enabled) { return }

    this.disableUiSection()
    this.resetDragEventWatchData()
    this.watchElements.forDragStart = event.target
  }

  dropEventProbe(event) {
    if (!this.status.enabled) { return }

    if ( event.dataTransfer.files.length ) {
      event.preventDefault()
      this.watchElements.forDrop = null
      this.events.userDropFiles(event)
    } else {
      this.watchElements.forDrop = event.target 
    }
  }

  dragEndEventProbe(event) {
    if (!this.status.enabled) { return }

    this.watchElements.forDragEnd = event.target
    
    const hasDragEventData = 
      !_.isNull(this.watchElements.forDragStart) &&
      !_.isNull(this.watchElements.forDrop) &&
      !_.isNull(this.watchElements.forDragEnd) 
    
    const userDragSomeElement = 
      hasDragEventData && this.watchElements.forDragEnd == this.watchElements.forDragStart

    const destinationFolderElement =
      this.watchElements.forDrop != null &&(
        this.watchElements.forDrop.closest('.storage-folder,.storage-path-folder')
      )

    const callMoveObjectFunc = 
      userDragSomeElement && !_.isNull(destinationFolderElement)

    if ( callMoveObjectFunc ) {
      this.events.moveObjectToFolder(destinationFolderElement)
    }

    this.userStatus.dragging = false
  }

  userMouseDownEventProbe(event) {
    const ignore =
      this.userStatus.dragging

    if ( ignore ) { return }

    // this.log('userMouseDownEventProbe')

    this.userStatus.uiSection.enabled = true
    this.userStatus.uiSection.latestPageX = event.pageX
    this.userStatus.uiSection.latestPageY = event.pageY
  }

  disableUiSection() {
    this.userStatus.uiSection.enabled = false
    this.userStatus.uiSection.selectProcessing = false
  }

  userMouseUpEventProbe(event) {
    // this.log('userMouseUpEventProbe')

    this.disableUiSection()

    this.events.userUiSectionCancel(event)
  }

  userMouseMoveEventProbe(event) {
    const ignore =
      this.userStatus.dragging || !this.userStatus.uiSection.enabled

    if ( ignore ) { return }


    if ( !this.userStatus.uiSection.selectProcessing ) {
      const startProcess =
        Math.abs(event.pageX - this.userStatus.uiSection.latestPageX) > 9 &&
        Math.abs(event.pageY - this.userStatus.uiSection.latestPageY) > 9 

      if ( startProcess ) {
        // this.log('userMouseMoveEventProbe:userUiSectionBefore')

        this.userStatus.uiSection.top = this.userStatus.uiSection.latestPageY
        this.userStatus.uiSection.left = this.userStatus.uiSection.latestPageX

        this.events.userUiSectionBefore(event)
        this.userStatus.uiSection.selectProcessing = true
      }
    }

    if (this.userStatus.uiSection.selectProcessing) {
      this.userStatus.uiSection.width =
        Math.abs(event.pageX - this.userStatus.uiSection.latestPageX)

      this.userStatus.uiSection.height =
        Math.abs(event.pageY - this.userStatus.uiSection.latestPageY)

      this.userStatus.uiSection.top =
      ( event.pageY - this.userStatus.uiSection.latestPageY >= 0 ) ?
        this.userStatus.uiSection.latestPageY :
        this.userStatus.uiSection.latestPageY - this.userStatus.uiSection.height

      this.userStatus.uiSection.left =
      ( event.pageX - this.userStatus.uiSection.latestPageX >= 0 ) ?
        this.userStatus.uiSection.latestPageX :
        this.userStatus.uiSection.latestPageX - this.userStatus.uiSection.width

      const rect = {
        top: this.userStatus.uiSection.top,
        left: this.userStatus.uiSection.left,
        width: this.userStatus.uiSection.width,
        height: this.userStatus.uiSection.height
      }

      this.events.userUiSectionProcessing(event, rect)
    }
  }

  userKeyDownEventProbe(event) {
    if (!this.status.enabled) { return }

    const useControlKey = event.ctrlKey || event.metaKey
    if (!useControlKey) { return }

    // 優化: 如果遇到 INPUT Tag 則忽略事件觸發不處理。
    if ( /INPUT|TEXTAREA/.test(event.target.tagName) ) { return }

    if ( event.altKey && event.keyCode == 65 ) {
      event.preventDefault()
      this.events.deselectAllFiles()
    } else if ( !event.altKey && event.keyCode == 65 ) {
      event.preventDefault()
      this.events.selectAllFiles()
    } else if ( event.keyCode == 88 ) {
      event.preventDefault()
      this.events.cutChoseFiles()
    } else if ( event.keyCode == 86 ) {
      event.preventDefault()
      this.events.pasteChoseFiles()
    } else {
      // ignore
    }
  }

  disable() {
    this.window.removeEventListener('mousedown', this._userMouseDownEventProbe)
    this.window.removeEventListener('mouseup', this._userMouseUpEventProbe)
    this.window.removeEventListener('mousemove', this._userMouseMoveEventProbe)
    this.document.body.removeEventListener('keydown', this._userKeyDownEventProbe)

    this.status.enabled = false
  }

  enable() {
    this.window.addEventListener('mousedown', this._userMouseDownEventProbe)
    this.window.addEventListener('mouseup', this._userMouseUpEventProbe)
    this.window.addEventListener('mousemove', this._userMouseMoveEventProbe)
    this.document.body.addEventListener('keydown', this._userKeyDownEventProbe)

    this.status.enabled = true
  }

  log(tag) {
    console.log(+new Date, `ubo:${tag}`)
  }

}

export default UserBehaviorObserver