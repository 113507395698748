<template>
  <div class="ui-section" :style="styleObject"></div>
</template>

<script>
// 
// UiSection ( 0.1.0.0 )
// 
export default {
  name: 'UiSection',

  props: {
    enabled: { type: Boolean, default: false },
    top:     { type: Number, default: 0 },
    left:    { type: Number, default: 0 },
    width:   { type: Number, default: 0 },
    height:  { type: Number, default: 0 }
  },

  data () {
    return {
    }
  },

  computed: {
    styleObject () {
      return {
        'display': ( this.enabled ? '' : 'none' ), 
        'top': `${this.top}px`,
        'left': `${this.left}px`,
        'width': `${this.width}px`,
        'height': `${this.height}px`
      }
    }
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.ui-section {
  z-index: 9999;
  display: block;
  position: absolute;

  top: 0;
  left: 0;
  
  width: 0;
  height: 0;

  outline: 1px solid fade-out(#5469d4, 0.7);
  background-color: fade-out(#5469d4, 0.8);
}
</style>