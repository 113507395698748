<template>
  <div
    ref="main"
    class="context-menu"
    :style="{ display: 'none' }"
  >
    <transition name="fade">
      <div
        v-if="showSubMenu"
        class="sub-menu"
      >
        <a
          href="#"
          @click.prevent="sort('name', 'asc')"
        ><i class="fas fa-signature"></i> 依名稱 <span>A <i class="fas fa-caret-right"></i> Z</span></a>
        <a
          href="#"
          @click.prevent="sort('name', 'desc')"
        ><i class="fas fa-signature"></i> 依名稱 <span>Z <i class="fas fa-caret-right"></i> A</span></a>
        <a
          href="#"
          @click.prevent="sort('id', 'asc')"
        ><i class="far fa-calendar-alt"></i> 依日期 <span>1/1 <i class="fas fa-caret-right"></i> 1/31</span></a>
        <a
          href="#"
          @click.prevent="sort('id', 'desc')"
        ><i class="far fa-calendar-alt"></i> 依日期 <span>1/31 <i class="fas fa-caret-right"></i> 1/1</span></a>
        <a
          href="#"
          @click.prevent="sort('size', 'asc')"
        ><i class="fas fa-hdd"></i> 依大小 <span>1 <i class="fas fa-caret-right"></i> 9 MB</span></a>
        <a
          href="#"
          @click.prevent="sort('size', 'desc')"
        ><i class="fas fa-hdd"></i> 依大小 <span>9 <i class="fas fa-caret-right"></i> 1 MB</span></a>
        <a
          href="#"
          @click.prevent="sort('extension', 'asc')"
        ><i class="far fa-file-pdf"></i> 依種類 <span>A <i class="fas fa-caret-right"></i> Z</span></a>
        <a
          href="#"
          @click.prevent="sort('extension', 'desc')"
        ><i class="far fa-file-pdf"></i> 依種類 <span>Z <i class="fas fa-caret-right"></i> A</span></a>
      </div>
    </transition>
    <template v-if="currentFolderId">
      <a
        href="#"
        @click.prevent="$emit('go-to-upper-folder')"
      ><i class="fas fa-level-up-alt"></i> 回上層</a>
    </template>
    <template v-else>
      <a
        href="#"
        class="disabled"
        @click.prevent=""
      ><i class="fas fa-hdd"></i> 操作選單</a>
    </template>
    <div class="line"></div>
    <a
      v-show="ideaEditorStatus.enabled && acceptDeleteSingleObject"
      href="#"
      @click.prevent="$emit('add-user-asset-to-idea-editor', { dataSource: 'contextMenu' })"
    ><i class="fas fa-share"></i> 加入這個到文案</a>
    <a
      v-show="ideaEditorStatus.enabled && acceptDeleteMultiObject"
      href="#"
      @click.prevent="$emit('add-user-asset-to-idea-editor', { dataSource: 'userChose' })"
    ><i class="fas fa-share-square"></i> 加入所選 {{ choseFilesCount }} 個檔案到文案</a>
    <div class="line"></div>
    <a
      v-show="acceptUploadFile"
      href="#"
      @click.prevent="$emit('open-browser-file-dialog')"
    ><i class="fas fa-upload"></i> 上傳檔案</a>
    <a
      v-show="acceptCreateFolder"
      href="#"
      @click.prevent="$emit('create-new-folder')"
    ><i class="far fa-folder-open"></i> 新增資料夾</a>
    <div class="line"></div>
    <a
      v-show="acceptRenameSingleObject"
      href="#"
      @click.prevent="$emit('rename-object')"
    ><i class="fas fa-eraser"></i> 重新命名</a>
    <a
      v-show="acceptRenameMultiFiles"
      href="#"
      @click.prevent="$emit('rename-multi-objects')"
    ><i class="fas fa-eraser"></i> 重新命名所選 {{ choseFilesCount }} 個檔案</a>
    <div class="line"></div>
    <a
      href="#"
      class="has-submenu"
      @click.prevent="showSubMenu=true"
    ><i class="fas fa-sort-amount-down"></i> 排列</a>
    <a
      v-show="choseFilesCount > 0"
      href="#"
      @click.prevent="$emit('copy-chose-files-url')"
    ><i class="fas fa-link"></i> 複製 {{ choseFilesCount }} 個 連結</a>
    <a
      href="#"
      :class="{ 'disabled': !acceptCutObject }"
      @click.prevent="$emit('cut-objects')"
    ><i class="fas fa-cut"></i> 剪下</a>
    <a
      href="#"
      :class="{ 'disabled': !acceptPasteObject }"
      @click.prevent="$emit('paste-objects', { pasteFolderId })"
    ><i class="fas fa-paste"></i> 貼上 {{ tempZoneFilesCount }} 個檔案</a>
    <div class="line"></div>
    <a
      v-show="acceptDeleteSingleObject"
      href="#"
      @click.prevent="$emit('delete-object')"
    ><i class="far fa-trash-alt"></i> 移除這個</a>
    <a
      v-show="acceptDeleteMultiObject"
      href="#"
      @click.prevent="$emit('delete-multi-object')"
    ><i class="far fa-trash-alt"></i> 移除所選 {{ choseFilesCount }} 個檔案</a>
  </div>
</template>

<script>
// 
// StorageContextMenu ( 0.9.8.0.alpha )
// 
export default {
  name: 'StorageContextMenu',
  
  props: {
    ideaEditorStatus:          { type: Object, default: null },
    choseFilesCount:           { type: Number,  default: 0 },
    tempZoneFilesCount:        { type: Number,  default: 0 },
    currentFolderId:           { type: [String, Number], default: '' },
    pasteFolderId:             { type: [String, Number], default: null },
    acceptUploadFile:          { type: Boolean, default: false },
    acceptCreateFolder:        { type: Boolean, default: false },
    acceptRenameSingleObject:  { type: Boolean, default: false },
    acceptRenameMultiFiles:    { type: Boolean, default: false },
    acceptCutObject:           { type: Boolean, default: false },
    acceptPasteObject:         { type: Boolean, default: false },
    acceptDeleteSingleObject:  { type: Boolean, default: false },
    acceptDeleteMultiObject:   { type: Boolean, default: false },
  },

  emits: [
    'go-to-upper-folder',
    'open-browser-file-dialog',
    'create-new-folder',
    'rename-object',
    'rename-multi-objects',
    'cut-objects',
    'paste-objects',
    'delete-object',
    'delete-multi-object',
    'sort-folder-and-files',
    'add-user-asset-to-idea-editor',
    'copy-chose-files-url'
  ],
  
  data () {
    return {
      showSubMenu: false
    }
  },

  mounted () {
    this.showSubMenu = false
  },

  methods: {

    show ({ top, left, onComplete }) {
      TweenMax.set(this.$refs.main, {
        opacity: 0,
        display: 'none',
        top,
        left
      })

      TweenMax.to(this.$refs.main, .15, {
        opacity: 1,
        display: '',
        ease: Power1.easeOut,
        onComplete
      })
    },

    disable ({ before }) {
      before()
      TweenMax.set(this.$refs.main, { opacity: 0, display: 'none' })
    },

    hide ({ onComplete }) {
      TweenMax.to(this.$refs.main, .3, {
        opacity: 0,
        display: 'none',
        ease: Power1.easeOut,
        onComplete: onComplete
      })

      this.showSubMenu = false
    },

    updatePosition ({ top, left }) {
      TweenMax.set(this.$refs.main, { top, left })
    },

    sort (columnName, orderBy) {
      this.$emit('sort-folder-and-files', {
        column: columnName,
        order: orderBy,
        remember: true,
        notify: true
      })
    }

  }

}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

$content-menu-main-color: darken(#3b477b, 10%);

.context-menu {
  z-index: 9001;

  position: absolute;

  // overflow: hidden;
  background-color: white;
  border-top: 3px solid lighten(#3b477b, 10%);
  box-shadow: 0 0 5px fade-out(black, 0.935);

  > .sub-menu {
    z-index: 9002;
    position: absolute;

    top: 0;
    left: 100%;

    width: 13em;

    border-left: 2px solid $content-menu-main-color;
    box-shadow: 0 0 5px fade-out(black, 0.935);

    span {
      opacity: 0.5;
      margin-left: 0.5em;
    }

    span i {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  > a,
  > .sub-menu > a {
    display: block;
    text-decoration: none;

    transition: color .3s ease,
                background-color .3s ease;

    color: $content-menu-main-color;
    background-color: white;
    font-size: 14px;
    padding: 0.5em 0.8em;
    text-align: left;

    &:hover {
      color: white;
      background-color: $content-menu-main-color;
    }

    &.disabled {
      cursor: not-allowed;
      color: lighten(gray, 40%) !important;

      &:hover {
        background-color: white !important;
      }
    }

    i {
      width: 1.25em;
      text-align: center;

      margin-right: 0.5em;

      &.fa-trash-alt { color: #f55a4e; }
    }
  }

  > .line {
    width: 100%;
    height: 1px;

    background-color: #F0F0F0;
  }
}
</style>