import FolderObject2 from './FolderObject2'
import FileObject2 from './FileObject2'
import { setLocalStorageData, getLocalStorageData } from '../../shared/core/util/common'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

// 
// storageCoreProcessMixin ( 0.7.8.0 )
//
export default {

  data: function () {
    return {
      ideaEditorStatus: {
        enabled: false,
        articleEditPath: '',
        articleTitle: '',
        userAssetIds: []
      },
      searchKeywords: '',
      userBehaviorObserver: null,
      storageOperator: null,
      sortStatus: {
        column: '', order: ''
      },
      choseFolders: [],
      choseFoldersCount: 0,
      tempZoneFiles: [],
      tempZoneFilesCount: 0,
      tempZoneFilesOldFolderId: '',
      choseFiles: [],
      choseFilesCount: 0,
      choseFilesTotalSize: 0,
      currentFolderLabel: '',
      currentFolderId: '',
      currentFolderParentId: '',
      currentFolders: [],
      currentFoldersCache: [],
      currentFiles: [],
      currentFilesCache: {},
      uploadAssetsCache: {},
      pageState: {},
      autoRefreshUsageInfoTimer: null,
      autoSyncUserChoseObjectsTimer: null,
      autoSyncIdeaEdtiorStatusTimer: null
    }
  },

  methods: {

    resetSortStatus () {
      this.sortStatus.column = ''
      this.sortStatus.order = ''
    },

    autoSortFolderAndFiles ({ sortRange='all' }) {
      const key = `nas:folder:sort:${this.currentFolderId}`
      const object = getLocalStorageData({ key })

      if (object) {
        const [ column, order ] = object
        this.sortFolderAndFiles({ column, order, sortRange })
      }
    },

    sortFolderAndFiles ({
      column,
      order,
      remember=false,
      sortRange='all',
      notify=false
    }) {
      // 1. sort folder and files
      const needsToSortFolders =
        /^(?:all|folder)$/i.test(sortRange) &&
        /^(?:name|id|createdAt)$/i.test(column)

      if (needsToSortFolders) {
        this.currentFolders = _.orderBy(this.currentFolders, column, order)
      }

      const needsToSortFiles =
        /^(?:all|files)$/i.test(sortRange) &&
        /^(?:name|id|createdAt|size|extension)$/i.test(column)

      if (needsToSortFiles) {
        this.currentFiles = _.orderBy(this.currentFiles, column, order)
      }

      // 2. update sort status
      if (needsToSortFolders || needsToSortFiles) {
        this.sortStatus.column = column
        this.sortStatus.order = order
      } else {
        this.resetSortStatus()
      }

      // 3. remember last setting 
      if (remember) {
        const key = `nas:folder:sort:${this.currentFolderId}`
        setLocalStorageData({ key, value: [ column, order ] })
      }

      // 4. notify
      if (notify) {
        if (needsToSortFolders || needsToSortFiles) {
          if (this.sortStatus.order==='asc') {
            this.notify(`已更新排序。`, '檔案/資料夾', 'fas fa-sort-amount-up')
          } else {
            this.notify(`已更新排序。`, '檔案/資料夾', 'fas fa-sort-amount-down')
          }
        }
      }
    },

    setFolderPushState (options={}) {
      const hashtag =
        options.folderId ? `#nas:folder:${options.folderId}` : "#nas:root"

      try {
        window.history.pushState(this.pageState, null, hashtag)
      } catch (err) {
        // ignore
      }
    },

    syncUserChoseObjects () {
      // sync chose folders ( optional )
      this.choseFolders = []
      this.choseFoldersCount = 0

      // sync chose files
      const newChoseFiles = []
      let newChoseFilesTotalSize = 0
    
      for ( const fileData of this.currentFiles ) {
        if (fileData.userChose) {
          newChoseFiles.push(fileData)
          newChoseFilesTotalSize += fileData.size
        }
      }
      
      this.choseFiles = newChoseFiles
      this.choseFilesCount = newChoseFiles.length
      this.choseFilesTotalSize = newChoseFilesTotalSize
    },


    terminateAllUploadAssets () {
      _.forEach(this.uploadAssetsCache, (ua, key) => {
        if ( _.isNull(ua) ) { return }

        if (ua.inProcessing() || ua.inQueued()) {
          ua.abort(true)
        }
      })
    },

    resetTempZoneFiles () {
      this.tempZoneFiles = []
      this.tempZoneFilesCount = 0
      this.tempZoneFilesOldFolderId = ''
    },

    removeFolderData ({ folderData }) {
      if (_.isEmpty(folderData)) { return }

      const newFolder = this.currentFolders.slice()
      const index = newFolder.indexOf(folderData)

      if ( index > -1 ) {
        newFolder.splice(index, 1)
      }

      this.currentFolders = newFolder
      this.currentFoldersCache[`_${folderData.uuid}`] = null
    },

    removeCurrentFilesFileData ({ fileVar, multi }) {
      if ( _.isEmpty(fileVar) ) { return }

      if (multi) {

        const newFiles = this.currentFiles.slice()

        for ( const fileData of fileVar) {
          this.currentFilesCache[`_${fileData.uuid}`] = null
          this.uploadAssetsCache[`_${fileData.uuid}`] = null

          const index = newFiles.indexOf(fileData)

          if ( index > -1 ) {
            newFiles.splice(index, 1)
          }
        }

        this.currentFiles = newFiles

      } else {
        const index = this.currentFiles.indexOf(fileVar)

        if ( index > -1 ) {
          this.currentFiles.splice(index, 1)
        }
  
        this.currentFilesCache[`_${fileVar.uuid}`] = null
        this.uploadAssetsCache[`_${fileVar.uuid}`] = null
      }
    },

    setFolderToCurrentFiles (folderJson, status) {
      const folderData = new FolderObject2({
        uuid: uuidv4(),
        status: status,
        id: folderJson.id,
        name: folderJson.name,
        tree: folderJson.tree,
        depth: folderJson.depth,
        parent: folderJson.parent
      })

      this.currentFoldersCache[`_${folderData.uuid}`] = folderData
      this.currentFolders.unshift(folderData)
    },

    setFileDataToCurrentFiles (ua) {
      const fileData = new FileObject2({
        uuid: ua.id,
        status: 'new',
        fileExtName: ua.fileExtName,
        fileIconCss: ua.fileIconCss,
        fileName: ua.fileName,
        fileSize: ua.fileSize,
        uploadProgress: ua.progressPercent,
        uploadTxBytes: ua.progressLoaded,
        uploadTotalBytes: ua.progressTotal
      })

      this.currentFilesCache[`_${ua.id}`] = fileData
      this.uploadAssetsCache[`_${ua.id}`] = ua
      this.currentFiles.unshift(fileData)

      this.fileNotFound = false
    },

    findFolderDataByUuid (uuid) {
      return this.currentFoldersCache[`_${uuid}`]
    },

    findFileDataByUploadAssetUuid (uuid) {
      return this.currentFilesCache[`_${uuid}`]
    },

    findUploadAssetByUuid (uuid) {
      return this.uploadAssetsCache[`_${uuid}`]
    },

    cancelAllUserAssetChose () {
      for ( const f of this.currentFiles ) {
        f.userChose = false
      }
    },

    resetStorageList () {
      this.terminateAllUploadAssets()

      this.currentFolders = []
      this.currentFiles = []
      this.currentFilesCache = {}
      this.currentFoldersCache = {}
      this.uploadAssetsCache = {}
      this.choseFolders = []
      this.choseFoldersCount = 0
      this.choseFiles = []
      this.choseFilesCount = 0
    },

    reloadCurrentPage () {
      window.location.reload()
    },

    refreshCurrentFolderPreviewImages () {
      for ( const folderData of this.currentFolders ) {
        this.refreshFolderPreviewImages({
          uuid: folderData.uuid, useCache: false
        })
      }
    },

    refreshFolderPreviewImages ({ uuid, useCache }) {
      const folderData = this.findFolderDataByUuid(uuid)
      if ( !_.isObject(folderData) ) { return }

      this.storageOperator.folderPreviewImages({
        useCache: useCache,
        folderId: folderData.id,
        events: {
          ok: (res) => {
            if ( res.data.result == 'success' ) {
              folderData.previewImages = res.data.data.preview_images.slice(0, 16)
            }
          }
        }
      })
    },

    getTempZoneFileFolderIds () {
      return _.uniq(_.map(this.tempZoneFiles, 'folderId'))
    },


    refreshCurrentUsageInfo () {
      this.storageOperator.currentUsageInfo({
        events: {
          ok: (res) => {
            if ( res.data.result == 'success' ) {
              this.storageStatusCurrentUsage = res.data.data.currentUsage
              this.storageStatusMaxUsage = res.data.data.maxUsage
            }
          },
          failed: (res, err) => {
            // ignore
          }
        }
      })
    },

    enableAutoRefreshUsageInfo() {
      this.disableAutoRefreshUsageInfo()

      this.autoRefreshUsageInfoTimer = 
        setInterval(() => {
          if (this.isOpened) { this.refreshCurrentUsageInfo() }
        }, 5000)  
    },

    disableAutoRefreshUsageInfo() {
      clearInterval(this.autoRefreshUsageInfoTimer)
    },

    enableAutoSyncUserChoseObjects() {
      this.disableAutoSyncUserChoseObjects()

      this.autoSyncUserChoseObjectsTimer = 
        setInterval(() => {
          if (this.isOpened) { this.syncUserChoseObjects() }
        }, 300)
    },

    disableAutoSyncUserChoseObjects() {
      clearInterval(this.autoSyncUserChoseObjectsTimer)
    },

    refreshIdeaEdtiorStatus () {
      this.ideaEditorStatus.enabled =
        _.isObject(window.IdeaEditorUploadFiles) &&
        _.isObject(window.IdeaEditorEditMode) &&
        window.IdeaEditorUploadFiles.enabled()

      const { articleEditPath, articleTitle, userAssetIds } =
        window.IdeaEditorEditMode
        ?
          window.IdeaEditorEditMode.getCurrentEditorStatus()
        :
          { articleEditPath: '',
            articleTitle: '',
            userAssetIds: [] }

      this.ideaEditorStatus.articleEditPath = articleEditPath
      this.ideaEditorStatus.articleTitle = articleTitle.length > 0 ? articleTitle : '新文案'
      this.ideaEditorStatus.userAssetIds = userAssetIds
    },

    enableAutoSyncIdeaEdtiorStatus () {
      this.disableAutoSyncIdeaEdtiorStatus()

      this.refreshIdeaEdtiorStatus()
      
      this.autoSyncIdeaEdtiorStatusTimer = 
        setInterval(() => {
          this.refreshIdeaEdtiorStatus()
        }, 2000)
    },

    disableAutoSyncIdeaEdtiorStatus () {
      clearInterval(this.autoSyncIdeaEdtiorStatusTimer)
    },

    refreshArticleAssetStatus () {
      const editorAssetIds = this.ideaEditorStatus.userAssetIds.slice()

      for (const fileData of this.currentFiles) {
        fileData.bindToArticle = editorAssetIds.indexOf(`${fileData.id}`) > -1
      }
    }

  }

}