// 
// MultiFilters ( 0.8.3.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import axios from 'axios'

class MultiFilters extends GtUiComponent {
  name() {
    return 'MultiFilters'
  }

  fetchUi() {
    return this.ui.multiFilters = $('.multi-filter')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.multiFilters.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.multiFilters.each((index, self) => {
      const uiFilter = $(self)

      if (uiFilter.hasClass('create-form')) {
        this.initCreateFormMultiFilter(uiFilter)
      }
    })
  }

  createShakeAnimation(uiElement) {
    const tl = new TimelineMax({paused: true})
    tl.set(uiElement, {y: 10})
    tl.to(uiElement, 1.5,  { y: 0, ease: Elastic.easeOut.config(1, 0.3) }, 0)
    uiElement.data('animation', tl)
  }

  createFormSubmit(uiFilter) {
    const uiInput = uiFilter.data('uiInput')
    const uiConfirmBtn = uiFilter.data('uiConfirmBtn')
    const uiOptions = uiFilter.data('uiOptions')

    const valueString = uiInput.val().replace(/\s+/g, ' ')
    uiInput.val('').focus()

    uiConfirmBtn.data('animation').play(0)

    axios.post(uiFilter.data('createFormPath'), {
      value: valueString,
      'authenticity_token': $('meta[name="csrf-token"]').attr('content')
    })
    .then(rs => {
      const label = rs.data.data.value
      let buttonQs = uiFilter.data('newButtonQuerystring') || ''
      buttonQs = buttonQs.replace('_VALUEID_', rs.data.data.id)

      const uiBtn = $(`<a href=\"?${buttonQs}\">${label}</a>`)
      TweenMax.set(uiBtn, { opacity: 0, y: -10, scale: 1.2 })
      uiOptions.append(uiBtn)
      TweenMax.to(uiBtn, 0.5, {
        y: 0, opacity: 1, scale: 1.0, ease: Power1.easeOut, delay: 0.1
      })

      window.PageHelper.notify(window.ui18.msg.createDataSuccess(label), window.ui18.label.systemNotice, 'fas fa-check')
    })
    .catch(err => {
      if (err.response && err.response.status === 403) {
        window.PageHelper.notify(window.ui18.msg.createDataAlreadyExists(valueString), window.ui18.label.processFailed, 'fas fa-times')
      } else {
        window.PageHelper.notify(window.ui18.msg.processFailedPleaseTryAgainLater(valueString), window.ui18.label.processFailed, 'fas fa-times')

        setTimeout(_ => window.location.reload(), 1000)
      }
    })
  }

  initCreateFormMultiFilter(uiFilter) {
    const uiCreateBtn = uiFilter.find('a.create')
    const uiInput = uiFilter.find('input')
    const uiConfirmBtn = uiFilter.find('a.confirm')
    const uiOptions = uiFilter.find('.options')

    if ((uiCreateBtn.length === 0) ||
              (uiInput.length === 0) ||
              (uiConfirmBtn.length === 0) ||
              (uiOptions.length === 0)) { return }

    uiFilter.data('uiInput', uiInput)
    uiFilter.data('uiConfirmBtn', uiConfirmBtn)
    uiFilter.data('uiOptions', uiOptions)
    this.createShakeAnimation(uiConfirmBtn)

    uiCreateBtn.on('click', e => {
      e.preventDefault()
      uiFilter.toggleClass('show')
    })

    uiInput.on('keypress', e => {
      if (uiInput.val().length < 1) { return }
      if (e.which === 13) { return this.createFormSubmit(uiFilter) }
    })

    uiConfirmBtn.on('click', e => {
      e.preventDefault()
      if (uiInput.val().length < 1) { return }

      this.createFormSubmit(uiFilter)
    })
  }
}

export default MultiFilters