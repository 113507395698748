<template>
  <div
    :class="classObject"
    :data-uuid="uuid"
    :data-id="id"
    :data-parent-id="parentId"
    @dblclick.prevent="changeNewFolder"
  >
    <transition name="fade">
      <div v-if="dataUpdating" class="data-updating">
        <div><i class="fa fa-spinner fa-spin fa-1x"></i></div>
      </div>
    </transition>

    <div v-if="userEditObjectNameMode" class="rename-dialog">
      <div>
        <p class="title">
          <i class="fas fa-eraser"></i> 重新命名
        </p>
        <textarea
          ref="renameInput"
          v-model="renameInputValue"
          spellcheck="false"
          onfocus="this.select()"
          autocomplete="off"
        ></textarea>
        <a
          class="btn-confirm"
          href="#"
          @dblclick.prevent=""
          @click.prevent="confirmRename"
        ><i class="fas fa-check"></i></a>
        <a
          class="btn-cancel"
          href="#"
          @dblclick.prevent=""
          @click.prevent="cancelRenaming"
        ><i class="fas fa-times"></i></a>
      </div>
    </div>

    <div ref="preview" class="preview">
      <div class="imgs">
        <img2
          v-for="item in previewImages"
          :key="item.id"
          :width="previewImageSize" 
          :height="previewImageSize" 
          :src="item.preview"
          :large="item.url"
        />
      </div>
      <div class="icon">
        <i class="far fa-folder-open"></i>
      </div>
    </div>

    <div class="name">
      <i :class="nameIconClassObject"></i> {{ name }}
    </div>
  </div>
</template>

<script>
import Img2 from './Img2.vue'

// 
// StorageFolder ( 0.8.4.0 )
// 
export default {
  name: 'StorageFolder',

  components: {
    Img2
  },

  props: {
    uuid:         { type: String, default: '' },
    id:           { type: Number, default: 0  },
    parentId:     { type: Number, default: 0  },
    depth:        { type: Number, default: 0  },
    status:       { type: String, default: '' },
    name:         { type: String, default: '' },
    totalFiles:   { type: Number, default: 0  },
    totalSize:    { type: Number, default: 0  },
    userChose:    { type: Boolean, default: false },
    userEditObjectNameMode: { type: Boolean, default: false },
    dataUpdating: { type: Boolean, default: false },
    systemDefault: { type: Boolean, default: false },
    previewImages: { type: Array, default: null }
  },

  emits: [
    'rename-folder',
    'cancel-folder-renaming',
    'refresh-folder-preview-images'
  ],

  data () {
    return {
      renameInputValue: '',
      previewImageSize: 32
    }
  },
  
  computed: {
    classObject () {
      return {
        'storage-folder': true,
        'updating': this.dataUpdating,
        'user-chose': this.userChose,
        'system-default': this.systemDefault
      }
    },

    nameIconClassObject () {
      return {
        'fas': true,
        'fa-icons': this.systemDefault,
        'fa-images': !this.systemDefault
      }
    }
  },

  watch: {

    previewImages: {
      immediate: true,
      handler (newValue, originalValue) {
        const imageCount = newValue.length
        let newSize = 32

        if (imageCount <= 1 ) {
          newSize = Math.round(32*3)
        } else if (imageCount >= 2 && imageCount <= 4) {
          newSize = Math.round(32*2.05)
        } else if (imageCount >= 5 && imageCount <= 9) {
          newSize = Math.round(32*1.315)
        } else {
          newSize = Math.round(32*1.0)
        }

        this.previewImageSize = newSize
      }
    },

    userEditObjectNameMode: {
      immediate: true,
      handler (newValue, originalValue) {
        if ( !originalValue && newValue == true ) {
          setTimeout(() => {
            if ( !this.$refs.renameInput ) { return }

            this.$refs.renameInput.focus()
          }, 100)
        }
      }
    }
  },

  mounted () {
    this.renameInputValue = this.name

    if ( this.status == 'stored' ) {
      this.fetchPreviewImages()
    }
  },

  methods: {

    fetchPreviewImages () {
      this.$emit('refresh-folder-preview-images', { uuid: this.uuid, useCache: false })
    },

    changeNewFolder (e) {
      if ( this.ignoreChose() ) { return }

      this.$parent.changeFolder({ folderId: this.id, trigger: this.$refs.preview })
    },

    confirmRename () {
      const newName = this.$refs.renameInput.value
      if ( _.isEmpty(newName) ) { return }

      this.$emit('rename-folder', {
        uuid: this.uuid,
        newName,
        renameCallback: (newName) => {
          this.renameInputValue = newName
        }
      })
    },

    cancelRenaming () {
      this.$emit('cancel-folder-renaming', { uuid: this.uuid })
    },

    ignoreChose () {
      return this.userEditObjectNameMode || this.dataUpdating
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>