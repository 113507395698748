// 
// SystemActiveLogUiCore ( 0.6.0.0 )
// 
import SystemActiveLogFormatter from './SystemActiveLogFormatter'
import moment from 'moment'
import Mustache from 'mustache'
import axios from 'axios'

class SystemActiveLogUiCore {

  constructor(mainElement) {
    this.core = {}
    this.core.dataCaches = {}

    this.status = {}
    this.status.currentLogDate = null

    this.settings = {}
    this.settings.api = null

    this.ui = {}
    this.ui.main = $(mainElement)

    this.ui.notice  = this.ui.main.children('.title').find('.notice')
    this.ui.date    = this.ui.main.children('.title').find('.date .text')
    this.ui.prevBtn = this.ui.main.find('a.prev')
    this.ui.nextBtn = this.ui.main.find('a.next')
    this.ui.logs    = this.ui.main.children('.inner')

    this.locale = window.APP_LOCALE || 'zh-TW'

    if (this.checkUi()) { this.setup() }
  }

  checkUi() {
    return (this.ui.main.length === 1) &&
    (this.ui.notice.length === 1) &&
    (this.ui.date.length === 1) &&
    (this.ui.prevBtn.length === 1) &&
    (this.ui.nextBtn.length === 1) &&
    (this.ui.logs.length === 1)
  }

  setup() {
    this.settings.api = this.ui.main.data('url')

    this.initButtons()

    return this.fetchData()
  }

  logsViewTemplate() {
    if (this.locale == 'en') {
      return `\
{{#logs}}
<div class="log" data-user-id="{{userId}}">
<div class="avatar">
<img src="{{userImg}}" alt="{{userName}}"><div class="time">{{timeAgo}}</div>
</div>
<div class="message">
{{#userModifyActionLogs}}

{{#isCreateArticle}}
<p class="create-article"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">created <b>{{count}}</b> articles.</span><!--
{{#urls}}--><a href="{{targetUrl}}" target="_blank"><img src="{{showCreatePostImageUrl}}" alt=""></a><!--{{/urls}}
--></p>
{{/isCreateArticle}}

{{#isEditArticle}}
<p class="edit-article"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">updated the ad copy <b>{{count}}</b> times.</span><!--
{{#urls}}--><a href="{{targetUrl}}" target="_blank"><img src="{{showCreatePostImageUrl}}" alt=""></a><!--{{/urls}}
--></p>
{{/isEditArticle}}

{{#isDestroyArticle}}
<p class="destroy-article log-alert"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text"><b>{{count}}</b> articles removed.</span><!--
--></p>
{{/isDestroyArticle}}

{{#isCreatePost}}
<p class="create-post"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">add <b>{{count}}</b> facebook post link.</span><!--
{{#urls}}--><a href="{{targetUrl}}" target="_blank"><img src="{{showCreatePostImageUrl}}" alt=""></a><!--{{/urls}}
--></p>
{{/isCreatePost}}

{{#isDestroyPost}}
<p class="destroy-post log-alert"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">{{count}} facebook post link removed.</span><!--
--></p>
{{/isDestroyPost}}

{{#isUploadImage}}
<p class="upload-image">upload <b>{{count}}</b> files. <!--
{{#urls}}--><img src="{{.}}"><!--{{/urls}}
--></p>
{{/isUploadImage}}

{{#isDestroyFile}}
<p class="destroy-file log-alert"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text"><b>{{count}}</b> files removed.</span><!--
--></p>
{{/isDestroyFile}}

{{/userModifyActionLogs}}
</div>
</div>
{{/logs}}`
    } else {
      return `\
{{#logs}}
<div class="log" data-user-id="{{userId}}">
<div class="avatar">
<img src="{{userImg}}" alt="{{userName}}"><div class="time">{{timeAgo}}</div>
</div>
<div class="message">
{{#userModifyActionLogs}}

{{#isCreateArticle}}
<p class="create-article"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">建立 {{count}} 篇文案</span><!--
{{#urls}}--><a href="{{targetUrl}}" target="_blank"><img src="{{showCreatePostImageUrl}}" alt=""></a><!--{{/urls}}
--></p>
{{/isCreateArticle}}

{{#isEditArticle}}
<p class="edit-article"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">更新了 {{count}} 次文案</span><!--
{{#urls}}--><a href="{{targetUrl}}" target="_blank"><img src="{{showCreatePostImageUrl}}" alt=""></a><!--{{/urls}}
--></p>
{{/isEditArticle}}

{{#isDestroyArticle}}
<p class="destroy-article log-alert"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">已移除 {{count}} 篇文案</span><!--
--></p>
{{/isDestroyArticle}}

{{#isCreatePost}}
<p class="create-post"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">建立 {{count}} 個素材</span><!--
{{#urls}}--><a href="{{targetUrl}}" target="_blank"><img src="{{showCreatePostImageUrl}}" alt=""></a><!--{{/urls}}
--></p>
{{/isCreatePost}}

{{#isDestroyPost}}
<p class="destroy-post log-alert"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">已移除 {{count}} 個素材</span><!--
--></p>
{{/isDestroyPost}}

{{#isUploadImage}}
<p class="upload-image">上傳了 {{count}} 張圖片 <!--
{{#urls}}--><img src="{{.}}"><!--{{/urls}}
--></p>
{{/isUploadImage}}

{{#isDestroyFile}}
<p class="destroy-file log-alert"><!--
-->{{#showUserName}}<span class="author">{{userName}}</span>{{/showUserName}}<!--
--><span class="text">已移除 {{count}} 個檔案</span><!--
--></p>
{{/isDestroyFile}}

{{/userModifyActionLogs}}
</div>
</div>
{{/logs}}`
    }
  }

  showNewDate() {
    let logDate = moment(this.status.currentLogDate)
    const prevDate = moment().add(-1, 'days')
    const nextDate = moment().add(1, 'days')

    if (this.locale == 'en') {
      logDate = logDate.locale('en')
    }

    let newString =
      logDate.format('MM/DD ddd ')

    if (this.locale == 'en') {
      newString +=
        logDate.isSame(moment().format('YYYY-MM-DD')) ?
          ', Today'
        : logDate.isSame(prevDate.format('YYYY-MM-DD')) ?
          ', Yesterday'
        : logDate.isSame(nextDate.format('YYYY-MM-DD')) ?
          ', Tomorrow'
        : ''
    } else {
      newString +=
        logDate.isSame(moment().format('YYYY-MM-DD')) ?
          ', 今天'
        : logDate.isSame(prevDate.format('YYYY-MM-DD')) ?
          ', 昨天'
        : logDate.isSame(nextDate.format('YYYY-MM-DD')) ?
          ', 明天'
        : ''
    }

    TweenMax.set(this.ui.date, { opacity: 0 })
    this.ui.date.text(newString)
    TweenMax.to(this.ui.date, 0.5, { opacity: 1 })
  }

  showLogUiError(options) {
    if (options == null) { options = {} }

    const message = options.message || ''
    const icon_class = options.icon || 'fas fa-user-edit'

    this.ui.notice.text('--')
    this.ui.logs.html(`\
<div class="no-active">
<div class="icon"><i class="${icon_class}"></i></div>
<div class="message">${message}</div>
</div>\
`)

    TweenMax.to(this.ui.notice, 1, { opacity: 0.1, ease: Power1.easeOut })
    TweenMax.to(this.ui.logs, 1, { opacity: 1, ease: Power1.easeOut })
    TweenMax.to(this.ui.logs, 1, { y: 0, ease: Elastic.easeOut.config(1, 0.3) })
  }

  startLoading() {
    this.ui.date.text(window.ui18.label.loading)
    this.ui.logs.text(window.ui18.label.loading)

    TweenMax.to(this.ui.date, 0.3, { opacity: 0.5 })
    TweenMax.set(this.ui.notice, { opacity: 0 })
    TweenMax.set(this.ui.logs, { opacity: 0, y: -20 })
  }

  cacheKey(choseDate) {
    return "_" + choseDate.replace(/[^\d]+/g, '')
  }

  cacheData(logDate, data) {
    return this.core.dataCaches[this.cacheKey(logDate)] = data
  }

  showNewLogs(data) {
    this.status.currentLogDate = data.logDate
    this.showNewDate()

    if (data.count === 0) {
      this.showLogUiError({message: window.ui18.msg.thereAreNoEventsToday})

    } else {
      this.ui.notice.text(data.count)

      const formatter = new SystemActiveLogFormatter(data.logs)
      const formattedLogs = formatter.format()

      const logsUiHtml =
        Mustache.render(this.logsViewTemplate(), {
          "logs": formattedLogs,
          "targetUrl"() {
            const url = "" + this.url
            if (url.length === 0) { return "#" } else { return url }
          },
          "showCreatePostImageUrl"() {
            const url = "" + this.img

            if (url === 'DELETED') {
              return "/images/icons/post-deleted.png"
            } else {
              if (url.length === 0) {
                return "/images/icons/empty-post.png"
              } else {
                return url
              }
            }
          }
        })

      this.ui.logs.html(logsUiHtml)
      this.ui.logs.find('a[href="#"]').on('click', e => e.preventDefault())

      TweenMax.to(this.ui.notice, 1, { opacity: 1, ease: Power1.easeOut })
      TweenMax.to(this.ui.logs, 1, { opacity: 1, ease: Power1.easeOut })
      TweenMax.to(this.ui.logs, 2, { y: 0, ease: Elastic.easeOut.config(1, 0.3) })
    }
  }

  fetchData(choseDate=null) {
    let cacheData
    this.startLoading()

    // 1. 如果有指定時間，而且有查到快取，就用快取的資料顯示
    if (choseDate != null) {
      cacheData = this.core.dataCaches[this.cacheKey(choseDate)]

      if (cacheData != null) {
        this.showNewLogs(cacheData)
        return
      }
    }

    // 2. 如果沒有快取，就抓新資料
    axios.get(this.settings.api, { params: { chose_date: choseDate } })
    .then(rs => {
      this.cacheData(rs.data.data.logDate, rs.data.data)
      this.showNewLogs(rs.data.data)
    })
    .catch(err => {
      if (err.response && err.response.status === 401 ) {
        this.showLogUiError({icon: 'fas fa-ban', message: window.ui18.msg.logoutTipsInLog })
      } else {
        this.showLogUiError({icon: 'fas fa-ban', message: window.ui18.msg.processFailedPleaseTryAgainLater2 })
      }
    })
  }

  showPrevDateLogs() {
    if ((this.status.currentLogDate == null)) { return }
    const newTime = moment(this.status.currentLogDate).add(-1, 'days')

    if (newTime.isValid()) {
      this.fetchData(newTime.format('YYYY-MM-DD'))
    }
  }

  showNextDateLogs() {
    if ((this.status.currentLogDate == null)) { return }
    const newTime = moment(this.status.currentLogDate).add(1, 'days')

    if (newTime.isValid()) {
      this.fetchData(newTime.format('YYYY-MM-DD'))
    }
  }

  acceptUseButton() {
    return (this.status.currentLogDate != null)
  }

  initButtons() {
    this.ui.prevBtn.on('click', e => {
      e.preventDefault()
      if (this.acceptUseButton()) { this.showPrevDateLogs() }
    })

    this.ui.nextBtn.on('click', e => {
      e.preventDefault()
      if (this.acceptUseButton()) { this.showNextDateLogs() }
    })

    this.ui.main.on('click', '.log a.btn', event => {
      event.preventDefault()
    })
  }
}

export default SystemActiveLogUiCore