import FolderObject2 from './FolderObject2'
import FileObject2 from './FileObject2'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

// 
// storageUserManagementProcessMixin ( 0.9.2.0 )
// 
export default {

  methods: {

    showLoadingBar () {
      this.loading = true
    },

    hideLoadingBar () {
      this.loading = false
    },

    openBrowserFileDialog () {
      this.$refs.fileInput.value = ''
      this.$refs.fileInput.click()
    },

    closeAppDialog () {
      this.close()
    },

    changeRootFolder () {
      this.changeFolder()
    },

    goToUpperFolder () {
      this.changeFolder({
        folderId: this.currentFolderParentId
      })
    },

    changeFolder (options={}) {
      if ( !_.isEmpty(options.trigger) ) {
        TweenMax.set(options.trigger, { x: 0, y: 10, opacity: 0.5 })
        TweenMax.to(options.trigger, 1.5, { x: 0, y: 0, ease: Elastic.easeOut.config(1, 0.3) })
      }

      this.updateCurrentStorageList({
        folderId: options.folderId,
        notAllowPushState: options.notAllowPushState
      })
    },

    updateCurrentStorageList (options={}) {
      this.currentFolderId = options.folderId || ''

      this.resetSortStatus()

      this.hideAllStorageFolderAndFiles({
        events: {
          completed: () => {
            this.resetStorageList()

            if ( !options.notAllowPushState ) {
              this.setFolderPushState({ folderId: this.currentFolderId })
            }

            this.loadNewFileAndFoldersData()
            this.autoScrollToTop({ condition: 80 })
          }
        }
      })
    },

    autoScrollToTop ({ condition=80 }) {
      if (document.documentElement.scrollTop > condition) {
        document.documentElement.scrollIntoView()
      }
    },

    showAllStorageFolderAndFiles (options={}) {
      this.hideLoadingBar()

      const refs = [ this.$refs.storageFolders, this.$refs.storageFiles ]

      TweenMax.to(refs, 0.3, {
        opacity: 1,
        ease: Power1.easeOut,
        onComplete: () => {
          if ( _.isFunction(options.events.completed) ) {
            options.events.completed()
          }
        }
      })
    },

    hideAllStorageFolderAndFiles (options={}) {
      this.showLoadingBar()

      const refs = [ this.$refs.storageFolders, this.$refs.storageFiles ]

      TweenMax.to(refs, 0.3, {
        opacity: 0,
        ease: Power1.easeOut,
        onComplete: () => {
          if ( _.isFunction(options.events.completed) ) {
            options.events.completed()
          }
        }
      })
    },

    changeFolderByUrlAnchor () {
      const matchHash = window.location.hash.match(/^\#nas:folder\:(\d+)$/i)
      const folderId = matchHash ? matchHash[1] : ''

      this.changeFolder({
        folderId: folderId,
        notAllowPushState: true
      })
    },

    loadNewFileAndFoldersData () {

      // load folders
      this.storageOperator.currentFolders({
        useCache: false,
        currentFolder: this.currentFolderId,
        events: {
          ok: (res) => {
            switch (res.data.result) {
              case 'has_folder':
                const newCurrentFolders = this.currentFolders.slice()

                for ( const folderJson of res.data.data.folders ) {
                  const folderData = 
                    new FolderObject2({
                      uuid: uuidv4(),
                      status: 'stored',
                      id: folderJson.id,
                      name: folderJson.name,
                      tree: folderJson.tree,
                      depth: folderJson.depth,
                      parent: folderJson.parent,
                      systemDefault: folderJson.system_default
                    })
            
                  this.currentFoldersCache[`_${folderData.uuid}`] = folderData
                  newCurrentFolders.push(folderData)
                }

                this.currentFolders = newCurrentFolders

                this.currentFolderParentId =
                  res.data.data.current_folder_parent_id

                this.currentFolderLabel = 
                  res.data.data.current_folder_label

                this.autoSortFolderAndFiles({ sortRange: 'folder' })

                break
              
              case 'no_folder_found':
                this.currentFolderParentId =
                  res.data.data.current_folder_parent_id

                this.currentFolderLabel = 
                  res.data.data.current_folder_label

                break

              case 'current_folder_not_found':
                this.alertDialog('系統找不到這個資料夾 ( 可能已經移除 )', '錯誤', 'error', {
                  closed: () => { this.changeRootFolder() }
                })

                break
            
              default:
                break
            }

            this.showAllStorageFolderAndFiles({
              events: { completed: () => { } }
            })
          },
          failed: (res, err) => {
            // failed
          }
        }
      })

      // load files
      this.storageOperator.currentAssets({
        currentFolder: this.currentFolderId,
        events: {
          ok: (res) => {
            switch (res.data.result) {
              case 'has_files':
                this.fileNotFound = false

                const editorAssetIds = this.ideaEditorStatus.userAssetIds.slice()
                const newFiles = this.currentFiles.slice()

                for ( const f of res.data.data.files) {
                  const fileData = new FileObject2({
                    uuid: uuidv4(),
                    id: f.id,
                    folderId: f.folderId,
                    contentType: f.contentType,
                    status: 'stored',
                    fileName: f.name,
                    fileExt: f.extension,
                    fileSize: f.size,
                    previewUrl: f.preview,
                    storageUrl: f.url,
                    userRating: f.rating,
                    createdAt: f.createdAt
                  })

                  fileData.bindToArticle = editorAssetIds.indexOf(`${f.id}`) > -1

                  this.currentFilesCache[`_${fileData.uuid}`] = fileData
                  this.uploadAssetsCache[`_${fileData.uuid}`] = null

                  newFiles.push(fileData)
                }

                this.currentFiles = newFiles

                this.autoSortFolderAndFiles({ sortRange: 'files' })

                break
              
              case 'no_files_found':
                this.fileNotFound = true

                break
            
              default:
                break
            }
          },
          failed: (res, err) => {
            // failed
          }
        }
      })
    },

    async createNewFolder () {
      const { value: folderName } = await this.$swal({
        title: '新增一個資料夾 <i class="far fa-folder-open"></i>',
        input: 'text',
        inputPlaceholder: '範例：11月棚拍、商攝參考照片',
        confirmButtonText: '好'
      })

      if ( _.isEmpty(folderName) ) {
        return
      }

      this.showLoadingBar()

      this.storageOperator.createFolder({
        name: folderName,
        currentFolder: this.currentFolderId,
        events: {
          ok: (res) => {
            const newCurrentFolders = this.currentFolders.slice()
            const folderJson = res.data.data.folder

            const folderData = 
              new FolderObject2({
                uuid: uuidv4(),
                status: 'new',
                id: folderJson.id,
                name: folderJson.name,
                tree: folderJson.tree,
                depth: folderJson.depth,
                parent: folderJson.parent
              })
  
            this.currentFoldersCache[`_${folderData.uuid}`] = folderData
            newCurrentFolders.unshift(folderData)

            this.currentFolders = newCurrentFolders

            this.notify(`資料夾「${folderName}」已新增`, '新增資料夾', 'fas fa-folder-open')
          },
          failed: (res, err) => {
            switch(res.status) {
              case 404:
                this.alertDialog('新增失敗，因為上層的資料夾可能已經被移除', '錯誤', 'error', {
                  closed: () => { this.changeRootFolder() }
                })
                break

              default:
                this.alertDialog('新增失敗，請重新再試一次', '錯誤', 'error', {
                  closed: () => { this.reloadCurrentPage() }
                })
                break
            }
          },
          always: () => {
            this.hideLoadingBar()
          }
        }
      })
    },

    canelFileChose ({ uuid }) {
      const fileData = this.findFileDataByUploadAssetUuid(uuid)
      if ( !_.isObject(fileData) ) { return }

      fileData.userChose = false
      fileData.userChoseByUiSection = false
    },

    choseFile ({ uuid, by }) {   
      const fileData = this.findFileDataByUploadAssetUuid(uuid)
      if ( !_.isObject(fileData) ) { return }

      switch (by) {
        case 'click':
          fileData.userChose = !fileData.userChose
          break
        case 'uiSection':
          fileData.userChose = true
          fileData.userChoseByUiSection = true
          break
        case 'userDrag':
          fileData.userChose = true
          break
      }
    },

    selectAllFiles () {
      for ( const item of this.currentFiles ) {
        item.userChose = true
      }
    },

    deselectAllFiles () {
      for ( const item of this.currentFiles ) {
        item.userChose = false
      }
    },

    uploadFile (event) {
      for ( const file of event.target.files ) {
        this.uploadAssetToStorage(file)
      }
    },

    addNewUserAssetToUi (ua) {
      this.setFileDataToCurrentFiles(ua)
      return true
    },

    uploadAssetToStorage (file) {

      this.storageOperator.uploadAsset({
        file: file,
        params: [
          { name: 'current_folder', value: this.currentFolderId }
        ],
        events: {
          beforeStart: (ua) => {
            return this.addNewUserAssetToUi(ua)
          },
          uploaded: (ua) => {
            this.updateAssetUploadCompleted(ua)
          },
          progress: (ua) => {
            this.updateAssetUploadingStatus(ua)
          },
          error: (ua) => {
            this.updateAssetUploadFailed(ua, 'error')
          },
          timeout: (ua) => {
            this.updateAssetUploadFailed(ua, 'timeout')
          },
          abort: (ua) => {
            this.updateAssetUploadFailed(ua, 'abort')
          }
        }
      })
    },

    updateAssetUploadingStatus (ua) {
      const fileData = this.findFileDataByUploadAssetUuid(ua.id)
      if ( !_.isObject(fileData) ) { return }

      if ( fileData.status === 'new' ) {
        fileData.status = 'uploading'
      }

      fileData.updateUploadProgress(ua.progressLoaded, ua.progressTotal)
    },

    updateAssetUploadCompleted (ua) {
      const fileData = this.findFileDataByUploadAssetUuid(ua.id)
      if ( !_.isObject(fileData) ) { return }

      const uaJsonData = ua.getServerResponse()
      if ( !_.isObject(uaJsonData) ) { return }

      if ( fileData.status === 'uploading' ) {
        fileData.status = 'stored'
        fileData.id = uaJsonData.data.file_id
        fileData.storageUrl = uaJsonData.data.url
        fileData.previewUrl = uaJsonData.data.preview_url
      }

      this.notify(`${fileData.name}`, `上傳完成 ( ${fileData.humanSize} )`, 'fas fa-file-upload')
    },

    updateAssetUploadFailed (ua, problem) {
      const fileData = this.findFileDataByUploadAssetUuid(ua.id)
      if ( !_.isObject(fileData) ) { return }

      const resText = ua.xhr.responseText

      switch (problem) {
        case 'error':
          fileData.status = 'uploadError'

          if ( /storage_usage_exceeded_the_limit/.test(resText)) {
            this.notify('您的儲存空間已滿', window.ui18.label.uploadFailed, 'fas fa-times')
          } else if ( /file_size_exceeded_the_limit/.test(resText)) {
            this.notify('檔案上傳過大', window.ui18.label.uploadFailed, 'fas fa-times')
          } else if ( /assets_quota_exceeded_the_limit/.test(resText)) {
            this.notify('檔案上傳數量已達上限', window.ui18.label.uploadFailed, 'fas fa-times')
          } else {
            this.notify('檔案上傳失敗', window.ui18.label.uploadFailed, 'fas fa-times')
          }
          
          break

        case 'timeout':
          fileData.status = 'uploadTimeout'
          this.notify('上傳檔案逾時', window.ui18.label.uploadFailed, 'fas fa-times')
          break

        case 'abort':
          fileData.status = 'uploadAbort'
          this.notify('上傳中途終止', window.ui18.label.uploadFailed, 'fas fa-times')
          break
      
        default:
          throw 'incorrect problem params.'
          break
      }
    },

    moveObjectToSpecificFolder (eleDestinationFolder) {
      this.showLoadingBar()
      if (this.choseFilesCount < 1) { return }

      const destinationFolderId = eleDestinationFolder.dataset.id
      const destinationFolderUuid = eleDestinationFolder.dataset.uuid

      const choseFileFolderId = this.choseFiles[0].folderId
      const sameFolder = destinationFolderId == choseFileFolderId

      if (sameFolder) {
        this.hideLoadingBar()
        this.notify(`您的檔案位於相同的資料夾。`, '無法搬移', 'fas fa-times')
        return
      }

      const choseFileIds = _.map(this.choseFiles, 'id')

      this.storageOperator.moveObjects({
        destinationFolder: destinationFolderId,
        folderIds: "",
        fileIds: choseFileIds.join(","),
        events: {
          ok: (res) => {

            for ( const file of this.choseFiles ) {
              const itemIndex = this.currentFiles.indexOf(file)
              this.currentFiles.splice(itemIndex, 1)
            }

            this.notify(`已搬移 ${this.choseFilesCount} 個檔案。`, '搬移資料', 'fas fa-exchange-alt')

            if ( destinationFolderUuid ) {
              this.refreshFolderPreviewImages({
                uuid: destinationFolderUuid, useCache: false
              })
            }

            this.cancelAllUserAssetChose()
          },
          failed: (res, err) => {
            switch(res.status) {
              case 404:
                this.alertDialog('搬移失敗，因為資料夾已經被移除', '錯誤', 'error', {
                  closed: () => { this.reloadCurrentPage() }
                })
                break

              default:
                this.alertDialog('搬移失敗，請重新再試一次', '錯誤', 'error', {
                  closed: () => { this.reloadCurrentPage() }
                })
                break
            }

            this.cancelAllUserAssetChose()
          },
          always: () => {
            this.hideLoadingBar()
          }
        }
      })

    },

    renameMultiObjects () {
      for ( const item of this.choseFiles ) {
        item.userEditObjectNameMode = true
      }
    },

    renameObject () {
      if ( !_.isObject(this.contextMenu.eleStorageObject) ) { return }

      const className = this.contextMenu.eleStorageObject.className
      const objectUuid = this.contextMenu.eleStorageObject.dataset.uuid
      if ( _.isEmpty(objectUuid) ) { return }

      if ( className.indexOf('storage-file') > -1 ) {
        const fileData = this.findFileDataByUploadAssetUuid(objectUuid)
        if ( !_.isObject(fileData) ) { return }

        fileData.userChose = true
        fileData.userEditObjectNameMode = true

      } else if ( className.indexOf('storage-folder') > -1 ) {
        const folderData = this.findFolderDataByUuid(objectUuid)
        if ( !_.isObject(folderData) ) { return }

        folderData.userEditObjectNameMode = true

      } else {
        // ignore
      }
    },

    renameFile ({ uuid, newName, renameCallback }) {
      if ( _.isEmpty(newName) ) { return }

      const fileData = this.findFileDataByUploadAssetUuid(uuid)
      if ( !_.isObject(fileData) ) { return }

      fileData.dataUpdating = true
      this.cancelFileRenaming({ uuid })

      setTimeout(() => {
        this.storageOperator.renameAsset({
          objectId: fileData.id,
          newName: newName,
          events: {
            ok: (res) => {
              fileData.setNewName(res.data.data.new_name)
              renameCallback(res.data.data.new_name)

              this.notify(`已更改為「${fileData.name}」`, '更改檔名', 'fas fa-edit')
            },
            failed: (res, err) => {
              switch(res.status) {
                case 404:
                  this.alertDialog('更名失敗，因為檔案可能被移除', '錯誤', 'error')
                  break
  
                default:
                  this.alertDialog('更名失敗，請重新再試一次', '錯誤', 'error')
                  break
              }
            },
            always: () => {
              fileData.dataUpdating = false
            }
          }
        })
      }, 300)
    },

    renameFolder ({ uuid, newName, renameCallback }) {
      const folderData = this.findFolderDataByUuid(uuid)
      if ( !_.isObject(folderData) ) { return }

      folderData.dataUpdating = true
      this.cancelFolderRenaming({ uuid })

      setTimeout(() => {
        this.storageOperator.renameFolder({
          objectId: folderData.id,
          newName: newName,
          events: {
            ok: (res) => {
              folderData.name = res.data.data.new_name
              renameCallback(res.data.data.new_name)

              this.notify(`已更改為「${folderData.name}」`, '更改資料夾名', 'fas fa-edit')
            },
            failed: (res, err) => {
              switch(res.status) {
                case 404:
                  this.alertDialog('更名失敗，因為資料夾可能被移除', '錯誤', 'error')
                  break
  
                default:
                  this.alertDialog('更名失敗，請重新再試一次', '錯誤', 'error')
                  break
              }
            },
            always: () => {
              folderData.dataUpdating = false
            }
          }
        })
      }, 300)
    },

    cancelFolderRenaming ({ uuid }) {
      const folderData = this.findFolderDataByUuid(uuid)
      if ( !_.isObject(folderData) ) { return }

      folderData.userEditObjectNameMode = false
    },

    cancelFileRenaming ({ uuid }) {
      const fileData = this.findFileDataByUploadAssetUuid(uuid)
      if ( !_.isObject(fileData) ) { return }

      fileData.userEditObjectNameMode = false
    },

    cutObjects () {
      if ( this.choseFilesCount < 1 ) { return }

      for ( const fileData of this.tempZoneFiles ) {
        fileData.userCutObject = false
      }

      this.tempZoneFiles = this.choseFiles.concat([])
      this.tempZoneFilesCount = this.choseFilesCount
      this.tempZoneFilesOldFolderId = this.currentFolderId

      for ( const fileData of this.tempZoneFiles ) {
        fileData.userCutObject = true
      }

      this.notify(`已剪下 ${this.tempZoneFilesCount} 個檔案，供貼上使用。`, '剪下', 'fas fa-cut')
    },

    pasteObjects ({ pasteFolderId }) {
      const noFileNeedsToPaste = this.tempZoneFilesCount == 0
      if (noFileNeedsToPaste) { return }

      const pasteToSameDirectory = this.tempZoneFilesOldFolderId == pasteFolderId
      if (pasteToSameDirectory) {
        this.notify(`您貼的檔案位於相同的資料夾。`, '無法貼上', 'fas fa-times')
        return
      }

      const pasteFolderIdIsCurrentFolderId =
        this.currentFolderId == pasteFolderId

      const fileIds =_.map(this.tempZoneFiles, (v) => { return v.id })

      this.storageOperator.moveObjects({
        destinationFolder: pasteFolderId,
        folderIds: '',
        fileIds: fileIds,
        events: {
          ok: (res) => {

            if (pasteFolderIdIsCurrentFolderId) {
              for ( const fileData of this.tempZoneFiles ) {
                fileData.resetUserUiStatus()
                fileData.resetFolderId(pasteFolderId)
  
                this.currentFilesCache[`_${fileData.uuid}`] = fileData
                this.uploadAssetsCache[`_${fileData.uuid}`] = null
                this.currentFiles.unshift(fileData)
              }
            } else {
              this.removeCurrentFilesFileData({ fileVar: this.tempZoneFiles, multi: true })
            }
            
            this.notify(`已移動 ${this.tempZoneFilesCount} 個檔案。`, '貼上檔案', 'fas fa-paste')

            this.resetTempZoneFiles()
            this.refreshCurrentFolderPreviewImages()
          },
          failed: (res, err) => {
            switch(res.status) {
              case 404:
                this.alertDialog('貼上移動失敗，資料夾可能被移除', '錯誤', 'error')
                break
              default:
                this.alertDialog('貼上移動失敗，請重新再試一次。', '錯誤', 'error')
                break
            }
          }
        }
      })
    },

    addUserAssetToIdeaEditorFromContextMenu({ dataSource }) {

      let addFiles = []

      if (dataSource == 'contextMenu') {
        if ( !_.isObject(this.contextMenu.eleStorageObject) ) { return }

        const className = this.contextMenu.eleStorageObject.className
        const objectUuid = this.contextMenu.eleStorageObject.dataset.uuid
        if ( _.isEmpty(objectUuid) || className.indexOf('storage-file') == -1 ) { return }
        
        const fileData = this.findFileDataByUploadAssetUuid(objectUuid)
        if ( !_.isObject(fileData) ) { return }

        addFiles = [ fileData ]

      } else if (dataSource == 'userChose') {
        addFiles = this.choseFiles
      } else {
        // ignore
      }

      const addFileIds = _.map(addFiles, 'id')

      if (addFileIds.length > 0) {
        const currentIdeaArticleId =
          window.IdeaEditorUploadFiles.getCurrentArticleId() || 0

        this.createArticleAssetsSettings({
          choseAssetIds: addFileIds,
          articleId: currentIdeaArticleId
        })
      }
    },

    addUserAssetToIdeaEditor({ files }) {
      if (this.ideaEditorStatus.enabled) {
        window.IdeaEditorUploadFiles.insertUserAssetToEditor(files)

        this.notify(`已加入 ${files.length} 個檔案到文案內。`, 'NAS 管理', 'fas fa-file-import')
      }
    },

    copyChoseFilesUrl() {
      const copyDataArray = []

      for (const file of this.choseFiles) {
        copyDataArray.push(`檔案：${file.name}`)
        copyDataArray.push(`URL連結：${file.storageUrl}`)
        copyDataArray.push('')
      }

      const copyString = copyDataArray.join("\r\n")

      this.$copyText(copyString).then(() => {
        this.notify(`已複製 ${this.choseFilesCount} 個連結。`, '複製URL', 'fas fa-link')
      }, () => {
        this.notify(`無法複製連結，請重新再試一次 或 檢查瀏覽器`, '複製URL', 'fas fa-times')
      })
    },

    removeOtherUiUserAssets({ userAssetIds }) {
      if (window.IdeaEditorUploadFiles) {
        for ( const fileId of userAssetIds ) {
          window.IdeaEditorUploadFiles.removeFileUiByUserAssetId(fileId)
        }
      }
    },

    async destroyObject({ uuid, type }) {

      if ( type == 'file' ) {
        const fileData = this.findFileDataByUploadAssetUuid(uuid)
        if ( !_.isObject(fileData) ) { return }

        this.showLoadingBar()

        this.storageOperator.deleteAsset({
          objectIds: fileData.id,
          events: {
            ok: (res) => {
              this.removeCurrentFilesFileData({ fileVar: fileData, multi: false })
              this.removeOtherUiUserAssets({ userAssetIds: [ fileData.id ] })

              this.notify(`已移除 ${fileData.name}。`, '移除檔案', 'fas fa-trash-alt')
            },
            failed: (res, err) => {
              switch(res.status) {
                case 404:
                  this.alertDialog('移除失敗，檔案可能已經移除了', '錯誤', 'error')
                  break
              
                default:
                  this.alertDialog('移除失敗，請重新再試一次。', '錯誤', 'error')
                  break
              }
            },
            always: () => {
              this.hideLoadingBar()
            }
          }
        })
      }

      if ( type == 'folder' ) {

        // 
        // 開發備註
        // 
        //  1. 狀態回饋
        //  2. root 位置的 folder id 為 0 但 標 deleting 的要隱藏
        //  3. 當 foolder 或 file 被標 deleting 不能做任何更動
        //  4. 移除資料夾時，需要輸入驗證或其他方式讓使用者不要誤觸

        const folderData = this.findFolderDataByUuid(uuid)
        if ( !_.isObject(folderData) ) { return }

        if (folderData.systemDefault) {
          this.notify(`無法移除，系統預設的資料夾。`, '移除失敗', 'fas fa-trash-alt')
          return
        }

        await this.deleteConfirm({
          message: `確定移除 ${folderData.name}`,
          events: {
            accept: () => {
              this.storageOperator.deleteFolder({
                objectId: folderData.id,
                events: {
                  ok: (res) => {
                    this.removeFolderData({ folderData })
                    this.notify(`已準備開始移除 ${folderData.name} `, '移除資料夾', 'fas fa-trash-alt')
                  },
                  failed: (res, err) => {
                    switch(res.status) {
                      case 403:
                        this.alertDialog('資料夾已經開始移除了，請您等候系統處理', '錯誤', 'error')
                        break
                      case 404:
                        this.alertDialog('移除失敗，資料夾可能已經移除了', '錯誤', 'error')
                        break
                      default:
                        this.alertDialog('移除失敗，請重新再試一次。', '錯誤', 'error')
                        break
                    }
                  }
                }
              })
            },
            reject: ignore => {}
          }
        })

      }
    },

    async destroyObjectByContextMenu () {

      if ( !_.isObject(this.contextMenu.eleStorageObject) ) { return }

      const className = this.contextMenu.eleStorageObject.className
      const objectUuid = this.contextMenu.eleStorageObject.dataset.uuid
      if ( _.isEmpty(objectUuid) ) { return }

      if ( className.indexOf('storage-file') > -1 ) {
        this.destroyObject({ uuid: objectUuid, type: 'file' })
      }
      
      if ( className.indexOf('storage-folder') > -1 ) {
        this.destroyObject({ uuid: objectUuid, type: 'folder' })
      }
    },

    async destroyMultiObjectByContextMenu () {
      const choseFiles = this.choseFiles
      const choseFileIds = _.map(choseFiles, 'id')
      if ( _.isEmpty(choseFileIds) ) { return }

      await this.deleteConfirm({
        message: `確定移除 ${choseFileIds.length} 個檔案？`,
        events: {
          accept: () => {
            this.showLoadingBar()

            this.storageOperator.deleteAsset({
              objectIds: choseFileIds,
              events: {
                ok: (res) => {
                  this.removeCurrentFilesFileData({ fileVar: choseFiles, multi: true })
                  this.removeOtherUiUserAssets({ userAssetIds: choseFileIds })

                  this.notify(`已移除 ${res.data.data.success_count} 個檔案。`, '移除檔案', 'fas fa-trash-alt')
                },
                failed: (res, err) => {
                  switch(res.status) {
                    case 404:
                      this.alertDialog('移除失敗，找不到可以移除的檔案', '錯誤', 'error')
                      break
                  
                    default:
                      this.alertDialog('移除失敗，請重新再試一次。', '錯誤', 'error')
                      break
                  }
                },
                always: () => {
                  this.hideLoadingBar()
                }
              }
            }) // [end] this.storageOperator.deleteAsset
          },
          reject: () => {
            // ignore
          }
        }
      })
    },

    ratingFile ({ uuid, ratingNumber }) {
      const fileData = this.findFileDataByUploadAssetUuid(uuid)
      if ( !_.isObject(fileData) || !fileData.hasBeenStored() ) { return }

      clearTimeout(fileData.userRatingApiTimer)

      fileData.userRating = ratingNumber
      fileData.userRatingApiUpdating = false

      fileData.userRatingApiTimer = 
        setTimeout(() => {
          fileData.userRatingApiUpdating = true

          this.storageOperator.ratingAsset({
            objectId: fileData.id,
            rating: ratingNumber,
            events: {
              ok: (res) => {
                fileData.userRatingApiUpdating = false
                fileData.userRating = res.data.data.new_rating
              },
              failed: (res, err) => {
                fileData.userRatingApiUpdating = false

                switch(res.status) {
                  case 404:
                    this.alertDialog('更新星星失敗，檔案可能被移除', '錯誤', 'error')
                    break
    
                  default:
                    this.alertDialog('更新星星失敗，請重新再試一次。', '錯誤', 'error')
                    break
                }
              }
            }
          })
        }, 600)
    },

    terminateUploadAssetByUuid (uuid) {
      const userAsset = this.findUploadAssetByUuid(uuid)
      if ( !_.isObject(userAsset) ) { return }

      const terminateUploading =
        userAsset.inProcessing() || userAsset.inQueued() || userAsset.processFailed()

      if (terminateUploading) {
        userAsset.abort()
      }
    },

    terminateUpload ({ uuid }) {
      const fileData = this.findFileDataByUploadAssetUuid(uuid)
      if ( !_.isObject(fileData) ) { return }

      this.removeCurrentFilesFileData({ fileVar: fileData, multi: false })
      this.terminateUploadAssetByUuid(uuid)

      this.notify(`已終止 ${fileData.name} 上傳`, "終止上傳", 'far fa-hand-paper')
    },
    
    thxSearchStorage: _.throttle(function () {
      console.log('thxSearchStorage', this.searchKeywords)
    }, 400),

    searchStorage ({ keywords }) {
      this.searchKeywords = keywords
      this.thxSearchStorage()
    },

    createArticleAssetsSettings ({
      zapToIdeaArticleCreatePage=false,
      articleId,
      choseAssetIds
    }) {

      this.articleAssetsOperator.createArticleAssets({
        params: {
          articleId: articleId,
          assetIds: choseAssetIds.join(',')
        },
        events: {
          ok: res => {
            if (zapToIdeaArticleCreatePage) {
              window.location = `/ideas/new`
            } else {
              this.addUserAssetToIdeaEditor({ files: this.choseFiles })
              this.close()
            }
          },
          failed: (res, err) => {
            this.notify(`暫時無法取得附件檔案。`, '發生錯誤', 'fas fa-times')
            console.error(res)
          },
          always: () => { }
        }
      })
    },

    addFileToNewArticle () {
      const choseFileIds = _.map(this.choseFiles, 'id')
      if (choseFileIds.length == 0) { return }

      this.createArticleAssetsSettings({
        choseAssetIds: choseFileIds,
        articleId: null,
        zapToIdeaArticleCreatePage: true
      })
    },
  
    addFileToExistArticle () {
      if (!window.IdeaEditorUploadFiles) { return }

      const currentIdeaArticleId =
        window.IdeaEditorUploadFiles.getCurrentArticleId() || 0

      const choseFileIds = _.map(this.choseFiles, 'id')
      if (choseFileIds.length == 0) { return }

      this.createArticleAssetsSettings({
        choseAssetIds: choseFileIds,
        articleId: currentIdeaArticleId
      })
    }

  }

}