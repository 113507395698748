// 
// RequestFbAuthorizationBtns ( 0.8.1.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import FbOperator from '../../shared/fb/FbOperator'

class RequestFbAuthorizationBtns extends GtUiComponent {
  name() {
    return 'RequestFbAuthorizationBtns'
  }

  fetchUi() {
    this.ui.rfbBtns = $('.request-fb-authorization-btn,.rfb-btn')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.rfbBtns.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {

    this.fb = this.createFbOperator()

    this.ui.rfbBtns.each((_, self) => {
      this.asyncInit(self)
    })
  }

  asyncInit(element) {
    if (element instanceof jQuery) {
      this.initButton(element)
    } else {
      const uiElement = $(element)
      if (uiElement.length < 1) { return }

      this.initButton(uiElement)
    }
  }

  initButton(uiButton) {
    uiButton.on('click', (e) => {
      e.preventDefault()
      this.fb.requestUserLogin()
    })
  }

  notify(text, title, icon) {
    window.PageHelper.notify(text, title, icon)
  }

  createFbOperator () {
    const instance =
      new FbOperator({
        events: {
          fbSdkInitFailed: () => {
            this.notify(window.ui18.msg.pleaseTryAgainLater, window.ui18.label.processFailed, 'fas fa-ban')
          },
          beforeGetPageAccount: () => {
            this.notify(window.ui18.msg.getFacebookPageData, window.ui18.label.authorizationProcess, 'fas fa-project-diagram')
          },
          syncPageAccountSuccess: ({ pageAccounts }) => {
            this.notify(window.ui18.msg.nPagesAuthorizationCompleted(pageAccounts.length), window.ui18.label.authorizationProcess, 'fas fa-check')

            setTimeout(_ => window.location.reload(), 500)
          },
          syncPageAccountFailed: ({ err }) => {
            console.error('syncPageAccountFailed', err)

            this.notify(window.ui18.msg.pleaseTryAgainLater, window.ui18.label.authorizationFailed, 'fas fa-times')
          },
          beforeExchangeToken: () => {
            this.notify(window.ui18.msg.requestingAuthorization, window.ui18.label.authorizationProcess, 'fab faa-flash animated  fa-facebook-square')
          },
          exchangeTokenFailed: ({ err }) => {
            console.error('exchangeTokenFailed', err)

            this.notify(`${window.ui18.msg.pleaseTryAgainLater} [2]`, window.ui18.label.authorizationFailed, 'fas fa-times')
          },
          requestUserLoginFailed: () => {
            this.notify(window.ui18.msg.userCancelledTheAuthentication, window.ui18.label.authorizationFailed, 'fas fa-times')
          }
        }
      })

    return instance
  }

}

export default RequestFbAuthorizationBtns