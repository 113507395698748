<template>
  <div class="storage-file-rating">
    <div class="left">
    <!--
    --><a
        href="#rating:1"
        @click.prevent="rating(1, $event)"
       ><i :class="rating1BtnClassObj"></i></a><!--
    --><a
        href="#rating:2"
        @click.prevent="rating(2, $event)"
       ><i :class="rating2BtnClassObj"></i></a><!--
    --><a
        href="#rating:3"
        @click.prevent="rating(3, $event)"
       ><i :class="rating3BtnClassObj"></i></a><!--
    --><a
        href="#rating:4"
        @click.prevent="rating(4, $event)"
       ><i :class="rating4BtnClassObj"></i></a><!--
    --><a
        href="#rating:5"
        @click.prevent="rating(5, $event)"
       ><i :class="rating5BtnClassObj"></i></a><!--
    -->
    </div>
    <div class="right">
      <a
        v-if="apiUpdating"
        href="#"
        @click.prevent
      ><i class="fas fa-fw fa-spinner fa-spin fa-1x"></i></a>
      <a
        v-else
        content="留個備註"
        href="#addComments"
        style="cursor: not-allowed;"
        @click.prevent
      ><i :class="addCommentsBtnClassObj"></i></a>
    </div>
  </div>
</template>

<script>


// 
// StorageFileRating ( 0.8.2.0 )
// 
export default {
  name: 'StorageFileRating',

  props: {
    number:      { type: Number, default: 0 },
    apiUpdating: { type: Boolean, default: false }
  },

  emits: [
    'update'
  ],

  data () {
    return { }
  },

  computed: {
    rating1BtnClassObj () {
      return this.number >= 1 ? 'fas fa-fw fa-star' : 'far fa-fw fa-star'
    },

    rating2BtnClassObj () {
      return this.number >= 2 ? 'fas fa-fw fa-star' : 'far fa-fw fa-star'
    },

    rating3BtnClassObj () {
      return this.number >= 3 ? 'fas fa-fw fa-star' : 'far fa-fw fa-star'
    },

    rating4BtnClassObj () {
      return this.number >= 4 ? 'fas fa-fw fa-star' : 'far fa-fw fa-star'
    },

    rating5BtnClassObj () {
      return this.number >= 5 ? 'fas fa-fw fa-star' : 'far fa-fw fa-star'
    },

    addCommentsBtnClassObj () {
      return 'far fa-fw fa-comment-dots'
    }
  },

  methods: {

    rating (newNumber, e) {
      if ( this.apiUpdating ) { return }

      if ( this.number == newNumber ) {
        this.sharkButton(e.currentTarget, true)
        this.$emit('update', { ratingNumber: 0 })
      } else {
        this.sharkButton(e.currentTarget)
        this.$emit('update', { ratingNumber: newNumber })
      }
    },

    sharkButton (element, hide=false) {
      if ( hide ) {
        // ignore
      } else {
        TweenMax.set(element, { x: 0, y: 6, rotation: 20 })
        TweenMax.to(element, 1.5, {
          x: 0, y: 0, rotation: 0,
          ease: Elastic.easeOut.config(1, 0.3)
        })
      }
    }
  
  }
}
</script>

<style lang="scss" scoped>
.storage-file-rating {
  display: table;

  width: 100%;
  // height: 5em;

  > div {
    display: table-cell;

    width: auto;
    height: 100%;

    text-align: left;
    vertical-align: middle;

    &.left {
      a { margin-right: 0.125em; }
    }

    &.right {
      width: 1.5em;

      text-align: center;
    }

  }

  a {
    display: inline-block;

    i {
      opacity: 1;
      transition: color .1s ease, opacity .1s ease;
    }

    i.fas {
      color: lighten(#5469d4, 5%);
    }

    i.far {
      color: lighten(gray, 40%);
    }

    &:hover i {
      opacity: 0.3;

      &.fas {
        opacity: 1 !important;
      }
    }
  }
}
</style>