// 
// CopyBtn ( 1.0.1.0 )
// 
import ClipboardJS from 'clipboard'

import { GtUiComponent } from '../../shared/core/GtUi'

class CopyBtn extends GtUiComponent {
  name() {
    return 'CopyBtn'
  }

  fetchUi() {
    this.core.clipboard = null

    this.ui.btns = $('.copy-btn')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.btns.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.btns.each((_, self) => {
      this.asyncInit(self)
    })
  }

  asyncInit(element) {
    const uiElement = $(element)
    if (uiElement.length < 1) { return }

    this.initButton(uiElement)
  }

  initButton(uiButton) {
    uiButton.click(e => e.preventDefault())

    const clipboard = new ClipboardJS(uiButton[0])
    
    clipboard.on('success', _ => {
      const msg = window.ui18.msg.copyDataSuccess(uiButton.data('clipboardText'))
      window.PageHelper.notify(msg, window.ui18.label.clipboard, 'fas fa-cut')

      TweenMax.set(uiButton, { scale: 1, y: 0, rotation: 0 })
      TweenMax.to(uiButton, 0.1, { scale: 1, yoyo: true, y: 5, repeat: 1 })
    })
    
    clipboard.on('error', _ => {
      const msg = window.ui18.msg.copyDataError(uiButton.data('clipboardText'))
      window.PageHelper.notify(msg, window.ui18.label.clipboard, 'fas fa-times')
    })

    uiButton.data('clipboard', clipboard)
  }
  
}

export default CopyBtn