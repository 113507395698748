<template>
  <div class="upload-tips">
    <div class="icon">
      <i class="fas fa-icons"></i>
      <i class="fas fa-photo-video"></i>
      <i class="far fa-folder-open"></i>
    </div>
    <div class="message">
      在 AdOps 收集、管理、分享資料
    </div>
    <div class="buttons">
      <a
        v-tooltip="'點一下，上傳檔案'"
        href="#"
        @click.prevent="openBrowserFileDialog"
      >
        新增 <i class="fas fa-file-upload"></i>
      </a>
    </div>
  </div>
</template>

<script>
// 
// UploadTips ( 0.9.1.0 )
// 
export default {
  name: 'UploadTips',

  methods: {
    openBrowserFileDialog () {
      this.$parent.openBrowserFileDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-tips {
  text-align: center;

  margin-top: 5em;
  margin-bottom: 3em;

  .icon {
    color: fade-out(lighten(#1a1f36, 30%), 0.1);
    margin-bottom: 1em;

    i {
      font-size: 48px;
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  } // [end] .icon

  .message {
    font-size: 16px;
    margin-top: 1em;
    margin-bottom: 1em;
    letter-spacing: 1.5px;
  } // [end] .message

  .buttons {
    padding: 1em;

    a {
      display: inline-block;

      padding: 0.75em 1.125em;
      border-radius: 3px;

      color: white;
      background-color: #3b477b;
      transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;

      i {
        margin-left: 0.25em;
      }

      &:hover {
        background-color: lighten(#3b477b, 10%);
      }

    } // [end] a

  } // [end] .buttons

}
</style>