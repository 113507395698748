<template>
  <div ref="bar" class="storage-loading-bar"></div>
</template>

<script>


// 
// StorageLoadingBar ( 0.9.0.0 )
// 
export default {
  name: 'StorageLoadingBar',

  props: {
    loading: { type: Boolean, default: false }
  },

  watch: {
    loading: function () {
      if ( this.loading ) {
        this.show()
      } else {
        this.hide()
      }
    }
  },

  mounted () {
    // nothing
  },

  methods: {
    hide () {
      const elBar = this.$refs.bar

      TweenMax.to(elBar, 0.3, {
        opacity: 0, height: 0, ease: Power1.easeOut, width: '100%'
      })
    },

    show () {
      const elBar = this.$refs.bar

      const tl = new TimelineMax({ paused: true })
      tl.add('setup')
      tl.set(elBar, { opacity: 0, height: 6, width: '0%' })
      tl.add('fadeIn')
      tl.to(elBar, 0.7, { opacity: 1, ease: Power3.easeOut }, 'fadeIn')
      tl.to(elBar, 1, { width: '70%', ease: Power1.easeOut }, 'fadeIn')
      tl.play(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.storage-loading-bar {
  z-index: 9999;
  opacity: 0;
  overflow: hidden;

  position: fixed;

  top: 0;
  left: 0;

  width: 0%;
  height: 6px;

  background: linear-gradient(150deg,#53f 15%,#05d5ff 70%,#a6ffcb 94%);
}
</style>