// 
// RhymeSearchEngine ( 0.9.14.0.es6 )
// 
import { getRandomNumber0ToN, stringRepeatCount } from '../../shared/core/util/common'
import _ from 'lodash'
import axios from 'axios'

class RhymeSearchEngine {
  
  constructor(options) {
    if (options == null) { options = {} }
    this.name = options.name || 'unknown'

    this.core = {}
    this.status = {}
    this.setting = {}
    this.setting.apiUrl = options.api

    this.core.db = []
  }

  createDbFromContent(data) {
    const newDb = []

    let count = 1

    for (const record of Array.from(data)) {
      const uuid = `${this.name}_${count}`

      const formattedContent =
        record.content.replace(/[\r\t]+/g, '').replace(/-\\/g, '-').split(/\n+/)

      newDb.push({
        uuid,
        recordId: record.id,
        title: ( record.title || '' ),
        url: record.url,
        time: record.time,
        content: formattedContent,
        img0: record.img0,
        img1: record.img1,
        img2: record.img2,
        img3: record.img3,
        count1: ( record.count1 || 0 ),
        count2: ( record.count2 || 0 ),
        count3: ( record.count3 || 0 )
      })

      count += 1
    }

    return newDb
  }

  updateDbFromDownloadData(data) {
    return this.core.db = this.createDbFromContent(data)
  }

  downloadData(options) {
    if (options == null) { options = {} }
    const completedEvent = options.events.completed
    const failedEvent = options.events.failed

    axios.get(this.setting.apiUrl)
    .then(rs => {
      this.updateDbFromDownloadData(rs.data)
      completedEvent()
    })
    .catch(err => {
      console.error(err)
      failedEvent()
    })
  }

  searchResultByUuid(uuid) {
    return _.find(this.core.db, { uuid })
  }

  search(keywords, countTitle) {
    if (countTitle == null) { countTitle = false }

    return this.searchByKeyword(keywords, countTitle)
  }

  formatKeywords(keywords) {
    return keywords.replace(/[\[\]\|\(\)]/gi, '').replace(/[\s\t]+/g, ' ').replace(/^\s+|\s+$/g, '')
  }

  formatKeywordsRegExp(keywords) {
    return keywords.replace(/([\:\\\/\|\*\?\$\.\+\-\^\_])/gi, "\\$1").split(" ").join('|')
  }

  randomPick(max, countTitle){
    if (max == null) { max = 50 }
    if (countTitle == null) { countTitle = false }

    const randomIndexArray =
      getRandomNumber0ToN(this.core.db.length, max)

    const searchResults = []

    for (const index of Array.from(randomIndexArray)) {
      const record = this.core.db[index]

      if (record != null) {
        searchResults.push({
          uuid: record.uuid,
          record,
          paragraphs: record.content
        })
      }
    }

    const randomSearchResults = searchResults

    const titlesCountData =
      countTitle ?
        this.createTitleCountData(randomSearchResults)
      :
        []

    return {
      searchResults: randomSearchResults,
      titlesCountData
    }
  }

  createTitleCountData(searchResults) {
    if (_.isEmpty(searchResults)) { return [] }

    const caches = {}

    for (const result of Array.from(searchResults)) {
      if ((caches[result.record.title] == null)) {
        caches[result.record.title] = {
          label: result.record.title,
          count: 0
        }
      }
      
      caches[result.record.title].count += 1
    }

    const maxLength = this.name === 'idea_articles' ? 999 : 12
    return _.slice(_.orderBy(_.values(caches), 'count', 'desc'), 0, maxLength)
  }

  calculateSearchWeight(findKeywordsRegExpRule, formattedKeywordsForRegExp, title, content) {
    const keywords = formattedKeywordsForRegExp.split("|")

    let newWeight = 0

    // 權重方式為透過 每匹配到關鍵字 +1000，字元重複次數則是除以 100 以便區隔權重大小
    // 越能匹配到多組關鍵字的，權重越大
    // 反之，只匹配到一組的關鍵字，則根據字元重複數來做排列

    if (findKeywordsRegExpRule.test(title)) {
      newWeight += 1.5 * 1000 // 如果匹配到的是標題，會有優先權重
    }

    for (let index = 0; index < keywords.length; index++) {
      const keyword = keywords[index]
      const repeatCount = stringRepeatCount(content, keyword)

      if (repeatCount > 0) {
        newWeight += (1.0 * 1000) + (repeatCount / 100)
      }
    }

    return newWeight
  }

  searchByKeyword(keywords, countTitle) {
    let saveResultToSearchResults
    if (countTitle == null) { countTitle = false }
    const searchResults = []

    const formattedKeywords = this.formatKeywords(keywords)
    const formattedKeywordsForRegExp = this.formatKeywordsRegExp(formattedKeywords)

    const replaceKeywordsRegExpRule = new RegExp(`(${formattedKeywordsForRegExp})`, 'gi')
    const findKeywordsRegExpRule = new RegExp(`${formattedKeywordsForRegExp}`, 'gi')

    // 彙整搜尋結果
    for (const record of Array.from(this.core.db)) {

      saveResultToSearchResults = false
      const tempParagraphs = []

      // 有符合搜尋條件就秀整篇文章
      // 原文每一個段落，都會檢查關鍵字，有符合到就 highlight 起來，反之保留原文
      // 最後由 saveResultToSearchResults 來決定是否需要顯示出來給 用戶
      for (let index = 0; index < record.content.length; index++) { 
        var paragraph = record.content[index]
        var isFound = findKeywordsRegExpRule.test(paragraph)

        const newParagraph = 
          (() => {
          if (isFound) {
            saveResultToSearchResults = true
            return paragraph.replace(replaceKeywordsRegExpRule, "<b>$1</b>")
          } else {
            return paragraph
          }
        })()

        tempParagraphs.push(newParagraph)
      }

      // 順便找標題，但只有在找不到適合的內容再搜尋
      if (saveResultToSearchResults !== true) {
        if (findKeywordsRegExpRule.test(record.title)) {
          saveResultToSearchResults = true
        }
      }

      if (saveResultToSearchResults) {
        const searchWeight =
          this.calculateSearchWeight(
            findKeywordsRegExpRule,
            formattedKeywordsForRegExp,
            record.title,
            tempParagraphs.join("")
          )

        searchResults.push({
          uuid: record.uuid,
          record,
          paragraphs: tempParagraphs,
          weight: searchWeight
        })
      }
    }

    const sortedSearchResults =
      _.orderBy(searchResults, 'weight', 'desc')

    const titlesCountData =
      countTitle ?
        this.createTitleCountData(sortedSearchResults)
      :
        []

    return {
      searchResults: sortedSearchResults,
      titlesCountData
    }
  }
}

export default RhymeSearchEngine