// 
// IdeasPanel ( 0.9.0.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import _ from 'lodash'

class IdeasPanel extends GtUiComponent {
  name() {
    return 'IdeasPanel'
  }

  fetchUi() {
    this.status.opened = false
    this.status.processing = false

    this.ui.main = $('.ideas-panel')

    this.ui.inner  = this.ui.main.children('.inner')
    this.ui.left   = this.ui.inner.children('.left') 
    this.ui.center = this.ui.inner.children('.center') 
    this.ui.right  = this.ui.inner.children('.right') 
  }

  checkUi() {
    this.fetchUi()
    
    const result =
      (this.ui.main.length === 1) &&
      (this.ui.inner.length === 1) &&
      (this.ui.left.length === 1) &&
      (this.ui.center.length === 1) &&
      (this.ui.right.length === 1)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  isOpened() {
    return this.status.opened === true
  }

  open(events) {
    if (events == null) { events = {} }
    if (this.status.processing) { return }

    this.status.processing = true
    this.ui.main.toggleClass('enable-rhyme-search', true)

    setTimeout(() => {
      if (_.isFunction(events.completed)) { events.completed() }
      this.status.opened = true
      this.status.processing = false
    }, 500)
  }

  close() {
    if (this.status.processing) { return }
    
    this.status.processing = true
    this.ui.main.toggleClass('enable-rhyme-search', false)

    setTimeout(() => {
      this.status.opened = false
      this.status.processing = false

      if (window.IdeaEditorEditMode != null) {
        window.IdeaEditorEditMode.focusEditor()
      }
    }, 500)
  }

  initUi() {
    // nothing
  }
}

export default IdeasPanel