import _ from 'lodash'
import axios from "axios"
import MultiUploadControl from '../../shared/core/MultiUploadControl'

// 
// StorageOperator ( 0.9.4.0 )
// 
class StorageOperator {

  constructor (options={}) {
    this.currentFoldersResponseCache = {}
    this.currentFoldersResponseCacheTimeout = 40
    this.pathResponseCache = {}
    this.pathResponseCacheTimeout = 60
    this.folderPreviewImagesResponseCache = {}
    this.folderPreviewImagesResponseCacheTimeout = 60
    
    this.uploadControl =
      new MultiUploadControl({
        paramsName: 'file',
        uploadTo: '/files/upload',
        otherParams: [],
        event: {
          added: () => { }
        }
      })
  }

  createHttpClient (options={}) {
    const timeout = options.timeout || 5000
    const instance = axios.create({ timeout })
    return instance
  }

  getTimestamp () {
    return Math.floor(+new Date() / 1000)
  }

  setPathDataCache (currentFolderId, res) {
    this.pathResponseCache[`_folder${currentFolderId}`] =
      {
        id: currentFolderId,
        res: res,
        cachedAt: this.getTimestamp() 
      }
  }

  pathData (options={}) {
    const events = this.formatEventOptions(options.events)
    const currentFolderId = options.currentFolder

    const params = {
      current_folder: currentFolderId
    }

    const resCache =
      this.pathResponseCache[`_folder${currentFolderId}`]

    const useCache =
      options.useCache && _.isObject(resCache) &&
      this.getTimestamp() - resCache.cachedAt < this.pathResponseCacheTimeout

    if ( useCache ) {
      events.ok(resCache.res)
      return
    }
  
    const client = this.createHttpClient()
    client.get('/files/path_data', { params: params })
          .then((res) => {
            this.setPathDataCache(currentFolderId, res)
            events.ok(res)
          })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  currentUsageInfo (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    client.get('/files/usage_info')
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  currentAssets (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      current_folder: options.currentFolder
    }

    client.get('/files/current', { params: params })
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  setCurrentFoldersCache (currentFolderId, res) {
    this.currentFoldersResponseCache[`_folder${currentFolderId}`] =
      {
        id: currentFolderId,
        res: res,
        cachedAt: this.getTimestamp() 
      }
  }

  setFetchPreviewImagesCache (folderId, res) {
    this.folderPreviewImagesResponseCache[`_folder${folderId}`] =
      {
        id: folderId,
        res: res,
        cachedAt: this.getTimestamp() 
      }
  }

  folderPreviewImages ({ events, folderId, useCache }) {
    events = this.formatEventOptions(events)

    const params = {
      folder_id: folderId
    }

    const resCache = this.folderPreviewImagesResponseCache[`_folder${folderId}`]

    useCache =
      useCache && _.isObject(resCache) &&
      this.getTimestamp() - resCache.cachedAt < this.folderPreviewImagesResponseCacheTimeout

    if ( useCache ) {
      events.ok(resCache.res)
      return
    }

    const client = this.createHttpClient()
    client.get('/files/folder_preview_images', { params: params })
          .then((res) => {
            this.setFetchPreviewImagesCache(folderId, res)
            events.ok(res)
          })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  currentFolders (options={}) {
    const events = this.formatEventOptions(options.events)
    const currentFolderId = options.currentFolder

    const params = {
      current_folder: currentFolderId
    }

    const resCache =
      this.currentFoldersResponseCache[`_folder${currentFolderId}`]

    const useCache =
      options.useCache && _.isObject(resCache) &&
      this.getTimestamp() - resCache.cachedAt < this.currentFoldersResponseCacheTimeout

    if ( useCache ) {
      events.ok(resCache.res)
      return
    }

    const client = this.createHttpClient()
    client.get('/files/current_folders', { params: params })
          .then((res) => {
            this.setCurrentFoldersCache(currentFolderId, res)
            events.ok(res)
          })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() }) 
  }

  createFolder (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      name: options.name,
      current_folder: options.currentFolder
    }
    
    client.post('/files/create_folder', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  moveObjects (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      folder_ids: options.folderIds,
      file_ids: options.fileIds,
      destination_folder: options.destinationFolder
    }

    client.post('/files/move_objects', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  uploadAsset (options={}) {
    this.uploadControl.add(options.file, {
      params: options.params,
      event: {
        beforeStart: (obj) => {
          return options.events.beforeStart(obj)
        },
        uploaded: (obj) => {
          return options.events.uploaded(obj)
        },
        progress: (obj) => {
          return options.events.progress(obj)
        },
        error: (obj) => {
          return options.events.error(obj)
        },
        timeout: (obj) => {
          return options.events.timeout(obj)
        },
        abort: (obj) => {
          return options.events.abort(obj)
        }
      }
    })
  }

  deleteAsset (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      ids: options.objectIds
    }

    client.post('/files/delete', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  deleteFolder (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      id: options.objectId
    }

    client.post('/files/delete_folder', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  renameAsset (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      object_id: options.objectId,
      type: 'file',
      new_name: options.newName
    }

    client.post('/files/rename_object', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  fullFileList (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    client.get('/files/full_file_list')
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  fullFolderList (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    client.get('/files/full_folder_list')
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  renameFolder (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      object_id: options.objectId,
      type: 'folder',
      new_name: options.newName
    }

    client.post('/files/rename_object', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  ratingAsset (options={}) {
    const client = this.createHttpClient()
    const events = this.formatEventOptions(options.events)

    const params = {
      object_id: options.objectId,
      rating: options.rating
    }

    client.post('/files/rating_object', params)
          .then((res) => { events.ok(res) })
          .catch((err) => { events.failed(err.response, err) })
          .then(() => { events.always() })
  }

  formatEventOptions (events={}) {
    const okEvent =
      _.isFunction(events.ok) ? events.ok : () => {}

    const failedEvent =
      _.isFunction(events.failed) ? events.failed : () => {}

    const timeoutEvent =
      _.isFunction(events.timeout) ? events.timeout : () => {}

    const alwaysEvent =
      _.isFunction(events.always) ? events.always : () => {}

    return {
      ok: okEvent,
      failed: failedEvent,
      timeout: timeoutEvent,
      always: alwaysEvent
    }
  }

}

export default StorageOperator