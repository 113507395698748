// 
// SystemModal ( 0.5.0.0 )
// 
// 
//   SystemModal.open({url: '/tracking_posts/new?mini', width: 800, height: 500})
//   SystemModal.close()
// 
import { GtUiComponent } from '../../shared/core/GtUi'


class SystemModal extends GtUiComponent {
  name() {
    return 'SystemModal'
  }

  fetchUi() {
    this.setting.effectDuration = 0.3
    this.setting.modalWidth  = 0
    this.setting.modalHeight = 0
    this.setting.defaultMargin = 15 * 5

    this.status.hidden = true
    this.status.animating = false

    this.ui.main = $(document.body)
    this.ui.window = $(window)
    this.ui.modalWindow = $('.system-modal-window')
    this.ui.closeButton = this.ui.modalWindow.find('.close-btn')
    this.ui.modalOrnament = $('.system-modal-background-ornament')
    this.ui.iframe = this.ui.modalWindow.find('iframe')
    this.ui.openModalBtns = $('.open-modal') // modal trigger
  }

  checkUi() {
    this.fetchUi()
    
    const result =
      this.ui.main.length === 1 &&
      this.ui.window.length === 1 &&
      this.ui.modalWindow.length === 1 &&
      this.ui.modalOrnament.length === 1 &&
      this.ui.closeButton.length === 1 &&
      this.ui.iframe.length === 1 &&
      this.ui.openModalBtns.length >= 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    this.ui.closeButton.off()
    this.ui.modalOrnament.off()
    $(window).off('resize.system-modal')

    return true
  }

  initUi() {
    this.ui.closeButton.on('click', event => {
      event.preventDefault()
      this.close()
    })

    this.ui.modalOrnament.on('click', event => {
      event.preventDefault()
      this.close()
    })

    this.initResize()

    this.ui.openModalBtns.each((_, self) => {
      this.asyncInit(self)
    })
  }

  asyncInit(element) {
    const uiElement = $(element)
    if (uiElement.length < 1) { return }

    this.initOpenModalBtn(uiElement)
  }
  
  initOpenModalBtn(uiButton) {
    uiButton.on('click', e => {
      e.preventDefault()

      this.open({
        url: uiButton.data('url') || '',
        width: uiButton.data('width') || 800,
        height: uiButton.data('height') || 500
      })
    })
  }

  isMobileBrowser() {
    return /ANDROID|MOBILE|IPHONE|IPAD|IOS/i.test(navigator.userAgent)
  }

  initResize() {
    this.ui.window.on('resize.system-modal', event => {
      if (this.isMobileBrowser()) {
        // ignore
      } else {
        this.resetOrnamentAndWindowPosition()
      }
    })

    this.ui.modalOrnament.css('height', this.ui.main.height())
  }

  resetOrnamentAndWindowPosition() {
    const documentHeight = this.ui.main.height()

    const { modalWidth, modalHeight } = this.setting

    const modalLeft = ( this.ui.window.width() - modalWidth ) / 2
    const modalTop = 30 +
      (this.ui.window.height() < modalHeight ?
        this.ui.window.scrollTop() + this.setting.defaultMargin
      :
        this.ui.window.scrollTop() + (( this.ui.window.height() - modalHeight ) / 2))

    const needsToPaddingOrnamentHeight =
      documentHeight <= (modalTop + modalHeight)
    
    if (needsToPaddingOrnamentHeight) {
      const newPaddingHeight = ( modalTop + modalHeight ) - documentHeight
      const newDocumentHeight = newPaddingHeight + documentHeight + this.setting.defaultMargin
      this.ui.modalOrnament.css('height', newDocumentHeight)
    } else {
      this.ui.modalOrnament.css('height', documentHeight)
    }

    this.ui.modalWindow.css({
      width: modalWidth,
      height: modalHeight,
      top: modalTop,
      left: modalLeft
    })
  }

  resizeHeight(newHeight) {
    this.setting.modalHeight = newHeight

    TweenMax.to(this.ui.modalWindow, .3, {
      height: this.setting.modalHeight, ease: Power1.easeOut
    })
  }

  close() {
    this.ui.iframe.attr('src', '')
    this.hide()
  }

  smartClose() {
    return window.parent.window.SystemModal.close()
  }

  open(options) {
    if (options == null) { options = {} }
    const targetUrl = options.url || ''
    if (targetUrl.length < 1) { return }

    this.setting.modalWidth  = options.width || 500
    this.setting.modalHeight = options.height || 300

    this.resetOrnamentAndWindowPosition()

    this.ui.iframe.attr('src', targetUrl)

    this.show()
  }

  hide() {
    if ((this.status.hidden === true) || this.status.animating) { return }

    const tl =
      new TimelineMax({ 
        paused: true,
        onStart: () => {
          return this.status.animating = true
        },
        onComplete: () => {
          this.status.hidden = true
          return this.status.animating = false
        }
      })

    tl.add('setup')
    tl.set(this.ui.modalWindow, { display: 'block', opacity: 1 })
    tl.set(this.ui.modalOrnament, { display: 'block', opacity: 1 })

    tl.add('fadeOut')
    tl.to(this.ui.modalWindow, this.setting.effectDuration,
          { display: 'none', opacity: 0, ease: Power1.easeOut }, 'fadeOut')
    tl.to(this.ui.modalOrnament, this.setting.effectDuration,
          { display: 'none', opacity: 0, ease: Power1.easeOut }, 'fadeOut')

    tl.add('hidden')
    return tl.play(0)
  }

  show() {
    if ((this.status.hidden !== true) || this.status.animating) { return }

    const tl =
      new TimelineMax({ 
        paused: true,
        onStart: () => {
          return this.status.animating = true
        },
        onComplete: () => {
          this.status.hidden = false
          return this.status.animating = false
        }
      })

    tl.add('setup')
    tl.set(this.ui.modalWindow, { display: 'none', opacity: 0 })
    tl.set(this.ui.modalOrnament, { display: 'none', opacity: 0 })

    tl.add('fadeIn')
    tl.to(this.ui.modalWindow, this.setting.effectDuration,
          { display: 'block', opacity: 1, ease: Power1.easeInOut }, 'fadeIn')
    tl.to(this.ui.modalOrnament, this.setting.effectDuration,
          { display: 'block', opacity: 1, ease: Power1.easeOut }, 'fadeIn')

    tl.add('appeared')
    tl.play(0)
  }
}

export default SystemModal