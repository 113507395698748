<template>
  <div
    id="page_helper_app"
    class="page-helper-app"
  >
    <notifications
      group="storage"
      classes="storage-notify"
      position="bottom right"
    >
      <template #body="props">
        <div class="page-helper-notify">
          <div class="oa">
            <div class="col icon">
              <i :class="props.item.data.icon"></i>
            </div>
            <div class="col">
              <div class="title">
                {{ props.item.title }}
              </div>
              <div class="content" v-html="props.item.text"></div>
            </div>
          </div>
        </div>
      </template>
    </notifications>
  </div>  
</template>
<script>
export default {

  mounted () {
    // binding instance to window object
    window.PageHelper = this
  },

  methods: {

    notify (text, title, icon='far fa-comment') {
      this.$notify({
        group: 'storage',
        title: title.replace(/[\<\>\"\']/gi, ''),
        text: text.replace(/[\<\>\"\']/gi, ''),
        data: { icon: icon }
      })
    }

  }
  
}
</script>
<style lang="scss" scoped>

</style>