// 
// RhymeSearch ( 1.0.8.0 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import RhymeSearchTemplateGenerator from './RhymeSearchTemplateGenerator'
import RhymeSearchEngine from './RhymeSearchEngine'
import _ from 'lodash'
import moment from 'moment'
import Mustache from 'mustache'

import { delegate } from 'tippy.js'

class RhymeSearch extends GtUiComponent {
  name() {
    return 'RhymeSearch'
  }

  fetchUi() {
    this.core.templateGenerator = new RhymeSearchTemplateGenerator

    this.core.watchSyncEventTimer = null

    this.core.engine1 =
      new RhymeSearchEngine({
        api: '/tracking_posts/fetch_all',
        name: 'tracking_posts'
      })

    this.core.engine2 = 
      new RhymeSearchEngine({
        api: '/ideas/fetch_all',
        name: 'idea_articles'
      })

    this.status.opened = true
    this.status.acceptSearch = false
    this.status.engineDownloadCount = 0
    this.status.startSync = false
 
    this.ui.main = $('.rhyme-search')
    this.ui.searchKeyword = this.ui.main.children('input.search')
    this.ui.searchResults = this.ui.main.children('.results') 
    this.ui.searchResultsInner = this.ui.searchResults.children('.inner') 
    return this.ui.searchFilter = this.ui.searchResults.children('.filter') 
  }

  checkUi() {
    this.fetchUi()
    
    const result =
      (this.ui.main.length > 0) &&
      (this.ui.searchKeyword.length === 1) &&
      (this.ui.searchResults.length === 1) &&
      (this.ui.searchResultsInner.length === 1) &&
      (this.ui.searchFilter.length === 1)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  syncEngineData() {
    if (this.status.startSync) { return }

    this.status.startSync = true

    this.core.watchSyncEventTimer =
      setInterval(() => {
        if (this.status.engineDownloadCount > 1) {
          this.enabledUi()
          clearInterval(this.core.watchSyncEventTimer)
        }
      }, 300)

    this.status.engineDownloadCount = 0

    this.core.engine1.downloadData({
      events: {
        completed: () => {
          this.status.engineDownloadCount += 1
        },
        failed: () => {
          this.status.engineDownloadCount += 1
        }
      }
    })

    this.core.engine2.downloadData({
      events: {
        completed: () => {
          this.status.engineDownloadCount += 1
        },
        failed: () => {
          this.status.engineDownloadCount += 1
        }
      }
    })
  }

  enabledUi() {
    this.ui.searchKeyword.prop('disabled', false)
    this.ui.searchKeyword.focus()

    return this.setRandomResults()
  }
  
  disableUi() {
    this.updateMessage('fas fa-spinner', 'Loading ...')
    this.ui.searchKeyword.prop('disabled', true)

    TweenMax.to(this.ui.searchResults.find('.message i'), 30, { rotation: 359 })
  }

  updateMessage(iconClass, message, events) {
    if (events == null) { events = {} }
    this.ui.searchFilter.hide()
    TweenMax.set(this.ui.searchResults, { opacity: 0 })

    const messageSyntax = 
      Mustache.render(this.core.templateGenerator.updateMessage(),
        {iconClass, message})

    this.ui.searchResultsInner.html(messageSyntax)

    TweenMax.to(this.ui.searchResults, 0.3, {
      opacity: 1,
      ease: Power1.easeOut,
      delay: 0.1,
      onComplete() {
        if (_.isFunction(events.show)) {
          events.show()
        }
      }
    })
  }

  initUi() {
    const com = this

    this.disableUi()

    $(document).on('click', '.rhyme-search .filter a', function(e) {
      e.preventDefault()
      const uiLink = $(this)
      com.ui.searchFilter.find('a').removeClass('chose')
      uiLink.addClass('chose')
      com.ui.searchResultsInner.children('div').hide()
      com.ui.searchResultsInner.children(`div[data-filter=\"${uiLink.data('label')}\"]`).show()
    })

    this.ui.searchKeyword.on('keypress', e => {
      if (e.which !== 13) { return }

      this.updateMessage('fas fa-spinner', '搜尋中', { show: () => this.search() })
    })

    delegate(this.ui.main[0], {target: '.tips'})

    TweenMax.set(this.ui.main, { opacity: 0, display: 'none' })
  }

  formatKeywords(keywords) {
    return keywords.replace(/[\[\]\|\(\)]/gi, '').replace(/[\s\t]+/g, ' ').replace(/^\s+|\s+$/g, '')
  }

  formatFilterTitle(dataSource, title) {
    if (dataSource === 'idea_articles') {
      return '文案'
    } else {
      return title
    }
  }

  formatResultTitle(title, url) {
    let newTitle = title || ''

    if (newTitle.length > 40) {
      newTitle = newTitle.substr(0, 40) + ' .. '
    }

    return newTitle
  }

  outputResultSyntaxByEngineResult(mergeEngineResults) {
    let output = ''

    for (const engineKey in mergeEngineResults) {
      const engineData = mergeEngineResults[engineKey]
      for (const value of Array.from(engineData.engineResults)) {
        const useLargeImage = 
          (value.record.img1.length > 0) &&
          (value.record.img2.length === 0) &&
          (value.record.img3.length === 0)

        const resultSyntax =
          Mustache.render(this.core.templateGenerator.result(), {
            dataSource: engineData.dataSource,
            isIdeaArticle: engineData.dataSource === 'idea_articles',
            isTrackingPost: engineData.dataSource === 'tracking_posts',
            uuid: value.uuid,
            url: value.record.url,
            title: this.formatResultTitle(value.record.title, value.record.url),
            filterTitle: this.formatFilterTitle(engineData.dataSource, value.record.title),
            timeString: moment(new Date(value.record.time*1000)).fromNow(),
            paragraphs: value.paragraphs,
            unescapedParagraph() {
              return this.replace(/<\/?\w{2,}>/gi, '')
            },
            img0: value.record.img0,
            img1: value.record.img1,
            img2: value.record.img2,
            img3: value.record.img3,
            count1: value.record.count1,
            count2: value.record.count2,
            count3: value.record.count3,
            useLargeImage
          })

        output += resultSyntax
      }
    }

    return output
  }

  showFilter(titlesCountData) {
    if (titlesCountData.length < 1) {
      this.ui.searchFilter.hide()
      return
    }

    const syntax = 
      Mustache.render(this.core.templateGenerator.filter(),
        {titlesCountData})

    this.ui.searchFilter.html(syntax)
    this.ui.searchFilter.show()
  }

  setRandomResults() {
    const engine1Return = this.core.engine1.randomPick(30, true)
    const engine2Return = this.core.engine2.randomPick(35, true)

    const output =
      this.outputResultSyntaxByEngineResult({
        engine1: {
          engineResults: engine1Return.searchResults,
          dataSource: this.core.engine1.name
        },
        engine2: {
          engineResults: engine2Return.searchResults,
          dataSource: this.core.engine2.name
        }
      })

    if (output === '') {
      this.updateMessage('fas fa-exclamation', '很抱歉，目前儲存的文案、素材還不夠作為樣本')
    } else {
      this.ui.searchResultsInner.html(output)
      this.ui.searchResultsInner.prepend(this.core.templateGenerator.inputTips())

      this.showFilter(
        this.mergeTitlesCountData(
          engine1Return.titlesCountData,
          engine2Return.titlesCountData
        )
      )
    }
  }

  search() {
    const keywords = this.formatKeywords(this.ui.searchKeyword.val())

    if (keywords === '') {
      this.updateMessage('far fa-lightbulb', '輸入幾組關鍵字，來找找靈感吧')
      return
    }

    const engine1Return = this.core.engine1.search(keywords, true)
    const engine2Return = this.core.engine2.search(keywords, true)

    const output =
      this.outputResultSyntaxByEngineResult({
        engine1: {
          engineResults: engine1Return .searchResults,
          dataSource: this.core.engine1.name
        },
        engine2: {
          engineResults: engine2Return.searchResults,
          dataSource: this.core.engine2.name
        }
      })

    if (output === '') {
      this.updateMessage('fas fa-exclamation', '找不到資料，建議換個關鍵字試試')
    } else {
      this.ui.searchResultsInner.html(output)

      this.showFilter(
        this.mergeTitlesCountData(
          engine1Return.titlesCountData,
          engine2Return.titlesCountData
        )
      )
    }
  }

  mergeTitlesCountData(titlesCountData1, titlesCountData2) {
    const titlesCountData2Total = _.sumBy(titlesCountData2, 'count')

    if (titlesCountData2Total > 0) {
      return [{ label: '文案', count: titlesCountData2Total }].concat(titlesCountData1)
    } else {
      return titlesCountData1
    }
  }

  open(events) {
    if (events == null) { events = {} }
    if (!this.status.startSync) { this.syncEngineData() }

    return TweenMax.to(this.ui.main, 0.3, {
      opacity: 1, display: '',
      ease: Power1.easeOut, 
      onComplete: () => {
        this.status.opened = true
        if (_.isFunction(events.completed)) { events.completed() }
        this.ui.searchKeyword.focus()
      }
    })
  }

  close(events) {
    if (events == null) { events = {} }
    return TweenMax.to(this.ui.main, 0.3, {
      opacity: 0,
      display: 'none',
      ease: Power1.easeOut,
      onComplete: () => {
        this.status.opened = false
        if (_.isFunction(events.completed)) { events.completed() }
      }
    })
  }
}

export default RhymeSearch