// 
// UploadAsset ( 1.0.1.0 )
// 
import _ from 'lodash'
import * as uuid from 'uuid/v4'
import {
  getHumanFileSize,
  getFileExtName,
  getFileIconCss
} from '../../shared/core/util/common'

class UploadAsset {

  constructor (options={}) {

    if ( !_.isObject(options.file) ) {
      throw 'create failed. ( file not found )'
    }

    this.file = options.file
    this.pseudoMode = options.pseudo
    this.event = {}
    this.storageParams = {}
    this.cache = { ui: {} }

    if ( this.pseudoMode == true ) {
      this.initCoreInPseudoMode(options)
    } else {
      this.initCoreInNormalMode(options)
    }
  }

  initCoreInNormalMode (options) {
    if ( _.isEmpty(options.paramsName) || _.isEmpty(options.uploadTo) ) {
      throw 'create failed. ( paramsName or uploadTo not found )'
    }

    if (_.isEmpty(options.event) ||
       !_.isFunction(options.event.uploaded) ||
       !_.isFunction(options.event.progress) ||
       !_.isFunction(options.event.error) ||
       !_.isFunction(options.event.abort) ||
       !_.isFunction(options.event.timeout) ) {

      throw 'must pass event parameter. ( event.uploaded/progress/error/abort/timeout )'
    }

    this.status           = 'queued'
    this.id               = uuid()
    this.xhr              = null
    this.formParamsName   = options.paramsName
    this.formOtherParams  = options.otherParams
    this.remoteServerUrl  = options.uploadTo
    this.maxUploadSize    = (1024+64) * 1024 * 1024
    this.fileType         = this.file.type
    this.fileName         = this.file.name
    this.fileExtName      = getFileExtName(this.fileName)
    this.fileIconCss      = getFileIconCss(this.fileExtName)

    this.fileSize         = this.file.size
    this.fileHumanSize    = getHumanFileSize(this.file.size)
    this.progressLoaded   = 0
    this.progressTotal    = 0
    this.progressPercent  = 0
    this.createdAt        = Math.floor(+new Date / 1000)
    this.startUploadAt    = this.timeNow()
    this.lastProcessedAt  = null
    this.processTimeout   = false
    this.watchTimer       = null

    this.event.beforeStart = options.event.beforeStart || (() => { return true })
    this.event.uploaded    = options.event.uploaded
    this.event.progress    = options.event.progress
    this.event.error       = options.event.error
    this.event.abort       = options.event.abort
    this.event.timeout     = options.event.timeout

    this.storageParams.fileId = null
    this.storageParams.url = null
    this.storageParams.previewUrl = null
    this.storageParams.time = null
    this.storageParams.signature = null

    this.inDebugMode =
      /^127.0.0.1|localhost$/.test(window.location.hostname) ||
      window.location.href.indexOf('debug=1') > -1

    this.initXhr()
  }

  initCoreInPseudoMode (options) {
    this.status        = 'pseudo'
    this.fileName      = this.file.name
    this.fileExtName   = getFileExtName(this.fileName)
    this.fileIconCss   = getFileIconCss(this.fileExtName)
    this.fileSize      = this.file.size
    this.fileHumanSize = getHumanFileSize(this.file.size)

    this.storageParams.fileId = options.storageParams.fileId
    this.storageParams.url = options.storageParams.url
    this.storageParams.previewUrl = options.storageParams.previewUrl
  }

  initXhr () {
    this.xhr = new XMLHttpRequest

    this.xhr.upload.addEventListener('progress', e => {
      this.lastProcessedAt = this.timeNow()

      if ( /^(?:queued|starting)$/i.test(this.status) ) {
        if ( this.inDebugMode ) {
          console.log([ this.id, 'progress', 'to uploading' ])
        }
        this.status = 'uploading'
      }

      this.progressLoaded  = e.loaded
      this.progressTotal   = e.total
      this.progressPercent = Math.round(this.progressLoaded / this.progressTotal * 100)

      if ( this.inDebugMode ) {
        console.log([ this.id, 'progress',
          this.progressLoaded, this.progressTotal, this.progressPercent ])
      }

      this.event.progress(this)
    })
    
    this.xhr.addEventListener('load', e => {
      if ( this.xhr.status == 200 ) {
        this.status = 'uploaded'
        this.event.uploaded(this)
      } else {
        this.status = 'error'
        this.event.error(this)
      }

      if ( this.inDebugMode ) {
        console.log([ this.id, `load:${this.status}`, this.timeNow(false), this, e ])
      }
    })

    this.xhr.addEventListener('error', e => {
      if ( this.inDebugMode ) {
        console.log([ this.id, 'error', this.timeNow(false), this, e ])
      }

      this.status = 'error'
      this.event.error(this)
    })

    this.xhr.addEventListener('abort', e => {
      if ( this.inDebugMode ) {
        console.log([ this.id, 'abort', this.timeNow(false), this, e ])
      }

      this.status = this.processTimeout ? 'timeout' : 'abort'
    
      if ( this.status == 'timeout' ) {
        this.event.timeout(this)
      } else {
        this.event.abort(this)
      }
    })

  }

  getServerResponse () {
    try {
      return JSON.parse(this.xhr.responseText)
    }
    catch ( err ) {
      return null
    }
  }

  fileSizeExceededTheLimit () {
    return this.fileSize > this.maxUploadSize
  }
  
  fileSizeExceededTheLimitTips () {
    return `上傳檔案過大 ( 大於 ${getHumanFileSize(this.maxUploadSize)} ) `
  }

  timeNow (normalFormat=true) {
    if ( normalFormat ) {
      return Math.floor(+new Date / 1000)
    } else {
      return +new Date
    }
  }

  beforeStart () {
    return this.event.beforeStart(this)
  }

  start () {
    if ( this.processTerminated() ) {
      return
    }

    this.status = 'starting'
    this.startUploadAt = this.timeNow()

    if ( this.fileSizeExceededTheLimit() ) {
      this.status = 'error'
      this.event.error(this)
      return
    }

    const formData = new FormData

    if ( _.isArray(this.formOtherParams) ) {
      for ( const item of this.formOtherParams ) {
        formData.append(item.name, item.value)
      }
    }

    formData.append(this.formParamsName, this.file)

    if ( this.inDebugMode ) {
      console.log([ this.id, 'formData', formData ])
    }

    this.xhr.open('POST', this.remoteServerUrl)
    this.xhr.send(formData)

    this.watchTimer =
      setInterval(() => {
        this.checkUploadProcess()
      }, 1200)
  }

  checkUploadProcess () {
    if ( this.processTerminated() ) {

      if ( this.inDebugMode ) {
        console.log([ this.id, "checkUploadProcess:terminated",
          this.timeNow(false) ])
      }

      clearInterval(this.watchTimer)

    } else {

      if ( this.inDebugMode ) {
        console.log([ this.id, "checkUploadProcess",
          this.timeNow(false), this.lastProcessedAt ])
      }

      const isTimeout =
        this.lastProcessedAt && this.timeNow() - this.lastProcessedAt > 150

      if ( isTimeout ) {
        this.processTimeout = yes
        this.abort()
      }

    }
  }

  inQueued () {
    return /^queued$/i.test(this.status)
  }

  inProcessing () {
    return /^uploading$/i.test(this.status)
  }

  processFailed () {
    return /^(?:error|timeout)$/i.test(this.status)
  }

  processTerminated () {
    return /^(?:uploaded|abort)$/i.test(this.status) || this.processFailed()
  }

  acceptShowPreviewImage () {
    return this.isImage() && this.fileSize <= 5242880 * 2
  }

  getPreviewUrl () {
    if ( _.isEmpty(this.storageParams.previewUrl) ) {
      return null
    } else {
      return this.storageParams.previewUrl
    }
  }

  isImageExt () {
    return /^JPG|PNG|GIF|JPEG|TIFF|BMP$/i.test(this.fileExtName)
  }

  isImage () {
    return /^image\/bmp|image\/cis|image\/gif|image\/png|image\/jpeg|image\/jpg|image\/pipeg|image\/tiff$/i.test(this.fileType)
  }

  abort (force=false) {
    if ( force ) { this.status = 'abort' }

    this.xhr.abort()
  }

}

export default UploadAsset