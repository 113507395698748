// 
// AutoDetectTrackingPostProcessStatus ( 0.9.3.0 )
// 
import axios from 'axios'
import { GtUiComponent } from '../../shared/core/GtUi'

class AutoDetectTrackingPostProcessStatus extends GtUiComponent {
  name() {
    return 'AutoDetectTrackingPostProcessStatus'
  }

  fetchUi() {
    this.ui.main = $('body.tracking_posts.index')
    this.ui.listTable = $('.list-table')
    this.ui.rows = []
    this.core.autoDetectTimer = null
  }

  checkUi() {
    this.fetchUi()

    const result =
      this.ui.main.length > 0 &&
      this.ui.listTable.length == 1

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.initAutoDetectProcess()
  }
  
  initAutoDetectProcess() {
    this.core.autoDetectTimer = 
      setInterval(() => {
        if (!this.core.autoDetectTimer) { return }

        this.ui.rows =
          this.ui.listTable.find('tr.awaiting_task')

        const postIds = _.map(this.ui.rows, (el) => el.dataset.id)

        if (postIds.length < 1) {
          clearInterval(this.core.autoDetectTimer)
          this.core.autoDetectTimer = null
          return
        }

        axios.get(`/tracking_posts/rows_html`, { params: { ids: postIds } })
        .then(res => this.refreshPostsUiFromResponse({ res }))
        .catch(err => console.error(err))
      }, 3200)
  }

  refreshPostsUiFromResponse({ res }) {
    const newUiRows = $(res.data).find('.row')
    if (newUiRows.length < 1) { return}

    newUiRows.each((_, elRow) => {
      const ignore =
        (
          elRow.dataset.status == 'awaiting_task'
        ) || (
          elRow.dataset.status == 'watching' &&
          elRow.querySelector('.switch-preview-image .images') == null
        )

      if (ignore) { return }

      const newUiRow = $(elRow)
      const rowId = newUiRow.attr('id') || ''
      const ogUiRow = this.ui.listTable.find(`#${rowId}`)

      const needsToSync =
        rowId.indexOf('row') > -1 &&
        ogUiRow.length == 1 &&
        ogUiRow.data('status') == 'awaiting_task'

      if (needsToSync) {
        this.initRow(ogUiRow, newUiRow)
      }
    })
  }

  initRow(uiRow, newUiRow) {
    const elMiniChart = newUiRow.find('.mini-chart')[0]
    if (elMiniChart != null) {
      window.MiniCharts.asyncInit(elMiniChart)
    }

    const elCopyBtn = newUiRow.find('.copy-btn')[0]
    if (elCopyBtn != null) {
      window.CopyBtn.asyncInit(elCopyBtn)
    }

    const uiOpenModalBtns = newUiRow.find('.open-modal')
    uiOpenModalBtns.each((_, elBtn) => {
      window.SystemModal.asyncInit(elBtn)
    })

    const elSwitchPreviewImage = newUiRow.find('.switch-preview-image')[0]
    if (elSwitchPreviewImage != null) {
      window.GtSwitchPreviewImage.asyncInit(elSwitchPreviewImage)
    }

    const uiRfbBtn = newUiRow.find('.rfb-btn')
    if (uiRfbBtn != null) {
      window.RequestFbAuthorizationBtns.asyncInit(uiRfbBtn)
    }

    uiRow.replaceWith(newUiRow)

    window.GtListTableMultiSelectSupport.asyncInit(newUiRow)
  }
}

export default AutoDetectTrackingPostProcessStatus