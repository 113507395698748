// 
// GtAirMenu ( 0.9.5.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import { eval2 } from '../../shared/core/util/common'

class GtAirMenu extends GtUiComponent {
  name() {
    return 'GtAirMenu'
  }

  fetchUi() {
    this.core.watchingMenus = {}
    this.status.totalMenu = 0

    this.ui.menuButtons = $('.air-menu-trigger')
  }

  checkUi() {
    this.fetchUi()

    this.status.totalMenu = this.ui.menuButtons.length

    const result = this.status.totalMenu > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    // @ui.menuButtons.off()
    $(window).off('resize.gt-air-menu')
    $(document).off('click.gt-air-menu')

    return true
  }

  initUi() {
    this.ui.menuButtons.each((index, self) => {
      this.initMenuButton(index, $(self))
    })

    this.initResize()
    this.initAutoHideMenu()
  }

  initAutoHideMenu() {
    $(document).on('click.gt-air-menu', event => {
      $.each(this.core.watchingMenus, (settingKey, setting) => {
        if (setting != null) {
          this.autoHideTargetMenu(event, settingKey, setting.menuButton, setting.menu)
        }
      })
    })
  }

  autoHideTargetMenu(event, settingKey, uiMenuButton, uiTargetMenu) {
    const targetMenuOffset = uiTargetMenu.offset()
    const eX = targetMenuOffset.left
    const eY = targetMenuOffset.top
    const ignore = (eX + eY) === 0
    if (ignore) { return }

    const elW = uiTargetMenu.width()
    const elH = uiTargetMenu.height()
    const mX = eX + (elW / 2)
    const mY = eY + (elH / 2)
    const raX = (elW / 2) + 50 // menu 左右邊周圍 50px 內仍可保留
    const raY = (elH / 2) + 100 // 同 raX 概念
    const dX = Math.abs(event.pageX - mX)
    const dY = Math.abs(event.pageY - mY)

    const needsToHide = (dX > raX) || (dY > raY)

    if (needsToHide) {
      this.core.watchingMenus[settingKey] = null
      uiMenuButton.data('clicked', false)
      uiTargetMenu.hide()
    }
  }

  resetTargetMenuPosition(uiMenuButton, uiTargetMenu) {
    const menuButtonOffset = uiMenuButton.offset()
    const topSpacing = uiMenuButton.data('topSpacing') || 5
    const menuNewPosition = { top: 0, left: 0, topSpacing, leftSpacing: 0 }

    menuNewPosition.top =
      menuButtonOffset.top + menuNewPosition.topSpacing + 
      uiMenuButton.height()

    menuNewPosition.left =
      (menuButtonOffset.left - uiTargetMenu.width()) +
      uiMenuButton.width() + menuNewPosition.leftSpacing

    uiTargetMenu.css({
      top: `${menuNewPosition.top}px`,
      left: `${menuNewPosition.left}px`
    })
  }

  initResize() {
    $(window).on('resize.gt-air-menu', event => {
      $.each(this.core.watchingMenus, (settingKey, setting) => {
        if (setting != null) {
          this.resetTargetMenuPosition(setting.menuButton, setting.menu)
        }
      })
    })
  }

  initMenuButton(index, uiMenuButton) {
    const settingKey = `_${index}`
    delete this.core.watchingMenus[settingKey]

    if ((uiMenuButton.data('menuId') == null)) { return }

    const targetMenuId = uiMenuButton.data('menuId').replace(/[^\w\_\-]/, '')
    const uiTargetMenu = $(`\#${targetMenuId}`)

    if (uiTargetMenu.length !== 1) { return }

    uiTargetMenu.on('click', event => {
      uiMenuButton.data('clicked', false)
      uiTargetMenu.hide()
    })

    uiMenuButton.on('click', event => {
      event.preventDefault()

      if (uiMenuButton.data('openCondition') != null) {
        const rejectClick = !eval2(uiMenuButton.data('openCondition'))
        if (rejectClick) { return }
      }

      if (uiMenuButton.data('animating')) { return }

      const newClickStatus = !( uiMenuButton.data('clicked') === true )
      uiMenuButton.data('clicked', newClickStatus)
      uiMenuButton.data('animating', true)

      if (uiMenuButton.data('clicked')) {
        this.core.watchingMenus[settingKey] = {
          menuButton: uiMenuButton, menu: uiTargetMenu
        }

        this.resetTargetMenuPosition(uiMenuButton, uiTargetMenu)
        this.showTargetMenu(uiMenuButton, uiTargetMenu)

      } else {
        delete this.core.watchingMenus[settingKey]
        this.hideTargetMenu(uiMenuButton, uiTargetMenu)
      }
    })
  }

  showTargetMenu(uiMenuButton, uiTargetMenu) {
    const tl = new TimelineLite({paused: true, onComplete: () => {
      uiMenuButton.data('animating', false)
    }})

    tl.set(uiTargetMenu, {
      display: 'none', opacity: 0, y: -10, rotationY: 0
    })

    tl.to(uiTargetMenu, 0.3, {
      display: 'block', opacity: 1, y: 0, rotationY: 0, ease: Power1.easeOut
    })

    tl.play(0)
  }

  hideTargetMenu(uiMenuButton, uiTargetMenu) {
    const tl = new TimelineLite({paused: true, onComplete: () => {
      uiMenuButton.data('animating', false)
    }})

    tl.set(uiTargetMenu, {
      display: 'block', opacity: 1, rotationY: 0
    })
  
    tl.to(uiTargetMenu, 0.3, {
      display: 'none', opacity: 0, rotationY: 0, ease: Power1.easeOut
    })

    tl.play(0)
  }
}

export default GtAirMenu