// 
// EmojiInput ( 0.9.0.2.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import _ from 'lodash'


class EmojiInput extends GtUiComponent {
  name() {
    return 'EmojiInput'
  }

  fetchUi() {
    this.status.opened = false

    this.ui.main = $('.emoji-input')
    this.ui.chars = this.ui.main.find('.chars')
    this.ui.search = this.ui.main.find('.search input')
    this.ui.categoryButtons = this.ui.main.find('.categories a')
    this.ui.ideaEditor = $('.idea-editor')

    this.core.quill = null

    this.core.searchEmoji = 
      _.throttle(() => {
        this.ui.categoryButtons.removeClass('chose')

        const { chars } = gtEmoji.searchByWord(this.ui.search.val())
        this.ui.chars.html(this.renderCharsInterface(chars))
      }, 200)
  }

  checkUi() {
    this.fetchUi()

    const result = (this.ui.main.length === 1) &&
             (this.ui.chars.length === 1) &&
             (this.ui.search.length === 1) &&
             (this.ui.categoryButtons.length > 0) &&
             (this.ui.ideaEditor.length === 1)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    this.ui.main.off()

    return true
  }

  initUi() {
    this.setQuill()
    this.initButtonUi()
  }

  setQuill() {
    if ((this.core.quill == null)) {
      this.core.quill = window.IdeaEditorEditMode.core.quill
    }
  }

  setDefaultUi() {
    this.renderCharsInterfaceByCategoryName('emotions')
  }

  initButtonUi() {
    const com = this

    this.ui.categoryButtons.on('click', function(e) {
      e.preventDefault()
      if (com.status.opened !== true) { return }

      com.renderCharsInterfaceByCategoryName($(this).data('category'))
    })

    this.ui.chars.on('click', 'i', function(e) {
      if (com.status.opened !== true) { return }

      com.insertCharToEditor($(this).text())
    })

    this.ui.search.on('input', e => {
      if (com.status.opened !== true) { return }

      this.core.searchEmoji()
    })
  }

  toggle() {
    if (this.status.opened) {
      this.close()
      return false
    } else {
      this.open()
      return true
    }
  }

  open() {
    this.ui.ideaEditor.addClass('open-emoji-window')
    TweenMax.to(this.ui.main, 0.5, { opacity: 1, display: 'block' })

    this.status.opened = true
    this.setDefaultUi()
  }

  close() {
    this.status.opened = false
    TweenMax.to(this.ui.main, 0.5, { opacity: 0, display: 'none' })

    this.ui.ideaEditor.removeClass('open-emoji-window')
  }

  insertCharToEditor(newChar) {
    if (_.isEmpty(newChar)) { return }

    this.core.quill.focus()
    this.core.quill.insertText(this.core.quill.getSelection(), newChar)
  }

  renderCharsInterfaceByCategoryName(categoryName) {
    let setChoseButtonStyle
    this.ui.search.val('')
    this.ui.categoryButtons.removeClass('chose')

    setChoseButtonStyle = false

    const newChars = 
      (() => { switch (categoryName) {
        case 'emotions':
          setChoseButtonStyle = true
          return gtEmoji.searchByCategory('smileys_emotion').chars
        case 'hand':
          setChoseButtonStyle = true
          return gtEmoji.searchByCategory('people_body').chars
        case 'animals':
          setChoseButtonStyle = true
          return gtEmoji.searchByCategory('animals_nature').chars
        case 'foods':
          setChoseButtonStyle = true
          var group1 = gtEmoji.searchByCategory('food_drink').chars
          var group2 = gtEmoji.searchByCategory('travel_places').chars
          return group1.concat(group2)
        case 'flags':
          setChoseButtonStyle = true
          return gtEmoji.searchByCategory('flags').chars
        case 'symbols':
          setChoseButtonStyle = true
          group1 = gtEmoji.searchByCategory('symbols').chars
          group2 = gtEmoji.searchByCategory('activities').chars
          return group1.concat(group2)
        case 'objects':
          setChoseButtonStyle = true
          return gtEmoji.searchByCategory('objects').chars
        default:
          return null
      } })()

    if (setChoseButtonStyle) {
      const choseButtonSelector = `.emoji-input a[data-category=\"${categoryName}\"]`
      $(choseButtonSelector).addClass('chose')
    }

    if (_.isEmpty(newChars)) {
      this.ui.chars.html('')
    } else {
      this.ui.chars.html(this.renderCharsInterface(newChars))
    }
  }

  renderCharsInterface(chars) {
    const maxColumns = 44
    let output = '<div>'

    for (let index = 0; index < chars.length; index++) { 
      const char = chars[index]
      const step = index + 1
      output += `<i>${char}</i>`
      if ((step % maxColumns) === 0) { output += '</div><div>' }
    }
      // 適時使用 <p> 區隔 <i> 可優化 chrome 對 <i> 的事件處理響應速度

    output += '</div>'
    return output
  }
}

export default EmojiInput