import axios from 'axios'

// 
// ArticleAssetsOperator ( 0.5.0.0 )
// 
class ArticleAssetsOperator {

  createHttpClient ({ timeout=5000 }) {
    const instance = axios.create({
      timeout: timeout
    })

    return instance
  }

  getTimestamp () {
    return Math.floor(+new Date() / 1000)
  }

  currentAssets ({
    params: { articleId },
    events: { ok, failed, always }
  }) {

    const client = this.createHttpClient({})

    client.post('/ideas/current_assets', { article: articleId })
          .then(res => { ok(res) })
          .catch(err => { failed(err.response, err) })
          .then(() => { always() })
  }

  createArticleAssets ({
    params: { assetIds, articleId },
    events: { ok, failed, always }
  }) {

    const client = this.createHttpClient({})
    const params = { article: articleId, assets: assetIds }

    client.post('/ideas/create_asset_relation_settings', params)
          .then(res => { ok(res) })
          .catch(err => { failed(err.response, err) })
          .then(() => { always() })
  }

  removeArticleAsset ({
    params: { assetId, articleId },
    events: { ok, failed, always }
  }) {

    const client = this.createHttpClient({})
    const params = { article: articleId, asset: assetId }

    client.post('/ideas/remove_asset_relation_settings', params)
          .then(res => { ok(res) })
          .catch(err => { failed(err.response, err) })
          .then(() => { always() })
  }

}

export default ArticleAssetsOperator