// 
// core/util/common ( 0.8.2.0 )
// 
import _ from 'lodash'

function eval2(syntax) {
  try {
    return eval(syntax)
  } catch (e) {
    return null
  }
}

function stringRepeatCount(string, keyword) {
  var keywordLength, position, repeatCount
  repeatCount = 0
  position = 0
  keywordLength = keyword.length

  while (true) {
    position = string.indexOf(keyword, position)
    if (position === -1) {
      break
    }
    repeatCount += 1
    position += keywordLength
  }

  return repeatCount
}

function getRandomNumber0ToN(maxNumber, n) {
  return _.slice(_.shuffle(_.map(new Array(maxNumber).fill(), function(v, i) {
    return i
  })), 0, n)
}

function acceptUseDragDropUpload() {
  return (
    typeof DataTransfer != 'undefined' &&
    typeof DataTransferItemList != 'undefined'
  )
}

function getHumanFileSize (bytes) {
  // modify: https://stackoverflow.com/questions/15900485
  if ( bytes == 0 ) {
    return '0 Byte'
  }

  const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ]
  const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

  return Math.round(bytes / Math.pow(1024, index), 2) + ' ' + sizes[index]
}

function getFileExtName (name) {
  return ( name.match(/\.(\w+)$/) || [ '', 'DAT' ] )[1].toUpperCase()
}

function getFileIconCss (extName) {
  let cssName = ''

  if (/^JPEG|JPG|PNG|GIF|TIFF$/.test(extName)) {
    cssName = 'far fa-file-image'
  } else if (/^MD$/.test(extName)) {
    cssName = 'fab fa-markdown'
  } else if (/^DOC|DOCX$/.test(extName)) {
    cssName = 'far fa-file-word'
  } else if (/^XLS|XLSX|XLSB|CSV$/.test(extName)) {
    cssName = 'far fa-file-excel'
  } else if (/^PPS|PPSX|PPT|PPTX$/.test(extName)) {
    cssName = 'far fa-file-powerpoint'
  } else if (/^PDF$/.test(extName)) {
    cssName = 'far fa-file-pdf'
  } else if (/^ZIP|7Z|TAR|GZ|RAR$/.test(extName)) {
    cssName = 'far fa-file-archive'
  } else if (/^MP4|M4A|WMV|MKV|FLV|MP3|AVI|MOV$/.test(extName)) {
    cssName = 'far fa-file-audio'
  } else {
    cssName = 'far fa-file-alt'
  }

  return cssName
}

function setLocalStorageData ({ key, value }) {
  const object = value
  const jsonData = JSON.stringify(object)
  window.localStorage.setItem(key.toString(), jsonData)

  return object
}

function getLocalStorageData ({ key }) {
  var object = null
  const value = window.localStorage.getItem(key.toString())

  try {
    object = value ? JSON.parse(value) : null
  } catch (err) {
    console.error(err)
    object = null
  }
  
  return object
}

export {
  eval2,
  stringRepeatCount,
  getRandomNumber0ToN,
  acceptUseDragDropUpload,
  getHumanFileSize,
  getFileExtName,
  getFileIconCss,
  setLocalStorageData,
  getLocalStorageData
}