<template>
  <div class="storage-navigator">
    <div class="breadcrumb">
      <div class="oa">
        <div class="col">
          <a
            class="home tippy top"
            title="回最上層"
            href="#root"
          ><i class="fas fa-hdd"></i></a>
        </div>
        <div class="col slash">
          /
        </div>
        <div class="col">
          <span class="title"><a href="#root">NAS 儲存</a></span>
        </div>
        <div class="col slash">
          /
        </div>
        <div class="col">
          <span class="title">{{ formatCurrentFolderLabel }}</span>
        </div>
      </div>
    </div>
    <div class="search">
      <!-- <storage-search @search="search" /> -->
    </div>
    <div class="buttons">
      <a
        class="btn secondary"
        href="#new"
        @click.prevent="$emit('create-new-file')"
      ><span>新增</span><i class="far fa-folder-open"></i></a>
      <a
        class="btn primary"
        href="#upload"
        @click.prevent="$emit('open-file-dialog')"
      >上傳檔案 <i class="fas fa-plus"></i><span></span></a>
      <a
        class="close"
        href="#close"
        @click.prevent="$emit('close-dialog')"
      ><div class="fas fa-times"></div></a>
    </div>
  </div>
</template>

<script>
// import StorageSearch from './StorageSearch.vue'

// 
// StorageNavigator ( 0.8.5.0 )
// 
export default {
  name: 'StorageNavigator',

  // components: {
  //   StorageSearch,
  // },

  props: {
    currentFolderLabel: { type: String, default: '' }
  },

  emits: [
    'create-new-file',
    'open-file-dialog',
    'search',
    'close-dialog'
  ],

  computed: {
    formatCurrentFolderLabel () {
      return this.currentFolderLabel.length > 0 ? this.currentFolderLabel : '全部資料'
    },
  },

  methods: {
    search ({ keywords }) {
      this.$emit('search', { keywords })
    }
  }
}
</script>

<style lang="scss" scoped>
.storage-navigator {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  display: table;

  width: 100%;
  height: 3.25em;

  color: #333333;

  margin-bottom: 1em;
  padding-bottom: 1em;

  border-bottom: 1px solid fade-out(gray, 0.9);

  & > div {
    display: table-cell;

    width: 100%;
    height: 100%;

    vertical-align: middle;
  }

  & > .breadcrumb {
    width: auto;

    padding-left: 0.25em;

    .title {
      vertical-align: middle;
      font-size: 20px;
    }

    .oa > .col {
      padding-left: 0.25em;
      padding-right: 0.25em;

      &.slash {
        color: lighten(gray, 20%);
      }
    }

    i {
      vertical-align: middle;
      font-size: 20px;
      transform: scale(0.8);

      &.fa-chevron-right {
        transform: scale(0.7);
      }
    }

    a {
      display: inline-block;
      color: darken(#5469d4, 5%);
      transition: color .3s ease;
      // vertical-align: middle;

      &.home {
        color: darken(#5469d4, 10%);
      }

      &:hover {
        color: darken(#5469d4,  0%);
      }
    }  
  }

  & > .search {
    width: 18em;
  }

  & > .buttons {
    width: 20em;

    padding-right: 0.25em;
    text-align: right;

    a.close {
      display: inline-flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;

      width: 2em;
      height: 2em;

      border-radius: 0.5em;
      color: #5469d4;
      background-color: fade-out(white, 0.5);
      transform: rotate(0);
      transition: color .3s ease,
                  background-color .3s ease,
                  transform .3s ease;

      &:hover {
        color: white;
        background-color: fade-out(#f44336, 0);
        transform: rotate(2deg);
      }

      margin-left: 1em;
    }

    a.btn:first-child { margin-left: 0 !important; }
    a.btn { margin-left: 0.75em; }

    a.btn {
      border-radius: 5px;
      padding: calc(0.75em - 1px) calc(1.125em - 1px);
      
      transition: (
        color .3s ease,
        background-color .3s ease,
        border-color .3s ease
      );

      > i { margin-left: 0.425em; transform: scale(1.2); }

      &.text {
        color: #333333;
        background-color: transparent;
        padding: 0.75em 0.75em;
  
        &:hover {
          color: #3b477b;
          background-color: fade-out(white, 0.2);
          border-color: fade-out(white, 0.2);
        }
      }

      &.primary {
        color: white;
        background-color: #3b477b;
        border: 1px solid #3b477b;
  
        &:hover {
          background-color: lighten(#3b477b, 10%);
        }
      }

      &.secondary {
        color: #3b477b;
        background-color: transparent;
        border: 1px solid #3b477b;
  
        &:hover {
          color: white;
          border-color: #3b477b;
          background-color: #3b477b;
        }
      }

      &.close {
        color: #3b477b;
        background-color: transparent;
        border: 1px solid #3b477b;
  
        &:hover {
          color: white;
          border-color: #3b477b;
          background-color: #3b477b;
        }
      }

    }

  } // [end] & > .buttons

}
</style>