import _ from 'lodash'

// 
// FolderObject2 ( 0.9.3.0 )
// 
class FolderObject2 {

  constructor (options={}) {
    if ( _.isEmpty(options.uuid) ) {
      throw 'uuid not found.'
    }

    // javascript object id
    this.uuid = options.uuid

    // adops nas folder record id
    this.id = options.id || null

    // folder parent id ( 0 = root )
    this.parentId = options.parent || 0

    // folder tree detph
    this.depth = options.depth
  
    // status: unknown, new, stored
    this.status = options.status || 'unknown'

    // folder info
    this.name = options.name || '未命名'
    this.totalFiles = 0
    this.totalSize = 0
    this.tree = options.tree || '/0/'
    this.createdAt = options.createdAt || Math.round(new Date().getTime() / 1000)

    // preview folder images
    this.previewImages = []

    // ui control
    this.userChose = false
    this.userEditObjectNameMode = false
    this.dataUpdating = false
    this.systemDefault = options.systemDefault || false

    // *unused
    this.permission = null

  }

}

export default FolderObject2
