// 
// SystemActiveLogs ( 0.1.0.0.alpha.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import _ from 'lodash'
import SystemActiveLogUiCore from './SystemActiveLogUiCore'

class SystemActiveLogs extends GtUiComponent {
  name() {
    return 'SystemActiveLogs'
  }

  fetchUi() {
    this.ui.SystemActiveLogs = $('.system-active-logs')
  }

  checkUi() {
    this.fetchUi()

    const result = this.ui.SystemActiveLogs.length > 0

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.ui.SystemActiveLogs.each((index, element) => new SystemActiveLogUiCore(element))
  }
}

export default SystemActiveLogs