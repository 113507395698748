// 
// HOW TO USE:
// 
//    1.  window.ui18.label.processFailed
//    2.  window.ui18.msg.processFailedPleaseTryAgainLater2
//    3.  window.ui18.msg.createDataSuccess('Value')
// 
const label = {
  'zh-TW': {
    authorizationProcess: '授權處理',
    authorizationFailed: '授權失敗',
    loading: '載入中⋯',
    clipboard: '剪貼簿',
    copyFbPostId: '複製 FB Post ID',
    copy: '複製',
    systemNotice: '系統通知',
    processFailed: '發生錯誤',
    removeFailed: '移除失敗',
    removeFolder: '移除資料夾',
    moveData: '搬移資料',
    copyUrl: '複製URL',
    systemInitializing: '系統初始化中',
    updateAuthorizationInformation: '授權更新',
    fbAuthorizationFailed: 'FB 授權失敗',
    pasteFileFailed: '無法貼上',
    uploadTerminated: '終止上傳',
    uploadFailed: '上傳失敗',
    nas: 'NAS',
    renameFile: '更改檔名',
    renameFolder: '更改資料夾名',
  },
  'en': {
    authorizationProcess: 'Authorization Process',
    authorizationFailed: 'Authorization Failed',
    loading: 'Loading⋯',
    clipboard: 'Clipboard',
    copyFbPostId: 'Copy Facebook Post Id',
    copy: 'Copy',
    systemNotice: 'System Notice',
    processFailed: 'Process Failed',
    removeFailed: 'Remove Failed',
    removeFolder: 'Remove Folder',
    moveData: 'Move Data',
    copyUrl: 'Copy URL',
    systemInitializing: 'System Initializing',
    updateAuthorizationInformation: 'Update Authorization Information',
    fbAuthorizationFailed: 'Authorization Failed',
    pasteFileFailed: 'Paste File Failed',
    uploadTerminated: 'Upload Terminated',
    uploadFailed: 'Upload Failed',
    nas: 'NAS',
    renameFile: 'Rename File',
    renameFolder: 'Rename Folder',
  }
}

const msg = {
  'zh-TW': {
    authorizationHasBeenCancelled: '授權已取消。',
    userCancelledTheAuthentication: '使用者取消登入或授權，請重新授權。',
    requestingAuthorization: '正在取得授權中。',
    getFacebookPageData: '取得粉絲專頁帳號中。',
    nPagesAuthorizationCompleted: v => `${v} 個 FB 粉專授權完成。`,
    createDataSuccess: v => `已建立 ${v}。`,
    createDataAlreadyExists: v => `${v} 已存在。`,
    processFailedPleaseTryAgainLater: v => `處理 ${v} 發生錯誤，無法建立，請重新再試一次。`,
    processFailedPleaseTryAgainLater2: '處理時發生錯誤，無法建立，請重新再試一次。',
    pleaseTryAgainLater: '處理時發生錯誤，請重新再試一次。',
    copyDataSuccess: v => `已複製 ${v}。`,
    copyDataError: v => `無法複製 ${v}。`,
    uploadFailed: '上傳失敗。',
    diskSpaceIsFull: '您的儲存空間已滿。',
    orderHasBeenUpdated: '已更新排序。',
    thereAreNoEventsToday: '本日沒有活動。',
    copyCompleted: '複製完成。',
    copyFailed: '瀏覽器無法複製內文，請重新整理試試。',
    logoutTipsInLog: '您已登出，請重新登入再試一次。',
  },
  'en': {
    authorizationHasBeenCancelled: 'Authorization has been cancelled.',
    userCancelledTheAuthentication: 'User cancelled the authentication.',
    requestingAuthorization: 'Requesting authorization.',
    getFacebookPageData: 'Get facebook page data..',
    nPagesAuthorizationCompleted: v => `${v} ${v < 2 ? 'page' : 'pages'} authorization completed.`,
    createDataSuccess: v => `${v} successfully created.`,
    createDataAlreadyExists: v => `${v} already exists.`,
    processFailedPleaseTryAgainLater: _ => `Process failed, Please try again later.`,
    processFailedPleaseTryAgainLater2: 'Process failed, Please try again later.',
    pleaseTryAgainLater: 'Please try again later.',
    copyDataSuccess: v => `${v} successfully copied.`,
    copyDataError: v => `Unable to copy ${v}.`,
    uploadFailed: 'Upload failed.',
    diskSpaceIsFull: 'Storage space is full.',
    orderHasBeenUpdated: 'The order has been updated.',
    thereAreNoEventsToday: 'There are no events today.',
    copyCompleted: 'Copy completed.',
    copyFailed: 'Copy failed. (your browser is not supported)',
    logoutTipsInLog: 'You have been sign out, Please log in again.',
  }
}

const locale = window.APP_LOCALE || 'zh-TW'

window.ui18 = {
  label: label[locale],
  msg: msg[locale],
}
