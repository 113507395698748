// 
// CurrentPlanInfo ( 0.1.0.0.alpha )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import { getHumanFileSize } from '../../shared/core/util/common'
import tippy from 'tippy.js'
import _ from 'lodash'

class CurrentPlanInfo extends GtUiComponent {
  name() {
    return 'CurrentPlanInfo'
  }

  fetchUi() {
    this.ui.main = $('.current-plan-info')
    this.ui.nasUsageBar = this.ui.main.find('.nas-usage-bar')
    this.ui.nasUsageBarLine = this.ui.nasUsageBar.find('i')
  }

  checkUi() {
    this.fetchUi()

    const result =
      this.ui.main.length > 0 &&
      this.ui.nasUsageBar.length == 1 &&
      this.ui.nasUsageBarLine.length == 1

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  initUi() {
    this.loadUsageInfo()
  }

  loadUsageInfo () {
    const nasCurrentUsage = this.ui.main.data('nasCurrentUsage')
    const nasMaxUsage = this.ui.main.data('nasMaxUsage')

    const reject = !_.isNumber(nasCurrentUsage) || !_.isNumber(nasMaxUsage)
    if (reject) { return }

    const percent = Math.min((nasCurrentUsage / nasMaxUsage * 100).toFixed(2), 100)
    tippy(this.ui.nasUsageBar[0], {
      content: `目前使用 ${percent}% ( ${getHumanFileSize(nasCurrentUsage)} / ${getHumanFileSize(nasMaxUsage)} )`,
      hideOnClick: false })

    const lineWidth = percent <= 5 ? '5%' : `${percent}%`
    this.ui.nasUsageBarLine.css('width', lineWidth)
  }
}

export default CurrentPlanInfo