// 
// IdeaEditorEditMode ( 0.9.1.0.es6 )
// 
import { GtUiComponent } from '../../shared/core/GtUi'
import { acceptUseDragDropUpload } from '../../shared/core/util/common'
import Quill from 'quill'

class IdeaEditorEditMode extends GtUiComponent {
  name() {
    return 'IdeaEditorEditMode'
  }

  fetchUi() {
    this.core.quill = null
    this.core.watchingEditContentTimer = null

    this.ui.editorWindow = $('.idea-editor.edit-mode')
    this.ui.titleInput = this.ui.editorWindow.find('.title input')
    this.ui.editorContent = this.ui.editorWindow.find('.content')
    this.ui.dataForm = $('form.data-form')
    this.ui.hiddenTitleInput = $('#editor_hidden_title')
    this.ui.hiddenContentInput = $('#editor_hidden_content')

    this.status.previousTitle = ''
    this.status.previousContent = ''
    return this.status.userEdited = false
  }

  checkUi() {
    this.fetchUi()

    const result = (this.ui.editorWindow.length === 1) && 
             (this.ui.titleInput.length === 1) && 
             (this.ui.editorContent.length === 1) &&
             (this.ui.dataForm.length === 1) &&
             (this.ui.hiddenTitleInput.length === 1) &&
             (this.ui.hiddenContentInput.length === 1)

    return result
  }

  mounted() {
    if (!this.checkUi()) { return }

    this.initUi()

    return true
  }

  destroy() {
    return true
  }

  focusEditor() {
    this.core.quill.focus()
  }

  watchingEditContent() {
    this.core.watchingEditContentTimer =
      setInterval(() => {
        if (this.status.userEdited) { return }

        const userHasModified =
          (this.status.previousTitle !== this.ui.titleInput.val()) ||
          (this.status.previousContent !== this.getEditorContent())

        if (userHasModified) {
          this.status.userEdited = true
          clearInterval(this.core.watchingEditContentTimer)
          this.enableNotSaveTips()
        }
      }, 1500)
  }

  initQuill(elementId) {
    this.core.quill =
      new Quill('#editor', {
        theme: 'bubble',
        modules: {
          syntax: false,
          toolbar: false
        }
      })

    this.ui.titleInput.focus()

    this.status.previousTitle   = this.ui.titleInput.val()
    this.status.previousContent = this.getEditorContent()

    this.watchingEditContent()
  }

  initUi() {
    this.initQuill(this.ui.editorContent.attr('id'))

    this.ui.dataForm.on('submit', e => {
      this.disabledNotSaveTips()
      this.ui.hiddenTitleInput.val(this.ui.titleInput.val())
      this.ui.hiddenContentInput.val(this.getEditorContent())
    })

    this.ui.titleInput.on('keypress', e => {
      e.which === 13 ? this.core.quill.focus() : false
    })

    if (acceptUseDragDropUpload()) {
      this.initUploadFileFeature()
    }
  }

  initUploadFileFeature() {
  
    this.ui.editorWindow.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.preventDefault()
      e.stopPropagation()
    })

    this.ui.editorWindow.on('drop', e => {
      if (window.IdeaEditorUploadFiles) {
        window.IdeaEditorUploadFiles.addFile({
          fileList: e.originalEvent.dataTransfer.files
        })
      }
    })
    .on('dragover dragenter', () => this.toggleUploadFileTips(true))
    .on('dragleave dragend drop', () => this.toggleUploadFileTips(false))
  }

  getEditorTitle() {
    return this.ui.titleInput.val()
  }

  getEditorContent() {
    return this.core.quill.getText()
  }

  toggleUploadFileTips(switchValue) {
    return this.ui.editorWindow.toggleClass('dragover', switchValue)
  }

  disabledNotSaveTips() {
    $(window).off('beforeunload')
  }

  enableNotSaveTips() {
    $(window).on('beforeunload', () => '您尚未儲存資料，請問要離開畫面嗎？')
  }

  getCurrentEditorStatus () {
    const statusData = {
      articleEditPath: '',
      articleTitle: '',
      userAssetIds: []
    } 

    const elAssets =
      document.querySelectorAll('div[id^="editor_user_asset_"]')

    if (elAssets.length > 0) {
      statusData.userAssetIds = [...elAssets].map(v => v.dataset.assetId)
    }

    const elInput = 
      document.querySelector('.idea-editor .title input')

    if (elInput) {
      statusData.articleTitle = elInput.value || ''
    }

    const urlPath = window.location.pathname

    if ((/^\/ideas\/\d+\/edit$/i).test(urlPath)) {
      statusData.articleEditPath = urlPath
    } else if ((/^\/ideas\/\d+$/i).test(urlPath)) {
      statusData.articleEditPath = `${urlPath}/edit`
    } else {
      // ignore
    }

    return statusData
  }

}

export default IdeaEditorEditMode